import { Box } from "@chakra-ui/react";
import { StepperPage } from "./StarIndex";

export const StarLandingPage = () => {
  return (
    <Box>
      <StepperPage />
    </Box>
  );
};
