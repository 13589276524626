import React, { useEffect, useState } from 'react';
import { Row, Col, Form, Button } from "react-bootstrap";
import DatePicker from "react-datepicker";
import { useTranslation } from 'react-i18next';
import { FaPrint } from "react-icons/fa";
import Moment from 'moment';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { massiveCertGetData } from '../../../../services/floodService';
import Swal from 'sweetalert2';
import "react-datepicker/dist/react-datepicker.css";

const Community = (props) => {
    const { poTransPK, generateDoc, emptyQuoteData, floodSystemDD, handleChange, handleDateChange, checkDateisValid, handleStateChange, validated, isBind } = props;
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [floodProgramType, setFloodProgramType] = useState('');
    const [communityNum, setCommunityNum] = useState('');
    const [panelNum, setPanelNum] = useState('');
    const [mapSuffix, setMapSuffix] = useState('');
    const [floodZone, setFloodZone] = useState('');
    const [countyName, setCountyName] = useState('');
    const [initFirmDate, setInitFirmDate] = useState(null);
    const [cBFED, setCBFED] = useState('');
    const [currentFirmDate, setCurrentFirmDate] = useState(null);
    const [CBRSorOPA, setCBRSorOPA] = useState('');
    const [CBRSorOPADesignationDate, setCBRSorOPADesignationDate] = useState(null);
    const [showBtnCode, setShowBtnCode] = useState("");
    // determination massivecert request id
    const [requestId, setRequestId] = useState("");
    const [zoneVendor, setZoneVendor] = useState('');

    // set state
    useEffect(() => {
        if (Object.keys(emptyQuoteData).length > 0) {
            setFloodProgramType(emptyQuoteData.propertyInfo.s_FloodProgramType || null);
            setCommunityNum(emptyQuoteData.propertyInfo.s_CommunityNumber || null);
            setPanelNum(emptyQuoteData.propertyInfo.s_PanelNumber || null);
            setFloodZone(emptyQuoteData.propertyInfo.s_FloodZone || null);
            setMapSuffix(emptyQuoteData.propertyInfo.s_MapSuffix || null);

            // from address
            setCountyName(emptyQuoteData.propertyInfo.s_CountyName || null);
            if (checkDateisValid(emptyQuoteData.propertyInfo.d_InitialFirmDate)) {
                setInitFirmDate(new Date(Moment(emptyQuoteData.propertyInfo.d_InitialFirmDate) || null));
                handleStateChange('COMMUNITY', new Date(Moment(emptyQuoteData.propertyInfo.d_InitialFirmDate)), 'InitFirmDate');
            }
            setCBFED(emptyQuoteData.propertyInfo.s_BaseElevation || null);
            if (checkDateisValid(emptyQuoteData.propertyInfo.d_CurrentFirmDate)) {
                setCurrentFirmDate(new Date(Moment(emptyQuoteData.propertyInfo.d_CurrentFirmDate) || null));
            }
            setCBRSorOPA(emptyQuoteData.propertyInfo.s_CBRSorOPA || null);
            if (checkDateisValid(emptyQuoteData.propertyInfo.d_CBRSOPADate)) {
                setCBRSorOPADesignationDate(new Date(Moment(emptyQuoteData.propertyInfo.d_CBRSOPADate) || null));
            }
            if(emptyQuoteData.determinationArray) {
                setShowBtnCode(emptyQuoteData.determinationArray.displayDeteminationBtnCode || "");
                setRequestId(emptyQuoteData.determinationArray.request_id || "");
                setZoneVendor(emptyQuoteData.determinationArray.vendor || "");
                let determinationStatus = emptyQuoteData.determinationArray.status || "";
                handleStateChange('COMMUNITY', determinationStatus, 'determinationStatus');
            }
        }
    }, [emptyQuoteData])

    useEffect(() => {
        if (CBRSorOPA !== 'YES') {
            handleDateChange(null, 'd_CBRSOPADate', 'propertyInfo');
            setCBRSorOPADesignationDate(null);
        }
    }, [CBRSorOPA]);

    // trigger on update currentFirmDate
    useEffect(() => {
        var months;
        if (checkDateisValid(currentFirmDate)) {
            months = (new Date().getFullYear() - currentFirmDate.getFullYear()) * 12;
            months -= currentFirmDate.getMonth();
            months += new Date().getMonth();
            var temp = false;
            if (months < 12 && !["X", "B", "C"].includes(floodZone)) {
                temp = true;
            }
            // var isEC = true;
            // if (!["X", "B", "C", "A99"].includes(floodZone)) {
            //     isEC = false;
            // }
            handleStateChange('DETERMINATION', temp, 'isNewlyMapped');
            handleStateChange('COMMUNITY', false, 'isEC');
            handleStateChange('COMMUNITY', floodZone, 'FloodZone');
            handleStateChange('COMMUNITY', currentFirmDate, 'currentMapDate');
        }
    }, [currentFirmDate, floodZone])

    // massivecert get data
    const handleGetData = async (requestId) => {
        await dispatch(massiveCertGetData(requestId)).then(async (res) => {
            if (res.success === true) {
                // for success
                let text = "Data saved successfully", title = "Success", icon = "success", status = res.data.status;

                if (status === 'error') {
                    text = res.data.message;
                    icon = status;
                    title = status;
                }
                await Swal.fire({
                    title: t(title),
                    text: t(text),
                    icon: icon,
                    showCancelButton: false,
                    confirmButtonColor: '#66e3d8',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Reload',
                    allowOutsideClick: false,
                }).then((result) => {
                    if (result.isConfirmed && status == "OK") {
                        window.location.reload();
                    }
                });
            } else {
                toast.error(t('Something Went Wrong!'));
            }
        }).catch(() => {
            toast.error(t('Something Went Wrong!'));
        });
    }

    return (
        <Form validated={validated}>
            <Row>
                <Col xs="12" sm="12" md="6" lg="2" xl="2">
                    <Form.Group
                        className="search-quote-form-group"
                        controlId="formBasicEmail"
                    >
                        <Form.Label className="dashboard-group-label">
                            {t('Program Type')}
                        </Form.Label>
                        <Form.Select
                            className="table-show-entries-select"
                            name="s_FloodProgramType"
                            title={t('Program Type')}
                            parentobject="propertyInfo"
                            value={floodProgramType}
                            onChange={(e) => {
                                setFloodProgramType(e.currentTarget.value);
                                handleChange(e);
                            }}
                            required
                            disabled={isBind}
                        >
                            <option value={''}>{t('Select')}</option>
                            {
                                Object.keys(floodSystemDD).length > 0 && floodSystemDD.FLOODPROGRAMTYPE.map((v, i) => {
                                    return <option key={i} value={v.s_AppCodeName}>{t(v.s_AppCodeNameForDisplay)}</option>
                                })
                            }
                        </Form.Select>
                    </Form.Group>
                </Col>
                <Col xs="12" sm="12" md="6" lg="2" xl="2">
                    <Form.Group
                        className="search-quote-form-group"
                        controlId="formBasicEmail"
                    >
                        <Form.Label className="dashboard-group-label">
                            {t("Community Number")}
                        </Form.Label>
                        <Form.Control
                            className="dashboard-group-input-flood"
                            type="text"
                            placeholder={t("Community Number")}
                            name="s_CommunityNumber"
                            title={t('Community Number')}
                            parentobject="propertyInfo"
                            value={communityNum}
                            onChange={(e) => {
                                handleChange(e);
                                setCommunityNum(e.currentTarget.value);
                            }}
                            required
                            autoComplete="off"
                            disabled={isBind}
                        />
                    </Form.Group>
                </Col>
                <Col xs="12" sm="12" md="6" lg="2" xl="2">
                    <Form.Group
                        className="search-quote-form-group"
                        controlId="formBasicEmail"
                    >
                        <Form.Label className="dashboard-group-label">
                            {t('Panel Number')}
                        </Form.Label>
                        <Form.Control
                            className="dashboard-group-input-flood"
                            type="text"
                            placeholder={t("Panel Number")}
                            name="s_PanelNumber"
                            title={t('Panel Number')}
                            parentobject="propertyInfo"
                            value={panelNum}
                            onChange={(e) => {
                                handleChange(e);
                                setPanelNum(e.currentTarget.value);
                            }}
                            required
                            autoComplete="off"
                            disabled={isBind}
                        />
                    </Form.Group>
                </Col>
                <Col xs="12" sm="12" md="6" lg="2" xl="2">
                    <Form.Group
                        className="search-quote-form-group"
                        controlId="formBasicEmail"
                    >
                        <Form.Label className="dashboard-group-label">
                            {t('Map Suffix')}
                        </Form.Label>
                        <Form.Control
                            className="dashboard-group-input-flood"
                            type="text"
                            placeholder={t("Map Suffix")}
                            name="s_MapSuffix"
                            title={t('Map Suffix')}
                            parentobject="propertyInfo"
                            value={mapSuffix}
                            onChange={(e) => {
                                handleChange(e);
                                setMapSuffix(e.currentTarget.value);
                            }}
                            required
                            autoComplete="off"
                            disabled={isBind}
                        />
                    </Form.Group>
                </Col>
                <Col xs="12" sm="12" md="6" lg="2" xl="2">
                    <Form.Group
                        className="search-quote-form-group"
                        controlId="formBasicEmail"
                    >
                        <Form.Label className="dashboard-group-label">
                            {t('Flood Zone')}
                        </Form.Label>
                        <Form.Select
                            className="table-show-entries-select"
                            name="s_FloodZone"
                            title={t('Flood Zone')}
                            parentobject="propertyInfo"
                            value={floodZone}
                            onChange={(e) => {
                                setFloodZone(e.currentTarget.value);
                                handleChange(e);
                            }}
                            required
                            disabled={isBind}
                        >
                            <option value={''}>{t('Select')}</option>
                            {
                                Object.keys(floodSystemDD).length > 0 && floodSystemDD.FoodZones.map((v, i) => {
                                    return <option key={i} value={v.s_FloodZone}>{t(v.s_FloodZone)}</option>
                                })
                            }
                        </Form.Select>
                    </Form.Group>
                </Col>
                <Col xs="12" sm="12" md="6" lg="2" xl="2">
                    <Form.Group
                        className="search-quote-form-group"
                        controlId="formBasicEmail"
                    >
                        <Form.Label className="dashboard-group-label">
                            {t("County Name")}
                        </Form.Label>
                        <Form.Control
                            className="dashboard-group-input-flood"
                            type="text"
                            placeholder={t("County Name")}
                            name="s_CountyName"
                            title={t('County Name')}
                            parentobject="propertyInfo"
                            value={countyName}
                            onChange={(e) => {
                                setCountyName(e.currentTarget.value);
                                handleChange(e);
                            }}
                            disabled={isBind}
                        />
                    </Form.Group>
                </Col>
                <Col xs="12" sm="12" md="6" lg="2" xl="2">
                    <Form.Group
                        className="search-quote-form-group"
                        controlId="formBasicEmail"
                    >
                        <Form.Label className="dashboard-group-label">
                            {t("Initial Firm Date")}
                        </Form.Label>
                        <DatePicker
                            id="dashboard-datepicker"
                            className={(isBind ? "dashboard-datepicker-main-bg" : "dashboard-datepicker-main")}
                            placeholderText="MM/DD/YYYY"
                            selected={initFirmDate}
                            onChange={(date) => {
                                setInitFirmDate(date);
                                handleDateChange(date, 'd_InitialFirmDate', 'propertyInfo');
                                handleStateChange('COMMUNITY', date, 'InitFirmDate');
                            }}
                            required
                            autoComplete="off"
                            disabled={isBind}
                        />
                    </Form.Group>
                </Col>
                <Col xs="12" sm="12" md="6" lg="2" xl="2">
                    <Form.Group
                        className="search-quote-form-group"
                        controlId="formBasicEmail"
                    >
                        <Form.Label className="dashboard-group-label">
                            {t('Current Base Flood Elevation/ Depth (in feet)')}:
                        </Form.Label>
                        <Form.Control
                            className="dashboard-group-input-flood"
                            type="number"
                            placeholder="00"
                            name="s_BaseElevation"
                            title={t('in feet')}
                            parentobject="propertyInfo"
                            value={cBFED}
                            min={0}
                            onChange={(e) => {
                                handleChange(e);
                                setCBFED(e.currentTarget.value);
                                handleStateChange('COMMUNITY', e.currentTarget.value, 'EC');
                            }}
                            disabled={isBind}
                        />
                    </Form.Group>
                </Col>
                <Col xs="12" sm="12" md="6" lg="2" xl="2">
                    <Form.Group
                        className="search-quote-form-group"
                        controlId="formBasicEmail"
                    >
                        <Form.Label className="dashboard-group-label">
                            {t("Current Firm Date")}
                        </Form.Label>
                        <DatePicker
                            id="dashboard-datepicker"
                            className={(isBind ? "dashboard-datepicker-main-bg" : "dashboard-datepicker-main")}
                            placeholderText="MM/DD/YYYY"
                            selected={currentFirmDate}
                            onChange={(date) => {
                                setCurrentFirmDate(date);
                                handleDateChange(date, 'd_CurrentFirmDate', 'propertyInfo');
                            }}
                            required
                            autoComplete="off"
                            disabled={isBind}
                        />
                    </Form.Group>
                </Col>
                <Col xs="12" sm="12" md="6" lg="3" xl="3">
                    <Form.Group
                        className="search-quote-form-group"
                        controlId="formBasicEmail"
                    >
                        <Form.Label className="dashboard-group-label">
                            {t('Is the building located in Coastal Barrier Resources System Area (CBRS) or an Other Protected Area (OPA)')}
                        </Form.Label>
                        <Form.Select
                            className="table-show-entries-select"
                            name="s_CBRSorOPA"
                            title={t('Is the building located in Coastal Barrier Resources System Area (CBRS) or an Other Protected Area (OPA)')}
                            parentobject="propertyInfo"
                            value={CBRSorOPA}
                            onChange={(e) => {
                                setCBRSorOPA(e.currentTarget.value);
                                handleChange(e);
                            }}
                            required
                            disabled={isBind}
                        >
                            <option value={''}>{t('Select')}</option>
                            {
                                Object.keys(floodSystemDD).length > 0 && floodSystemDD.YESNOONLY.map((v, i) => {
                                    return <option key={i} value={v.s_AppCodeName}>{t(v.s_AppCodeNameForDisplay)}</option>
                                })
                            }
                        </Form.Select>
                    </Form.Group>
                </Col>
                {
                    CBRSorOPA === 'YES' && (
                        <Col xs="12" sm="12" md="6" lg="2" xl="2">
                            <Form.Group
                                className="search-quote-form-group"
                                controlId="formBasicEmail"
                            >
                                <Form.Label className="dashboard-group-label">
                                    {t('CBRS/OPA Designation Date')}:
                                </Form.Label>
                                <DatePicker
                                    id="dashboard-datepicker"
                                    className={(isBind ? "dashboard-datepicker-main-bg" : "dashboard-datepicker-main")}
                                    placeholderText="MM/DD/YYYY"
                                    selected={CBRSorOPADesignationDate}
                                    onChange={(date) => {
                                        setCBRSorOPADesignationDate(date);
                                        handleDateChange(date, 'd_CBRSOPADate', 'propertyInfo');
                                    }}
                                    autoComplete="off"
                                    disabled={isBind}
                                />
                            </Form.Group>
                        </Col>
                    )
                }
                {
                    (zoneVendor === 'ZDV_MASSIVECERTS' && showBtnCode === 'GET_REPORT') && (
                        <Col xs="12" sm="12" md="6" lg="2" xl="2">
                            <Form.Label className="dashboard-group-label">
                                {t('View Zone Determination Document')}:
                            </Form.Label>
                            <Button
                                variant="primary"
                                id="search-quote-btn"
                                className='mt-0'
                                onClick={() => generateDoc('ZONE_DETERMINATION')}>
                                <FaPrint />&nbsp;&nbsp;{t('Zone Determination')}
                            </Button>
                        </Col>
                    )
                }
                {
                    (zoneVendor === 'ZDV_MASSIVECERTS' && showBtnCode === 'GET_DATA' && requestId !== "") && (
                        <Col xs="12" sm="12" md="6" lg="3" xl="3">
                            <Form.Label className="dashboard-group-label">
                                {t('Zone Determination Get Data')}:
                            </Form.Label>
                            <Button
                                variant="primary"
                                id="search-quote-btn"
                                className='mt-0'
                                onClick={() => handleGetData(requestId)}>
                                <FaPrint />&nbsp;&nbsp;{t('Zone Determination Get Data')}
                            </Button>
                        </Col>
                    )
                }
            </Row>
        </Form>
    )
}

export default Community