import {
  Checkbox,
  Flex,
  FormControl,
  FormLabel,
  Grid,
  Image,
  Slider,
  SliderFilledTrack,
  SliderMark,
  SliderThumb,
  SliderTrack,
  Spacer,
  Tooltip,
  Link
} from "@chakra-ui/react";
import { Box, Center, HStack, Text } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { useStarDataContext } from "../../StarDataContext";
import { useEffect, useState } from "react";
import { BsInfoCircle } from "react-icons/bs";
import DateInput from "../../utils/DateInput";
import creditScoreSliderImg from "../../utils/sliderCredit.svg";
import ReactTooltip from "react-tooltip";
import { FcInfo } from "react-icons/fc";

const CreditScoreSelect = ({
  setSecondCreditScore,
  setCreditScore,
  validationError,
  underWriting,
  setUnderWriting,
  confirmation,
  setConfirmation,
}) => {
  const { t } = useTranslation();
  const [sliderValue, setSliderValue] = useState(750);
  const [text, setText] = useState("");
  const [textSecond, setTextSecond] = useState("");
  const [sliderSecondValue, setSliderSecondValue] = useState(650);

  useEffect(() => {
    const getTextFromScore = (score) => {
      if (score >= 800 && score <= 850) return "Excellent";
      if (score >= 740 && score <= 799) return "Very Good";
      if (score >= 670 && score <= 739) return "Good";
      if (score >= 580 && score <= 669) return "Fair";
      if (score >= 0 && score <= 579) return "Poor";
    };
    setText(getTextFromScore(sliderValue));
  }, [sliderValue]);

  useEffect(() => {
    const getTextFromScore = (score) => {
      if (score >= 800 && score <= 850) return "Excellent";
      if (score >= 740 && score <= 799) return "Very Good";
      if (score >= 670 && score <= 739) return "Good";
      if (score >= 580 && score <= 669) return "Fair";
      if (score >= 0 && score <= 579) return "Poor";
    };
    setTextSecond(getTextFromScore(sliderSecondValue));
  }, [sliderSecondValue]);

  const getTooltipText = (text) => {
    switch (text) {
      case "Excellent":
        return "An excellent credit score is between 800 and 850.";
      case "Very Good":
        return "A very good credit score is between 740 and 799.";
      case "Good":
        return "A good credit score is between 670 and 739.";
      case "Fair":
        return "A fair credit score is between 580 and 669.";
      case "Poor":
        return "A poor credit score is between 300 and 579.";
      default:
        return "";
    }
  };

  const getColor = (text) => {
    switch (text) {
      case "Poor":
        return "red";
      case "Fair":
        return "yellow";
      case "Good":
        return "lightgreen";
      case "Very Good":
        return "green";
      case "Excellent":
        return "darkgreen";
      default:
        return "gray.500";
    }
  };

  return (
    <>
      <Box bg="primary.50" borderRadius="xl" p="0.1rem">
        <Text
          mt={3}
          color="black"
          fontSize="1.5rem"
          pl="1.5rem"
          fontWeight="semibold"
        >
          {t("credit_score")}
        </Text>
      </Box>
      <Box p={4}>
        <Text color={"neutral.500"}>
          Please use the slider to select your credit score from 350 to 850.
        </Text>
      </Box>
      <Box p={4}>
        <Grid templateColumns={"repeat(3, 1fr)"} alignItems={"center"}>
          <Box>
            <Text
              pt={"12px"}
              fontWeight="600"
            >
              {underWriting?.propertyDetails?.customerType === "individual"
                ? `${underWriting?.propertyDetails?.firstName || ""} ${
                    underWriting?.propertyDetails?.lastName || ""
                  }`
                : `${underWriting?.propertyDetails?.buissnessName || ""} `}
            </Text>
          </Box>
          <Box my={4} mt={"-10px"}>
            <FormControl w={"80%"} isRequired>
              <FormLabel>{t("Date of Birth")}:</FormLabel>
              <DateInput
                value={underWriting?.propertyDetails?.dateOfBirth || ""}
                onChange={(e) => {
                  setUnderWriting((prevState) => ({
                    ...prevState,
                    propertyDetails: {
                      ...prevState.propertyDetails,
                      dateOfBirth: e,
                    },
                  }));
                }}
                styleProps={{
                  border: "2px solid",
                  borderColor: "#9AA5B5",
                  borderRadius: "xl",
                  size: "lg",
                  readOnly: false,
                  width: "100%",
                }}
                maxDate={new Date()}
              />
              {validationError.underWritingPage &&
                !underWriting?.propertyDetails?.dateOfBirth && (
                  <Text color="red">{t("This field cannot be empty")}</Text>
                )}
            </FormControl>
          </Box>
          <Flex
            // direction={{ base: "column", md: "row" }}
            gap={2}
            alignItems="center"
            justifyContent="center"
          >
            <Slider
              min={350}
              aria-label="slider-ex-6"
              onChange={(val) => {
                setSliderValue(val);
                setCreditScore(val);
              }}
              width={{ base: "100%", md: "520px" }}
              max={850}
            >
              <Image
                src={creditScoreSliderImg}
                pos="relative"
                zIndex={15}
                w="100%"
              />
              <SliderMark
                value={sliderValue}
                textAlign="center"
                bg="transparent"
                color="gray.500"
                mt="-8"
                ml="-5"
                w="12"
                position="relative"
                zIndex={15}
                border="1px solid transparent"
                borderRadius="20px"
                fontWeight="bold"
              >
                <div
                  style={{
                    position: "absolute",
                    top: "60%",
                    transform: "translateY(-50%)",
                    backgroundColor: "rgba(255, 255, 255, 1)",
                    padding: "10px",
                    borderRadius: "20px",
                    zIndex: 15,
                    textAlign: "center",
                    color: "black",
                    fontSize: "14px",
                    width: "60px",
                    height: "40px",
                    overflow: "hidden",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {sliderValue}
                </div>
              </SliderMark>
              <SliderTrack>
                <SliderFilledTrack />
              </SliderTrack>
              <SliderThumb />
            </Slider>
            <Tooltip
              bg={"black"}
              color={"white"}
              label="Credit Score is a 3-digit number that represents the credit history of an individual. It ranges from 300 to 900. The higher the score, the better the credit history."
            >
              <Box p={1}>
                <FcInfo size={20} />
              </Box>
            </Tooltip>

            {text && (
              <Box w={"160px"}>
                <Tooltip
                  p={2}
                  hasArrow
                  bg={"black"}
                  color={"white"}
                  label={`${text}: ${getTooltipText(text)}`}
                >
                  <Text
                    textAlign="right"
                    color={getColor(text)}
                    fontWeight={500}
                    fontSize="3xl"
                    mt={4}
                    _hover={{
                      textDecoration: "underline",
                      cursor: "pointer",
                    }}
                  >
                    {text}
                  </Text>
                </Tooltip>
              </Box>
            )}
          </Flex>
        </Grid>
      </Box>
      {Array.isArray(confirmation?.additionalInsured) &&
        confirmation?.additionalInsuredFlag &&
        underWriting?.propertyDetails?.customerType === "individual" && (
          <Box p={4}>
            <Grid templateColumns={"repeat(3, 1fr)"} alignItems={"center"}>
              <Box>
                <Text
                  pt={"12px"}
                  fontWeight={"600"}
                >
                  {`${confirmation?.additionalInsured[0]?.firstName || ""} ${
                    confirmation?.additionalInsured[0]?.lastName || ""
                  }`}{" "}
                </Text>
              </Box>
              <Box my={4} mt={"-10px"}>
                <FormControl w={"80%"} isRequired>
                  <FormLabel>{t("Date of Birth")}:</FormLabel>
                  <DateInput
                    value={
                      confirmation?.additionalInsured[0]?.dateOfBirth || ""
                    }
                    onChange={(e) => {
                      setConfirmation({
                        ...confirmation,
                        additionalInsured: [
                          {
                            ...confirmation?.additionalInsured[0],
                            dateOfBirth: e,
                          },
                        ],
                      });
                    }}
                    styleProps={{
                      border: "2px solid",
                      borderColor: "#9AA5B5",
                      borderRadius: "xl",
                      size: "lg",
                      readOnly: false,
                      width: "100%",
                    }}
                    maxDate={new Date()}
                  />
                  {validationError.underWritingPage &&
                    Array.isArray(confirmation?.additionalInsured) &&
                    !confirmation?.additionalInsured[0]?.dateOfBirth && (
                      <Text color={"red"}>
                        {t("This field cannot be empty")}.
                      </Text>
                    )}
                </FormControl>
              </Box>

              <Flex
                gap={2}
                alignItems={"center"}
                justifyContent={"center"}
                // pr={4}
              >
                <Slider
                  min={350}
                  aria-label="slider-ex-6"
                  onChange={(val) => {
                    setSliderSecondValue(val);
                    setSecondCreditScore(val);
                  }}
                  width={{ base: "100%", md: "520px" }}
                  max={850}
                >
                  <Image
                    src={creditScoreSliderImg}
                    pos={"relative"}
                    zIndex={15}
                    w={"100%"}
                  />
                  <SliderMark
                    value={sliderSecondValue}
                    textAlign="center"
                    bg="transparent"
                    color="gray.500"
                    mt="-8"
                    ml="-5"
                    w="12"
                    position={"relative"}
                    zIndex={15}
                    border="1px solid transparent"
                    borderRadius={"20px"}
                    fontWeight={"bold"}
                  >
                    <div
                      style={{
                        position: "absolute",
                        top: "60%",
                        transform: "translateY(-50%)",
                        backgroundColor: "rgba(255, 255, 255, 1)",
                        padding: "10px",
                        borderRadius: "20px",
                        zIndex: 15,
                        textAlign: "center",
                        color: "black",
                        fontSize: "14px",
                        width: "60px",
                        height: "40px",
                        overflow: "hidden",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      {sliderSecondValue}
                    </div>
                  </SliderMark>
                  <SliderTrack>
                    <SliderFilledTrack />
                  </SliderTrack>
                  <SliderThumb />
                </Slider>
                <Tooltip
                  bg={"black"}
                  color={"white"}
                  label="Credit Score is a 3-digit number that represents the credit history of an individual. It ranges from 300 to 900. The higher the score, the better the credit history."
                >
                  <Box p={1}>
                    <FcInfo size={20} />
                  </Box>
                </Tooltip>

                {textSecond && (
                  <Box w={"160px"}>
                    <Tooltip
                      p={2}
                      hasArrow
                      bg={"black"}
                      color={"white"}
                      label={`${textSecond}: ${getTooltipText(textSecond)}`}
                    >
                      <Text
                        textAlign="right"
                        color={getColor(textSecond)}
                        fontWeight={500}
                        fontSize="3xl"
                        mt={4}
                        _hover={{
                          textDecoration: "underline",
                          cursor: "pointer",
                        }}
                      >
                        {textSecond}
                      </Text>
                    </Tooltip>
                  </Box>
                )}
              </Flex>
            </Grid>
          </Box>
        )}
    </>
  );
};

const TermsAndConditions = ({
  underWriting,
  setUnderWriting,
  validationError,
  setValidationError,
}) => {
  const { t } = useTranslation();
  useEffect(() => {
    setUnderWriting(() => ({
      ...underWriting,
      checkboxes: {
        ...underWriting?.checkboxes,
        readFcraStatement: false,
      },
    }));
    setValidationError(() => ({
      ...validationError,
      underWritingPage: false,
    }));
  }, []);

  const {
    stateCodeVal
  } = useStarDataContext();

  const renderCreditScoreToolTip = (stateCodeVal) => {
    if (stateCodeVal === "ms") {
      return (
        <>
          As an applicant or insured, your credit report or score is being
          requested for insurance rating purposes. The Mississippi Department of
          Insurance offers free financial literacy programs to assist you with
          insurance-related questions, including how credit works and how credit
          scores are calculated. To learn more, visit <Link style={{textDecoration: "underline"}} href='https://www.mid.ms.gov/' isExternal>www.mid.ms.gov</Link>.
        </>
      );
    } else if (stateCodeVal === "fl") {
      return (
        <>
          As an applicant or insured, your credit report or score is being
          requested for insurance rating purposes. The Department of Financial
          Services offers free financial literacy programs to assist you with
          insurance-related questions, including how credit works and how credit
          scores are calculated. To learn more, visit <Link style={{textDecoration: "underline"}} href='http://www.myfloridacfo.com/' isExternal>www.MyFloridaCFO.com."</Link>
        </>
      )
    } else {
        return (
          <></>
        )
    }
  };

  return (
    <>
      <Box my={6} borderTop="1px solid" borderColor="gray.200" />
      <Center pt="30px" pb="20px">
        <Flex direction={"column"}>
          <HStack
            cursor={"pointer"}
            onClick={() => {
              if (!underWriting?.checkboxes?.readFcraStatement) {
                setValidationError((prev) => ({
                  ...prev,
                  underWritingPage: false,
                }));
              }
              setUnderWriting((prev) => ({
                ...prev,
                checkboxes: {
                  ...prev?.checkboxes,
                  readFcraStatement: !prev?.checkboxes?.readFcraStatement,
                },
              }));
            }}
            userSelect="none"
          >
            <Checkbox
              pb={4}
              size="lg"
              isChecked={underWriting?.checkboxes?.readFcraStatement}
              onChange={(e) => {
                if (!underWriting?.checkboxes?.readFcraStatement) {
                  setValidationError((prev) => ({
                    ...prev,
                    underWritingPage: false,
                  }));
                }
                setUnderWriting((prev) => ({
                  ...prev,
                  checkboxes: {
                    ...prev?.checkboxes,
                    readFcraStatement: !prev?.checkboxes?.readFcraStatement,
                  },
                }));
              }}
            />
            <Text
              cursor={"pointer"}
              color="neutral.400"
              fontWeight={600}
              userSelect="none"
            >
              I acknowledge the use of
            </Text>
            <a target="_blank" rel="noopener noreferrer" href="https://uat-odyssey-images.s3.amazonaws.com/Credit-Notice.pdf" data-tip data-for='creditScoreToolTip'>
              <Text color="primary.500" borderBottom="2px solid" borderColor="primary.500" fontWeight={600} userSelect="none" cursor={"pointer"}>Credit or Insurance Credit Score (Credit Notice)</Text>
            </a>
            <ReactTooltip id='creditScoreToolTip' delayHide={500} effect='solid' className="creditScoreToolTip">
              <Box style={{maxWidth: "300px" }}>
                {renderCreditScoreToolTip(stateCodeVal)}
              </Box>
            </ReactTooltip>
          </HStack>
          {validationError?.underWritingPage &&
            !underWriting?.checkboxes?.readFcraStatement && (
              <Text color={"red"}>
                {t("Please read and agree to the FCRA Statement")}.
              </Text>
            )}
        </Flex>
      </Center>
      <style>{
        `.creditScoreToolTip{
            font-size:15px;
            pointer-events: auto !important;
            &:hover {
              visibility: visible !important;
              opacity: 1 !important;
            }
        }
        `
        }
      </style>
    </>
  );
};

const CreditScore = () => {
  const {
    underWriting,
    setUnderWriting,
    validationError,
    setValidationError,
    confirmation,
    setConfirmation,
  } = useStarDataContext();
  return (
    <>
      <Box border="1px solid #E6E9ED" borderRadius="12px" mb={6}>
        <CreditScoreSelect
          confirmation={confirmation}
          setConfirmation={setConfirmation}
          creditScore={underWriting?.creditScore}
          underWriting={underWriting}
          setUnderWriting={setUnderWriting}
          setCreditScore={(value) =>
            setUnderWriting({ ...underWriting, creditScore: value })
          }
          setSecondCreditScore={(value) =>
            setUnderWriting({
              ...underWriting,
              secondaryApplicantsCreditScore: value,
            })
          }
          validationError={validationError}
        />
        <Flex w={"100%"} flexDir={"column"} gap={3} px={5}>
          <Spacer />
        </Flex>
        <TermsAndConditions
          validationError={validationError}
          setValidationError={setValidationError}
          underWriting={underWriting}
          setUnderWriting={setUnderWriting}
        />
      </Box>
    </>
  );
};

export default CreditScore;
