import React, { useEffect, useState } from 'react';
import { Row, Col, Form, Alert } from "react-bootstrap";
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { getEditEmptyQuoteData } from '../../../../services/floodService';

const Occupancy = (props) => {
    const { emptyQuoteData, floodSystemDD, handleChange, handleStateChange, isMobileHome, isCondoAssociation, isAddressSame, validated, isBind } = props;
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [occupancy, setOccupancy] = useState(null);
    const [occupancyTypeDD, setOccupancyTypeDD] = useState([]);
    const [isbuildingAgeIs50, setIsBuildingAgeIs50] = useState(null);
    const [buildingType, setBuildingType] = useState(null);
    const [buildingUseDD, setBuildingUseDD] = useState([]);
    const [isOnPermanentFoun, setIsOnPermanentFoun] = useState(null);
    const [isOntravelTail, setIsOntravelTail] = useState(null);
    const [serialNo, setSerialNo] = useState(null);
    const [NOU, setNOU] = useState(0);
    const [buildingDescription, setBuildingDescription] = useState(null);
    const [info, setInfo] = useState([]);
    const [showInfo, setShowInfo] = useState(false);
    const [isOccupancyUpdated, setIsOccupancyUpdated] = useState(false);
    const [homeAnchored, setHomeAnchored] = useState(null);

    // set state
    useEffect(() => {
        if (Object.keys(emptyQuoteData).length > 0) {
            setOccupancy(emptyQuoteData.propertyInfo.s_Occupancy || null);
            setBuildingType(emptyQuoteData.propertyInfo.s_BuildingUse || null);
            setNOU(emptyQuoteData.propertyInfo.s_NoOfUnits || null);
            setIsBuildingAgeIs50(emptyQuoteData.propertyInfo.s_InsuredResides || null);
            setBuildingDescription(emptyQuoteData.propertyInfo.s_BuildingDescription || null);
            setHomeAnchored(emptyQuoteData.propertyInfo.s_homeAnchored || null);
            setIsOnPermanentFoun(emptyQuoteData.propertyInfo.s_IsPermanentFoundation || null);
            setIsOntravelTail(emptyQuoteData.propertyInfo.s_IsBldgTravelTailer || null);
            setSerialNo(emptyQuoteData.propertyInfo.s_SerialNo || null);
        }
    }, [emptyQuoteData])

    // trigger on Update Occupancy Type & set Building Use
    useEffect(() => {
        var dd = [], occupancyTypeDD = [];
        if (Object.keys(floodSystemDD).length > 0) {
            if (['SINGLEFAMILY20', 'RESIDENTIALMOBILEHOME20'].includes(occupancy)) {
                dd = floodSystemDD.FLOODBUILDINGUSE20.filter(x => ['MAINHOUSEBLDG20', 'DETACHEDGUESTHOUSE20', 'OTHER20'].includes(x.s_AppCodeName));
            }
            else if (['RESIDENTIALUNIT20'].includes(occupancy)) {
                dd = floodSystemDD.FLOODBUILDINGUSE20.filter(x => ['APARTMENTUNIT20', 'COOPERATIVEUNIT20', 'RESIDENTIALCONDOUNITRB20', 'RESIDENTIALCONDOUNITNRB20', 'OTHER20'].includes(x.s_AppCodeName));
            }
            else if (['TWOFOURFAMILY20', 'OTHERRESIDENTIAL20'].includes(occupancy)) {
                if (occupancy === 'OTHERRESIDENTIAL') {
                    dd = floodSystemDD.FLOODBUILDINGUSE20.filter(x => (['MAINHOUSEBLDG20', 'ENTIREAPARTMENTBLDG20', 'ENTIRECOOPERATIVEBLDG20', 'OTHER20'].includes(x.s_AppCodeName)));
                } else {
                    dd = floodSystemDD.FLOODBUILDINGUSE20.filter(x => (['MAINHOUSEBLDG20', 'DETACHEDGUESTHOUSE20', 'ENTIREAPARTMENTBLDG20', 'ENTIRECOOPERATIVEBLDG20', 'OTHER20'].includes(x.s_AppCodeName)));
                }
            }
            else if (['RESIDENTIALUNIT20'].includes(occupancy)) {
                dd = floodSystemDD.FLOODBUILDINGUSE20.filter(x => ['APARTMENTUNIT20', 'COOPERATIVEUNIT20', 'RESIDENTIALCONDOUNITRB20', 'RESIDENTIALCONDOUNITNRB20', 'OTHER20'].includes(x.s_AppCodeName));
            }
            else if (['NONRESIDENTIALMOBILEHOME20', 'NONRESIDENTIALBUILD20'].includes(occupancy)) {
                dd = floodSystemDD.FLOODBUILDINGUSE20.filter(x => ['DETACHEDGARAGE20', 'AGRICULTURALBLDG20', 'RECREATIONBLDG20', 'TOOLSTORAGESHED20', 'COMMERCIAL20', 'HOUSEOFWORSHIP20', 'GOVOWNED20', 'OTHERNONRESIDENTIAL20'].includes(x.s_AppCodeName));
            }
            else if (['NONRESIDENTIALUNIT20'].includes(occupancy)) {
                dd = floodSystemDD.FLOODBUILDINGUSE20.filter(x => ['RECREATIONBLDG20', 'COMMERCIAL20', 'HOUSEOFWORSHIP20', 'GOVOWNED20', 'OTHERNONRESIDENTIAL20'].includes(x.s_AppCodeName));
            } else if (['RESIDENTIALCONDOBUILD20'].includes(occupancy)) {
                dd = floodSystemDD.FLOODBUILDINGUSE20.filter(x => ['ENTIRERESCONDOBLDG20'].includes(x.s_AppCodeName));
            }

            // For Occupancy Dropdown
            if (isMobileHome && floodSystemDD) {
                occupancyTypeDD = floodSystemDD.FLOODOCCUPANCY20.filter(x => ['RESIDENTIALMOBILEHOME20', 'NONRESIDENTIALMOBILEHOME20'].includes(x.s_AppCodeName))
            } else if (floodSystemDD) {
                occupancyTypeDD = floodSystemDD.FLOODOCCUPANCY20.filter(x => !['RESIDENTIALMOBILEHOME20', 'NONRESIDENTIALMOBILEHOME20'].includes(x.s_AppCodeName))

                // For Occupancy DD if Condo Association True
                if (isCondoAssociation && floodSystemDD) {
                    occupancyTypeDD = occupancyTypeDD && occupancyTypeDD.filter(x => !['SINGLEFAMILY20', 'TWOFOURFAMILY20', 'OTHERRESIDENTIAL20'].includes(x.s_AppCodeName))
                }
            }

        }
        setBuildingUseDD(dd);
        // update occupancy type dropdown
        setOccupancyTypeDD(occupancyTypeDD)
    }, [occupancy, floodSystemDD, isMobileHome, isCondoAssociation])

    // To clear all fields onChanged occupancy
    useEffect(() => {
        if (isOccupancyUpdated) {
            setIsOnPermanentFoun('');
            setIsOntravelTail('');
            setSerialNo('');
            setIsBuildingAgeIs50('');
            setBuildingType('');
            setNOU('');
            setBuildingDescription('');
            setIsOccupancyUpdated(false);
        }
    }, [occupancy])

    //Update occupancy type
    useEffect(() => {
        handleStateChange('OCCUPANCY', occupancy, 'OccupancyType');
        NOU && handleStateChange('OCCUPANCY', parseInt(NOU), 'NOU');
    }, [occupancy, NOU]);

    // trigger on Update isOnPermanentFoun value
    useEffect(() => {
        var temp = [...info];
        var text = 'Mobile homes and Travel Trailers are not insurable if not on a permanent foundation.';
        if (['RESIDENTIALMOBILEHOME20', 'NONRESIDENTIALMOBILEHOME20'].includes(occupancy) && isOnPermanentFoun === 'NO') {
            if (temp.indexOf(text) === -1) {
                temp.push(text);
            }
        } else {
            temp.indexOf(text) !== -1 && temp.splice(temp.indexOf(text), 1)
        }
        setInfo(temp);
    }, [isOnPermanentFoun])

    // trigger on Update isOntravelTail value
    useEffect(() => {
        var temp = [...info];
        var text = 'A Travel Trailers with wheels attached are not eligible for flood insurance.';
        if (['RESIDENTIALMOBILEHOME20', 'NONRESIDENTIALMOBILEHOME20'].includes(occupancy) && isOntravelTail === 'NO') {
            if (temp.indexOf(text) === -1) {
                temp.push(text);
            }
        } else {
            temp.indexOf(text) !== -1 && temp.splice(temp.indexOf(text), 1)
        }
        setInfo(temp);
    }, [isOntravelTail])

    // to show warning alert box
    useEffect(() => {
        setShowInfo(info.length > 0 ? true : false);
    }, [info])

    // To Update Parent Component State
    useEffect(() => {
        handleStateChange('OCCUPANCY', isbuildingAgeIs50 === 'YES' ? true : false, 'isInsuredResides');
        if (occupancy === 'RESIDENTIALUNIT20') {
            handleStateChange('OCCUPANCY', buildingType, 'buildingType');
        }
    }, [isbuildingAgeIs50, buildingType])

    // if dropdown has single value then make it selected
    useEffect(() => {
        if (buildingUseDD.length == 1) {
            setBuildingType(buildingUseDD[0].s_AppCodeName);
        }
    }, [buildingUseDD])

    // empty serial no
    // useEffect(() => {
    //     if (![null, 'MANUFACTURESPECIFICATION', 'MANAGMENTSPECIFICATION', 'TIESTOGROUNDANCHORED'].includes(homeAnchored)) {
    //         var emptyQuote = emptyQuoteData;
    //         emptyQuote['propertyInfo']['s_SerialNo'] = '';
    //         setSerialNo('');
    //         dispatch(getEditEmptyQuoteData(emptyQuote));
    //     }
    // }, [homeAnchored])

    // update state in reducer
    const handleLocalStateChange = (state, value) => {
        if (![null, ''].includes(value)) {
            var emptyQuote = emptyQuoteData;
            switch (state) {
                case 'BT':
                    if (![null, 'OTHERNONRESIDENTIAL20', 'OTHER20'].includes(value)) {
                        emptyQuote['propertyInfo']['s_BuildingDescription'] = null;
                        setBuildingDescription(null);
                    }
                    break;
                case 'OCCUPANCY':
                    if (![null, 'RESIDENTIALUNIT20', 'TWOFOURFAMILY20', 'OTHERRESIDENTIAL20', 'RESIDENTIALCONDOBUILD20', 'NONRESIDENTIALBUILD20', 'NONRESIDENTIALUNIT20'].includes(value)) {
                        emptyQuote['propertyInfo']['s_NoOfUnits'] = 0;
                        setNOU(0);
                    }
                    if (!([null, 'SINGLEFAMILY20', 'RESIDENTIALMOBILEHOME20', 'RESIDENTIALUNIT20', 'TWOFOURFAMILY20'].includes(value)) && (!isCondoAssociation)) {
                        emptyQuote['propertyInfo']['s_InsuredResides'] = 'NO';
                        setIsBuildingAgeIs50('NO');
                    }
                    if (![null, 'RESIDENTIALMOBILEHOME20', 'NONRESIDENTIALMOBILEHOME20'].includes(value)) {
                        emptyQuote['propertyInfo']['s_IsPermanentFoundation'] = 'NO';
                        emptyQuote['propertyInfo']['s_IsBldgTravelTailer'] = 'NO';
                        emptyQuote['propertyInfo']['s_NotAnchored'] = 'NO';
                        emptyQuote['propertyInfo']['s_WithManufacture'] = 'NO';
                        emptyQuote['propertyInfo']['s_WithFloodpain'] = 'NO';
                        emptyQuote['propertyInfo']['s_OverTheTop'] = 'NO';
                        emptyQuote['propertyInfo']['s_SerialNo'] = '';
                        setSerialNo('');
                    }
                    break;
                default:
                    break;
            }
            dispatch(getEditEmptyQuoteData(emptyQuote));
        }
    }

    return (
        <Form validated={validated}>
            {
                showInfo && (
                    // Warning Info Array
                    info.length > 0 && info.map((x, i) => {
                        return (
                            <Col key={i} md="12" className='p-0'>
                                <Alert color={'warning'} isOpen={showInfo} className='p-1 pl-2 m-0 mb-1' >{x}</Alert>
                            </Col>
                        );
                    })
                )
            }
            <Row className="align-items-end">
                <Col xs="12" sm="12" md="6" lg="3" xl="3">
                    <Form.Group
                        className="search-quote-form-group"
                        controlId="formBasicEmail"
                    >
                        <Form.Label className="dashboard-group-label">
                            {t('Please select an Occupancy Type')}
                        </Form.Label>
                        <Form.Select
                            className="dashboard-group-input-flood"
                            id="select-ym-option"
                            name="s_Occupancy"
                            title={t('Please select an Occupancy Type')}
                            parentobject="propertyInfo"
                            value={occupancy}
                            onChange={(e) => {
                                setOccupancy(e.currentTarget.value);
                                handleLocalStateChange('OCCUPANCY', e.currentTarget.value)
                                handleChange(e);
                            }}
                            required
                            disabled={isBind}
                        >
                            <option value={''}>{t('Select')}</option>
                            {
                                Object.keys(occupancyTypeDD).length > 0 && occupancyTypeDD.map((v, i) => {
                                    return <option key={i} value={v.s_AppCodeName}>{t(v.s_AppCodeNameForDisplay)}</option>
                                })
                            }
                        </Form.Select>
                    </Form.Group>
                </Col>
                {
                    (['RESIDENTIALUNIT20', 'TWOFOURFAMILY20', 'OTHERRESIDENTIAL20', 'RESIDENTIALCONDOBUILD20', 'NONRESIDENTIALBUILD20', 'NONRESIDENTIALUNIT20'].includes(occupancy)) && (
                        <Col xs="12" sm="12" md="6" lg="2" xl="2">
                            <Form.Group
                                className="search-quote-form-group"
                                controlId="formBasicEmail"
                            >
                                <Form.Label className="dashboard-group-label">
                                    {t('Total # of units in Building')}:
                                </Form.Label>
                                <Form.Control
                                    className="dashboard-group-input-flood"
                                    type="number"
                                    placeholder="00"
                                    name="s_NoOfUnits"
                                    title={t('# of units')}
                                    parentobject="propertyInfo"
                                    value={NOU}
                                    min={0}
                                    onChange={(e) => {
                                        handleChange(e);
                                        setNOU(e.currentTarget.value);
                                    }}
                                    disabled={isBind}
                                />
                            </Form.Group>
                        </Col>
                    )
                }
                {
                    // Only Show if Occupancy - SINGLEFAMILY20, RESIDENTIALMOBILEHOME20, RESIDENTIALUNIT20, TWOFOURFAMILY20 && Condo Association - False
                    ((['SINGLEFAMILY20', 'RESIDENTIALMOBILEHOME20', 'RESIDENTIALUNIT20', 'TWOFOURFAMILY20'].includes(occupancy)) && (!isCondoAssociation)) && (
                        <>
                            <Col xs="12" sm="12" md="6" lg="3" xl="3">
                                <Form.Group
                                    className="search-quote-form-group"
                                    controlId="formBasicEmail"
                                >
                                    <Form.Label className="dashboard-group-label">
                                        {t("Will the applicant or applicant's spouse live in this building more than 50% of the year")}
                                    </Form.Label>
                                    <Form.Select
                                        className="dashboard-group-input-flood"
                                        id="select-ym-option"
                                        name="s_InsuredResides"
                                        title={t("Will the applicant or applicant's spouse live in this building more than 50% of the year")}
                                        parentobject="propertyInfo"
                                        value={isbuildingAgeIs50}
                                        onChange={(e) => {
                                            setIsBuildingAgeIs50(e.currentTarget.value);
                                            handleChange(e);
                                        }}
                                        disabled={isBind}
                                    >
                                        <option value={''}>{t('Select')}</option>
                                        {
                                            Object.keys(floodSystemDD).length > 0 && floodSystemDD.YESNOONLY.map((v, i) => {
                                                return <option key={i} value={v.s_AppCodeName}>{t(v.s_AppCodeNameForDisplay)}</option>
                                            })
                                        }
                                    </Form.Select>
                                </Form.Group>
                            </Col>
                            {(isbuildingAgeIs50 === 'YES' && isAddressSame === 'NO') &&
                                <Col md="12" className='p-1'>
                                    <p className="mb-1" style={{ textAlign: 'justify', color: '#008CBA', fontStyle: 'italic' }} >{t('Please provide documentation of primary residence status')}.</p>
                                </Col>
                            }
                        </>
                    )
                }
                <Col xs="12" sm="12" md="6" lg="3" xl="3">
                    <Form.Group
                        className="search-quote-form-group"
                        controlId="formBasicEmail"
                    >
                        <Form.Label className="dashboard-group-label">
                            {t("Please select the value that best describes the building")} :
                        </Form.Label>
                        <Form.Select
                            className="dashboard-group-input-flood"
                            id="select-ym-option"
                            name="s_BuildingUse"
                            title={t("Please select the value that best describes the building")}
                            parentobject="propertyInfo"
                            value={buildingType}
                            onChange={(e) => {
                                setBuildingType(e.currentTarget.value);
                                handleLocalStateChange('BT', e.currentTarget.value);
                                handleChange(e);
                            }}
                            required
                            disabled={isBind}
                        >
                            <option value={''}>{t('Select')}</option>
                            {
                                Object.keys(buildingUseDD).length > 0 && buildingUseDD.map((v, i) => {
                                    return <option key={i} value={v.s_AppCodeName}>{t(v.s_AppCodeNameForDisplay)}</option>
                                })
                            }
                        </Form.Select>
                    </Form.Group>
                </Col>
                {['OTHERNONRESIDENTIAL20', 'OTHER20'].includes(buildingType) && (
                    <Col xs="12" sm="12" md="6" lg="2" xl="2">
                        <Form.Group
                            className="search-quote-form-group"
                            controlId="formBasicEmail"
                        >
                            <Form.Label className="dashboard-group-label">
                                {t("Property Description")} :
                            </Form.Label>
                            <Form.Control
                                className="dashboard-group-input-flood"
                                type="text"
                                placeholder={t("Property Description")}
                                name="s_BuildingDescription"
                                title={t('Property Description')}
                                parentobject="propertyInfo"
                                value={buildingDescription}
                                onChange={(e) => {
                                    handleChange(e);
                                    setBuildingDescription(e.currentTarget.value);
                                }}
                                required
                                autoComplete="off"
                                disabled={isBind}
                            />
                        </Form.Group>
                    </Col>
                )}
                {
                    ['RESIDENTIALMOBILEHOME20', 'NONRESIDENTIALMOBILEHOME20'].includes(occupancy) && (
                        <>
                            <Col xs="12" sm="12" md="6" lg="3" xl="3">
                                <Form.Group
                                    className="search-quote-form-group"
                                    controlId="formBasicEmail"
                                >
                                    <Form.Label className="dashboard-group-label">
                                        {t("Is on Permanent foundation")} ?
                                    </Form.Label>
                                    <Form.Select
                                        className="dashboard-group-input-flood"
                                        id="select-ym-option"
                                        name="s_IsPermanentFoundation"
                                        title={t("Is on Permanent foundation")}
                                        parentobject="propertyInfo"
                                        value={isOnPermanentFoun}
                                        onChange={(e) => {
                                            setIsOnPermanentFoun(e.currentTarget.value);
                                            handleChange(e);
                                        }}
                                        disabled={isBind}
                                    >
                                        <option value={''}>{t('Select')}</option>
                                        {
                                            Object.keys(floodSystemDD).length > 0 && floodSystemDD.YESNOONLY.map((v, i) => {
                                                return <option key={i} value={v.s_AppCodeName}>{t(v.s_AppCodeNameForDisplay)}</option>
                                            })
                                        }
                                    </Form.Select>
                                </Form.Group>
                            </Col>
                            <Col xs="12" sm="12" md="6" lg="3" xl="3">
                                <Form.Group
                                    className="search-quote-form-group"
                                    controlId="formBasicEmail"
                                >
                                    <Form.Label className="dashboard-group-label">
                                        {t("If the building is a travel trailer, have the wheels and tires been removed")} ?
                                    </Form.Label>
                                    <Form.Select
                                        className="dashboard-group-input-flood"
                                        id="select-ym-option"
                                        name="s_IsBldgTravelTailer"
                                        title={t("If the building is a travel trailer, have the wheels and tires been removed")}
                                        parentobject="propertyInfo"
                                        value={isOntravelTail}
                                        onChange={(e) => {
                                            setIsOntravelTail(e.currentTarget.value);
                                            handleChange(e);
                                        }}
                                        disabled={isBind}
                                    >
                                        <option value={''}>{t('Select')}</option>
                                        {
                                            Object.keys(floodSystemDD).length > 0 && floodSystemDD.YESNOONLY.map((v, i) => {
                                                return <option key={i} value={v.s_AppCodeName}>{t(v.s_AppCodeNameForDisplay)}</option>
                                            })
                                        }
                                        <option key='2' value='NA'>Not Applicable</option>
                                    </Form.Select>
                                </Form.Group>
                            </Col>
                            <Col xs="12" sm="12" md="6" lg="3" xl="3">
                                <Form.Group
                                    className="search-quote-form-group"
                                    controlId="formBasicEmail"
                                >
                                    <Form.Label className="dashboard-group-label">
                                        {t('How is the home anchored?')}
                                    </Form.Label>
                                    <Form.Select
                                        className="dashboard-group-input-flood"
                                        id="select-ym-option"
                                        name="s_homeAnchored"
                                        title={t('How is the home anchored?')}
                                        parentobject="propertyInfo"
                                        value={homeAnchored}
                                        onChange={(e) => {
                                            setHomeAnchored(e.currentTarget.value);
                                            handleChange(e);
                                        }}
                                        disabled={isBind}
                                    >
                                        <option value={''}>{t('Select')}</option>
                                        {
                                            Object.keys(floodSystemDD).length > 0 && floodSystemDD.FLDHOMEANCHORED.map((v, i) => {
                                                return <option key={i} value={v.s_AppCodeName}>{t(v.s_AppCodeNameForDisplay)}</option>
                                            })
                                        }
                                    </Form.Select>
                                </Form.Group>
                            </Col>
                            <Col xs="12" sm="12" md="6" lg="2" xl="2">
                                <Form.Group
                                    className="search-quote-form-group"
                                    controlId="formBasicEmail"
                                >
                                    <Form.Label className="dashboard-group-label">
                                        {t("Serial #")}
                                    </Form.Label>
                                    <Form.Control
                                        className="dashboard-group-input-flood"
                                        type="text"
                                        placeholder={t("Serial #")}
                                        name="s_SerialNo"
                                        title={t('Serial #')}
                                        parentobject="propertyInfo"
                                        value={serialNo}
                                        onChange={(e) => {
                                            handleChange(e);
                                            setSerialNo(e.currentTarget.value);
                                        }}
                                        autoComplete="off"
                                        disabled={isBind}
                                    />
                                </Form.Group>
                            </Col>
                        </>
                    )
                }
            </Row>
        </Form>
    )
}

export default Occupancy