import React, { useState, useEffect } from "react";
import { Row, Col, Form, Alert } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import ListRequiredDoc from "./ListRequiredDoc";
import Moment from 'moment';
import { toast } from 'react-toastify';
import { getRefundAmount } from "../../../services/floodService";
import "react-datepicker/dist/react-datepicker.css";
import "./cancel.scss";

const Refund = (props) => {
    const { loading, setLoading, showAlert, alertText, cancelJSON, poTransPK, policyPK, policyNumber, setCancelJSON, setDataInCancelJSON, transEffectiveTo } = props;
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const [transEffectiveFrom, setTransEffectiveFrom] = useState(null);
    const [refundAmount, setRefundAmount] = useState(0);
    const [isRefundAPICalled, setIsRefundAPICalled] = useState(false);

    useEffect(() => {
        if (cancelJSON) {
            setTransEffectiveFrom(Moment(cancelJSON.cancelDate).format("MM-DD-YYYY") || null);
            setRefundAmount(cancelJSON.refundAmt);

            if (cancelJSON.refundAmt === 0) {
                getCancelRefund();
            }
        }
    }, [cancelJSON])

    // get refund amount
    const getCancelRefund = async () => {
        // avoid multiple calling if refund is 0
        if(isRefundAPICalled == true) { return false; }
        setIsRefundAPICalled(true);
        setLoading(true);
        var params = {
            "prevPoTrans": poTransPK,
            "cancelReasonCode": cancelJSON.cancelReason,
            "cancelEffFrom": cancelJSON.cancelDate,
            "cancelEffTo": transEffectiveTo
        };
        await dispatch(getRefundAmount(params)).then((res) => {
            if (res.status === "Y") {
                setDataInCancelJSON(res.refundAmt, "refundAmt");
                if (res.msg.length > 0) {
                    toast.info(t(res.msg))
                }
            } else {
                toast.error(t("Something Went Wrong"));
            }
            setLoading(false);
        }).catch(() => {
            setLoading(false);
            toast.error(t("Something Went Wrong"));
        });
    }

    return (
        <div className="policy-details-section mt-3 interview-questions-div">
            {
                showAlert && (
                    <Col md='12' className='p-0'>
                        <Alert color={'warning'} isOpen={true} className='p-1 pl-2 m-0 mb-1' >
                            <i className='fa fa-exclamation-triangle'></i>&nbsp;&nbsp;{alertText}
                        </Alert>
                    </Col>
                )
            }
            <Row>
                <Col
                    xs="12"
                    sm="12"
                    md="12"
                    lg="12"
                    xl="12"
                    style={{ padding: 0 }}
                >
                    <h5>
                        {t('Cancellation Refund')}
                    </h5>
                </Col>
                <Col xs="12" className="mt-3">
                    <Form.Group
                        controlId="formBasicEmail"
                    >
                        <Row>
                            <Col xs="6">
                                <Form.Label className="group-label">
                                    {t('Cancellation Effective date')}: <b>{transEffectiveFrom}</b>
                                </Form.Label>
                            </Col>
                            <Col xs="6">
                                <Form.Label className="group-label">
                                    {t('Refund amount')}: <b>${refundAmount || 0}</b>
                                </Form.Label>
                            </Col>
                            <Col xs="12" sm="12" md="12" lg="12" xl="12" style={{ padding: 0 }} className="mt-3">
                                <div className="header-div">
                                    <span className="header-title-name">{t('Required documents')}</span>
                                </div>
                                <ListRequiredDoc
                                    policyNumber={policyNumber}
                                    policyPK={policyPK}
                                    cancelJSON={cancelJSON}
                                    setCancelJSON={setCancelJSON}
                                    loading={loading}
                                    setLoading={setLoading}
                                />
                            </Col>
                        </Row>
                    </Form.Group>
                </Col>
            </Row>
        </div>
    )
}

export default Refund;
