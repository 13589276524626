import { useEffect, useState, useCallback } from "react";
import { useStarDataContext } from "../../StarDataContext";
import { toast } from "react-toastify";
import { createStarQuote } from "../../apis/createStarQuote";
import { CreateStarQuotePool } from "../../database/CreateStarQuotePool";
import { PaymentSection } from "./PaymentSection";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Box,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  Spinner,
  Stack,
  Code,
} from "@chakra-ui/react";
import ErrorLogModal from "../../utils/ErrorLog";
import { getFees } from "../../../create-quote/api/getFees";
import states from "../../../common/States";

export default function Payments() {
  const {
    location,
    setStarDataObject,
    policy,
    underWriting,
    discounts,
    coverages,
    confirmation,
    starDataObject,
    selectedRisk,
    uniqueID,
    roofProperties,
    lossHistoryData,
    saveMortgagee,
    primaryMailingAddress,
    currentZipData,
    vendors,
    feesData,
    setFeesData,
    saveDDCoverages,
  } = useStarDataContext();
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [errorData, setErrorData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [requestBody, setRequestBody] = useState(null);
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
  const [isErrorModalOpen1, setIsErrorModalOpen1] = useState(false);
  const [errorData1, setErrorData1] = useState(["Test", "Test"]);

  function getStateCode(input) {
    if (typeof input !== "string") {
      return "";
    }
    const normalizedInput = input.toLowerCase();

    const matchedStateByName = states.find(
      (state) => state.name.toLowerCase() === normalizedInput
    );

    if (matchedStateByName) {
      return matchedStateByName.abbr;
    }
    const matchedStateByAbbr = states.find(
      (state) => state.abbr.toLowerCase() === normalizedInput
    );

    if (matchedStateByAbbr) {
      return matchedStateByAbbr.abbr;
    } else {
      return "";
    }
  }

  const fetchData = useCallback(async () => {
    if (!requestBody) return;
    const data = starDataObject?.save_quote_response?.data;
    let policyNumberOrFalsy =
      typeof data?.policyNo === "string" && data?.poTransPK
        ? data.policyNo
        : false;
    setIsLoading(true);
    try {
      const res = await createStarQuote({
        requestBody: requestBody,
        quoteId: policyNumberOrFalsy,
      });
      if (res?.status === true) {
        const saveQuoteResponse = starDataObject?.save_quote_response;
        let toastMessage = "";

        if (saveQuoteResponse) {
          setStarDataObject({
            ...res,
            ...{ save_quote_response: saveQuoteResponse },
          });
          toastMessage = "Quote Updated Successfully";
        } else {
          setStarDataObject(res);
          toastMessage = "Quote Created Successfully";
        }

        fetchFeesData(
          res?.data?.quote_premiums?.original_model?.total_premium ||
            res?.data?.quote_premiums?.original_model?.final_premium ||
            0,
          res?.save_quote_response?.data?.policyNo || 0
        );
        setErrorData(null);
        toast.success(toastMessage, {
          position: "top-center",
          autoClose: 2000,
        });
        setIsSuccessModalOpen(true);
      } else {
        setErrorData(res);
        setIsErrorModalOpen(true);
      }
      if (res?.quote?.msg) {
        toast.success(res?.quote?.msg, {
          position: "top-center",
        });
      }
    } catch (error) {
      console.log(error);
      toast.error(
        "An error occurred while fetching the data. Please try again later.",
        {
          position: "top-center",
        }
      );
    } finally {
      setIsLoading(false);
    }
  }, [requestBody, setStarDataObject]);

  const fetchFeesData = async (premium, quoteId) => {
    setIsLoading(true);
    try {
      const res = await getFees(
        {
          prTransTypeCode: "NEWBUSINESS", // New Quote Query
          premium: Number(premium),
          productId: "1", // Hardcoded for StarInsurance
          state: getStateCode(location?.state) || location?.state,
          quoteId: quoteId,
        },
        "star"
      );
      if (res?.data?.success) {
        setFeesData(res?.data?.data);
        // toast.success(res?.data?.message, {
        //   position: "top-center",
        //   autoClose: 2000,
        // });
      } else {
        toast.error(res?.data?.message, {
          position: "top-center",
          autoClose: 2000,
        });
      }
    } catch (error) {
      console.log(error);
      toast.error(
        "An error occurred while fetching the fees. Please try again later.",
        {
          position: "top-center",
        }
      );
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (
      !policy ||
      !underWriting ||
      !discounts ||
      !coverages ||
      !location ||
      !confirmation ||
      !selectedRisk ||
      !uniqueID
    )
      return;
    setRequestBody(
      CreateStarQuotePool(
        policy,
        underWriting,
        discounts,
        coverages,
        location,
        confirmation,
        selectedRisk,
        uniqueID,
        roofProperties,
        lossHistoryData,
        saveMortgagee,
        primaryMailingAddress,
        currentZipData,
        vendors,
        saveDDCoverages
      )
    );
  }, [
    policy,
    underWriting,
    discounts,
    coverages,
    location,
    confirmation,
    selectedRisk,
    uniqueID,
    roofProperties,
    lossHistoryData,
    saveMortgagee,
    primaryMailingAddress,
    currentZipData,
    vendors,
    saveDDCoverages,
  ]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const renderErrorContent = (errorData) => {
    if (!errorData) return null;

    const renderErrorItem = (item, index) => {
      if (!item || (typeof item === "string" && !item.trim())) return null;

      return (
        <Box key={index} mb={4}>
          <Alert status="error" borderRadius="md">
            <AlertIcon />
            <Box flex="1">
              <AlertTitle>{item.errorField || "Error"}:</AlertTitle>
              <AlertDescription>
                {item.message || item.toString()}
              </AlertDescription>
            </Box>
          </Alert>
        </Box>
      );
    };

    const hasError =
      errorData.error &&
      ((Array.isArray(errorData.error) && errorData.error.length > 0) ||
        (typeof errorData.error === "string" && errorData.error.trim()) ||
        (typeof errorData.error === "object" &&
          !Array.isArray(errorData.error)));

    const hasDetailedError =
      errorData.detailed_error &&
      ((Array.isArray(errorData.detailed_error) &&
        errorData.detailed_error.length > 0) ||
        (typeof errorData.detailed_error === "string" &&
          errorData.detailed_error.trim()) ||
        (typeof errorData.detailed_error === "object" &&
          !Array.isArray(errorData.detailed_error)));

    return (
      <>
        {/* Handle errorData.error */}
        {Array.isArray(errorData.error) &&
          errorData.error.map((e, index) => renderErrorItem(e, index))}
        {typeof errorData.error === "string" &&
          errorData.error.trim() &&
          renderErrorItem({ message: errorData.error })}
        {typeof errorData.error === "object" &&
          !Array.isArray(errorData.error) &&
          renderErrorItem(errorData.error)}

        {/* Handle errorData.detailed_error */}
        {Array.isArray(errorData.detailed_error) &&
          errorData.detailed_error.map((e, index) => renderErrorItem(e, index))}
        {typeof errorData.detailed_error === "string" &&
          errorData.detailed_error.trim() &&
          renderErrorItem({ message: errorData.detailed_error })}
        {typeof errorData.detailed_error === "object" &&
          !Array.isArray(errorData.detailed_error) &&
          renderErrorItem(errorData.detailed_error)}

        {/* If no recognized error format is found */}
        {!hasError && !hasDetailedError && (
          <Alert status="error" borderRadius="md">
            <AlertIcon />
            <Box flex="1">
              <AlertTitle>Unknown Error</AlertTitle>
              <AlertDescription>An unexpected error occurred.</AlertDescription>
            </Box>
          </Alert>
        )}
      </>
    );
  };

  return (
    <>
      <ErrorLogModal
        type="Create Quote"
        isOpen={isErrorModalOpen1}
        onClose={() => setIsErrorModalOpen1(false)}
        errorData={errorData1}
      />
      {isLoading && (
        <Stack justify="center" align="center" h="50vh">
          <Spinner size="xl" />
        </Stack>
      )}
      {!isLoading && (
        <>
          <PaymentSection
            location={location}
            starDataObject={starDataObject}
            underWriting={underWriting}
            policy={policy}
            feesData={feesData}
          />
          <Modal
            isCentered
            scrollBehavior="inside"
            isOpen={isErrorModalOpen}
            onClose={() => setIsErrorModalOpen(false)}
            size={"5xl"}
          >
            <ModalOverlay />
            <ModalContent>
              <ModalHeader>Developer Error Logs</ModalHeader>
              <ModalCloseButton />
              <ModalBody>{renderErrorContent(errorData)}</ModalBody>
              <ModalFooter>
                <Button
                  colorScheme="blue"
                  onClick={() => setIsErrorModalOpen(false)}
                >
                  Close
                </Button>
              </ModalFooter>
            </ModalContent>
          </Modal>
          <Modal
            isOpen={false}
            onClose={() => setIsSuccessModalOpen(false)}
            isCentered
            scrollBehavior="inside"
            size={"5xl"}
          >
            <ModalOverlay bg="blackAlpha.600" />
            <ModalContent>
              <ModalHeader>Success Response Logs</ModalHeader>
              <ModalCloseButton />
              <ModalBody
                bgColor={"neutral.50"}
                maxH="800px"
                overflowY="auto"
                px={6}
                py={4}
              >
                <pre>
                  <Code
                    bgColor={"neutral.50"}
                    fontSize={"lg"}
                    color={"primary.500"}
                  >
                    {JSON.stringify(starDataObject, null, 2)}
                  </Code>
                </pre>
              </ModalBody>
              <ModalFooter>
                <Button
                  colorScheme="blue"
                  onClick={() => setIsSuccessModalOpen(false)}
                >
                  Close
                </Button>
              </ModalFooter>
            </ModalContent>
          </Modal>
        </>
      )}
    </>
  );
}
