  import React, { useEffect, useRef, useState } from "react";
  import { useDispatch, useSelector } from 'react-redux';
  import { Row, Col, Form, Button, Modal, ModalDialog, Tooltip } from "react-bootstrap";
  import GoogleMap from "../google-map/GoogleMap";
  import { useTranslation } from "react-i18next";
  import "./flood-property-detail.scss";
  import { getEditEmptyQuoteData, updateInitQuoteApi } from "../../../../../../services/floodService";
  import { Navigate } from "react-router-dom";
  import Validation from "../../Validation";
  import DatePicker from "react-datepicker";
  import Draggable from 'react-draggable';
  import {AiOutlineInfoCircle,AiOutlineQuestionCircle} from "react-icons/ai";
  import ReactTooltip from "react-tooltip";
  import Slab from "../../../../../../assets/foundation-type-images/Slab.jpg";
  import Crowlspace from "../../../../../../assets/foundation-type-images/Crawlspace.jpg";
  import Basement from "../../../../../../assets/foundation-type-images/Basement.jpg";
  import elevatedWithoutEnclosure from "../../../../../../assets/foundation-type-images/Elevated_Without_Enclosure.jpg";
  import elevatedWithEnclosure from "../../../../../../assets/foundation-type-images/Elevated_With_Enclosure.jpg";
  import twoToFour from "../../../../../../assets/foundation-type-images/Two_To_Four_Family_Building.png";
  import walkout from "../../../../../../assets/foundation-type-images/Walkout.jpg";
  // import Slab from "../../../../../../assets/foundation-type-images/Slab.jpg";
  import ModeRouter from "./../../../form-mode/ModeRouter";
  import { getTenant } from "../../../../../../services/commonService";

  const FloodPropertyDetail = (props) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { handleUpdateArray } = props;
    const { emptyQuoteData, initApiObject, floodSystemDD, pageMode } = useSelector(state => state.flood);
    const [validated, setValidated] = useState(false);
    const [yearBuilt, setYearBuiltd] = useState('');
    const [usage, setUsage] = useState('');
    const [foundation, setFoundation] = useState('');
    const [bldgConstructionType, setBldgConstructionType] = useState('');
    const [noOfFloors, setNoOfFloors] = useState('');
    const [areaInSqFt, setAreaInSqFt] = useState('');
    const [totalSqFootage, setTotalSqFootage] = useState('');
    const [viewType, setViewType] = useState('');
    const [isLocated, setIsLocated] = useState(false);
    const [DOC, setDOC] = useState(null);
    const [label, setLabel] = useState('');
    const [isStateSet, setIsStateSet] = useState(false);
    const [model, setModal] = useState(null);
    const [sflabel, setSflabel] = useState('Square footage');
    const [isResidentialUnit, setIsResidentialUnit] = useState('YES');
    const [condoAssociation, setCondoAssociation] = useState('');
    const [floorOfUnit, setFloorOfUnit] = useState('');
    const [description, setDescription] = useState('');
    const [descLabel, setDescLabel] = useState('Building Description');
    const [noOfUnit, setNoOfUnit] = useState(0);
    const [descDD, setDescDD] = useState([]);
    const [noOfFloorLabel, setNoOfFloorLabel] = useState('');
    const [occupancyType, setOccupancyType] = useState('');
    const [occupancyDD, setOccupancyDD] = useState([]);
    const [rcvVal, setRcvVal] = useState(0);
    const [persqft, setPerSqft] = useState(0);
    const nextbuttonRef = useRef();
    const locatedRef = useRef();
    const estreplacementcostRef = useRef();
    const foundationRef = useRef();
    const tenant=getTenant()
    
    const toggle = (e) => {
      setValidated(e);
      setModal(null);
    }

    useEffect(()=>{
      if(DOC){
        emptyQuoteData.propertyInfo.s_YearBuilt = DOC.toString().split(" ")[3];
        dispatch(getEditEmptyQuoteData(emptyQuoteData));
      }
      

    },[DOC])

    // Trigger to Set Primary data
    useEffect(() => {
      if (Object.keys(emptyQuoteData).length > 0) {
        var noOflabel = 'Number of Floors';
        setYearBuiltd(emptyQuoteData.propertyInfo.s_YearBuilt || '')
        setUsage(emptyQuoteData.propertyInfo.s_Usage || '')
        setFoundation(emptyQuoteData.propertyInfo.s_Foundation || '')
        setBldgConstructionType(emptyQuoteData.propertyInfo.s_BldgConstructionType || '')
        setNoOfFloors(emptyQuoteData.propertyInfo.n_NoOfFloors || '')
        setAreaInSqFt(emptyQuoteData.propertyInfo.s_AreaInSqFt || '')
        setTotalSqFootage(emptyQuoteData.propertyInfo.s_TotalSqFootage || '')
        setViewType(emptyQuoteData.viewType);
        setOccupancyType(emptyQuoteData.propertyInfo.s_Occupancy || '');
        setRcvVal(emptyQuoteData.propertyInfo.n_ReplacementCost || '');
        setPerSqft(emptyQuoteData.persqft || 0);

        // MOBILEHOME
        if (emptyQuoteData.viewType === 'MOBILEHOME') {
          if (props.checkDateisValid(emptyQuoteData.propertyInfo.d_DateOfConstruction)) {
            setDOC(new Date(emptyQuoteData.propertyInfo.d_DateOfConstruction));
          }
          var temp = emptyQuoteData.propertyInfo.s_DtOfConstructionSource === 'LOCATEDIN' ? true : false;
          setIsLocated(temp);
          setDescription(emptyQuoteData.propertyInfo.s_BuildingUse);
        }

        if (['UNIT', 'RESIDENTIALBLDG', 'NONRESIDENTIALBLDG'].includes(emptyQuoteData.viewType)) {
          noOflabel = 'Number of Floors';
          var view = emptyQuoteData.viewType;
          var label = 'Unit Square footage';
          var bldgDescription = 'Unit Description';
          if (view === 'UNIT') {
            setFloorOfUnit(emptyQuoteData.propertyInfo.s_UnitLocatedOnFloor);
          }
          if (['RESIDENTIALBLDG', 'NONRESIDENTIALBLDG'].includes(view)) {
            label = 'Building Square footage';
            bldgDescription = 'Building Description'
          }
          setDescLabel(bldgDescription);
          setSflabel(label);
          setCondoAssociation(emptyQuoteData.propertyInfo.s_CondoOwnership);
          setDescription(emptyQuoteData.propertyInfo.s_BuildingUse);
          setNoOfUnit(emptyQuoteData.propertyInfo.s_NoOfUnits);
        }

        setNoOfFloorLabel(noOflabel);
        setIsStateSet(true);
      }
    }, [emptyQuoteData]);

    // set Dropdown
    useEffect(() => {
      if (Object.keys(floodSystemDD).length > 0) {
        var dropdown = floodSystemDD.FLOODBUILDINGUSE20;
        var dd = [];
        if (emptyQuoteData.viewType === 'UNIT') {
          if (isResidentialUnit === 'YES' && condoAssociation === 'NO') {
            dd = dropdown.filter(x => ['COOPERATIVEUNIT20', 'APARTMENTUNIT20'].includes(x.s_AppCodeName))
          }
          if (isResidentialUnit === 'YES' && condoAssociation === 'YES') {
            dd = dropdown.filter(x => ['RESIDENTIALCONDOUNITRB20', 'RESIDENTIALCONDOUNITNRB20'].includes(x.s_AppCodeName))
          }

          if (isResidentialUnit === 'NO') {
            dd = dropdown.filter(x => ['RECREATIONBLDG20', 'COMMERCIAL20', 'HOUSEOFWORSHIP20', 'GOVOWNED20'].includes(x.s_AppCodeName))
          }
        }

        if (viewType === 'RESIDENTIALBLDG') {
          if (condoAssociation === 'NO' && noOfUnit > 0) {
            var array = [];
            var unit = parseInt(noOfUnit);
            if (unit >= 1 && unit <= 4) {
              array.push('DETACHEDGUESTHOUSE20');
            }
            if (unit >= 2) {
              array.push('ENTIREAPARTMENTBLDG20', 'ENTIRECOOPERATIVEBLDG20');
              if (unit <= 4) {
                array.push('MAINHOUSEBLDG20', 'DETACHEDGUESTHOUSE20');
              }
            }
            dd = dropdown.filter(x => array.includes(x.s_AppCodeName));
          }
        }

        if (viewType === 'NONRESIDENTIALBLDG') {
          dd = dropdown.filter(x => ['COMMERCIAL20', 'RECREATIONBLDG20', 'GOVOWNED20', 'AGRICULTURALBLDG20', 'TOOLSTORAGESHED20', 'DETACHEDGARAGE20', 'HOUSEOFWORSHIP20'].includes(x.s_AppCodeName));
        }
        setDescDD(dd);
      }
    }, [noOfUnit, isResidentialUnit, condoAssociation]);

    // Trigger to update primary residency values based on Usages
    useEffect(() => {
      if (isStateSet === true) {
        if (Object.keys(emptyQuoteData).length > 0 && Object.keys(initApiObject)) {
          const reqJson = initApiObject;
          const emptyQuote = emptyQuoteData;

          // Allow Primary Residency Discount If Usage not equal to SEASONAL, RENT OR UNDERCONST
          var temp = 'YES', isTenantorOwner = 'OWNER';
          if (['SEASONAL', 'RENT', 'UNDERCONST'].includes(usage)) {
            temp = 'NO'
          }
          if ((['UNIT'].includes(viewType) && condoAssociation === 'YES') || (['RESIDENTIALBLDG', 'NONRESIDENTIALBLDG'].includes(viewType))) {
            temp = 'NO';
          }

          reqJson.primaryResidence = temp;
          reqJson.type = emptyQuoteData.viewType;

          emptyQuote.propertyInfo.s_InsuredResides = temp

          temp = 'NO';
          if (['RENT', 'TENANT'].includes(usage)) {
            temp = 'YES';
            isTenantorOwner = 'TENANT';
          }
          emptyQuote.propertyInfo.s_isInsuredTenant = isTenantorOwner;
          emptyQuote.propertyInfo.s_RentalProperty = temp;


          if (viewType === 'RESIDENTIALBLDG' && condoAssociation === 'YES') {
            reqJson.occupancyType = 'RESIDENTIALCONDOBUILD20';
            reqJson.buildingDescription = 'ENTIRERESCONDOBLDG20';
            emptyQuote.propertyInfo.s_Occupancy = 'RESIDENTIALCONDOBUILD20';
            emptyQuote.propertyInfo.s_BuildingUse = 'ENTIRERESCONDOBLDG20';
          }
          if (viewType === 'RESIDENTIALBLDG' && condoAssociation === 'NO') {
            reqJson.occupancyType = 'OTHERRESIDENTIAL20';
            emptyQuote.propertyInfo.s_Occupancy = 'OTHERRESIDENTIAL20';
          }

          // To update
          dispatch(updateInitQuoteApi(reqJson));
          dispatch(getEditEmptyQuoteData(emptyQuote));
        }
      }
    }, [usage, emptyQuoteData, condoAssociation]);

    useEffect(() => {
      var temp = isLocated === true ? 'LOCATEDIN' : 'LOCATEDOUTSIDE';
      if (Object.keys(emptyQuoteData).length > 0) {
        const emptyQuote = { ...emptyQuoteData };
        emptyQuote.propertyInfo.s_DtOfConstructionSource = temp;
        dispatch(getEditEmptyQuoteData(emptyQuote));
      }
      var label = 'Date of Permanent Placement';
      if (isLocated === true) {
        label = 'Date of Park/Subdivision was established';
      }
      setLabel(label);
    }, [isLocated]);

    useEffect(() => {
      if (Object.keys(floodSystemDD).length > 0) {
        var dropdown = floodSystemDD.FLOODBUILDINGUSE20;
        if (viewType === 'MOBILEHOME') {
          setDescDD(dropdown.filter(x => ['MAINHOUSEBLDG20', 'DETACHEDGUESTHOUSE20', 'OTHER20'].includes(x.s_AppCodeName)));
        }
        if (viewType === 'HOME') {
          setOccupancyDD(floodSystemDD.FLOODOCCUPANCY20.filter(x => ['SINGLEFAMILY20', 'TWOFOURFAMILY20'].includes(x.s_AppCodeName)));
        }
      }

      // No Of Unit always 1 for occupancy - single family
      if (viewType === 'HOME' && occupancyType !== 'TWOFOURFAMILY20') {
        if (Object.keys(emptyQuoteData).length > 0) {
          const emptyQuote = { ...emptyQuoteData };
          emptyQuote.propertyInfo.s_NoOfUnits = 1;
          dispatch(getEditEmptyQuoteData(emptyQuote));
          setNoOfUnit(1);
        }
      }

      if (occupancyType) {
        const reqJson = initApiObject;
        reqJson.occupancyType = occupancyType;
        dispatch(updateInitQuoteApi(reqJson));
      }
    }, [viewType, occupancyType]);

    const openModal = () => {
      setModal(<Modal size="lg" id='confirmPropInfo' dialogAs={DraggableModalDialog} show={true}>
        <Modal.Header>
          <Modal.Title>{t('Confirm Your Property Information')}</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ textAlign: 'justify' }}>
          <h4>{t("Please verify the information displayed. By confirming the details, we can provide the most accurate premium. Then click 'Next' to continue")}.</h4>
        </Modal.Body>
        <Modal.Footer>
          <Validation currentStep={1} toggle={toggle} closeModal={setModal} saveQuoteData={props.saveQuoteData}/>
        </Modal.Footer>
      </Modal >)
    }

    // Draggable Modal
    const DraggableModalDialog = (props) => {
      return (
        <Draggable handle={'#confirmPropInfo'}>
          <ModalDialog {...props} />
        </Draggable>
      )
    }

    const handleStateChange = (e, state) => {
      let temp = 'NO';
      const { value, attributes, checked } = e.target;

      if (checked && ['s_IsResidentialUnit', 's_CondoOwnership'].includes(state)) {
        temp = attributes.checkedvalue.value;
      }

      switch (state) {
        case 's_IsResidentialUnit':
          setIsResidentialUnit(temp);
          break;
        case 's_CondoOwnership':
          setCondoAssociation(temp);
          break;
        case 's_UnitLocatedOnFloor':
          setFloorOfUnit(value);
          break;
        case 's_BuildingUse':
          setDescription(value);
          break;
        case 's_NoOfUnits':
          setNoOfUnit(value);
          break;
        default:
          break;
      }

      if (['s_CondoOwnership'].includes(state)) {
        updateUnitDescription();
        props.handleSwitchChange(e);
      } else {
        if (!['s_IsResidentialUnit'].includes(state)) {
          props.handleChange(e);
        }
      }
    }

    // 
    const updateUnitDescription = () => {
      const emptyQuote = { ...emptyQuoteData };
      emptyQuote.propertyInfo.s_BuildingUse = '';
      setDescription('');
      dispatch(getEditEmptyQuoteData(emptyQuote));
    }

    useEffect(() => {
      if (viewType === 'UNIT') {
        const emptyQuote = { ...emptyQuoteData };
        const reqJson = {...initApiObject};
        var occupancy = 'RESIDENTIALUNIT20', primaryResidence = 'YES';
        if (isResidentialUnit === 'NO') {
          occupancy = 'NONRESIDENTIALUNIT20'; primaryResidence = 'NO';
        }
        emptyQuote.propertyInfo.s_Occupancy = occupancy;
        emptyQuote.propertyInfo.s_InsuredResides = primaryResidence
        reqJson.occupancyType = occupancy;
        reqJson.primaryResidence = primaryResidence;
        setOccupancyType(occupancy);
        dispatch(getEditEmptyQuoteData(emptyQuote));
        dispatch(updateInitQuoteApi(reqJson));
      }
    }, [isResidentialUnit]);

    // Calculate Estimated RCV Form (sqft * perSqft) 
    const handleTotalSqFootageChange = (totalSqFootage) => { 
      if(totalSqFootage && persqft){
        var rcv = (totalSqFootage * persqft);
        setRcvVal(rcv);
        const emptyQuote = { ...emptyQuoteData };
        emptyQuote.propertyInfo.n_ReplacementCost = rcv;
        dispatch(getEditEmptyQuoteData(emptyQuote));
        handleUpdateArray('propertyInfo', 'n_ReplacementCost', rcv);
      }
    };

  const handleTabPressEst = (event) => {
    if (event.key === "Tab") {
      event.preventDefault();
      if (emptyQuoteData.viewType === "HOME") nextbuttonRef.current.focus();
      if (emptyQuoteData.viewType === "MOBILEHOME") locatedRef.current.focus();
      if (emptyQuoteData.viewType === "UNIT") nextbuttonRef.current.focus();
      if (emptyQuoteData.viewType === "RESIDENTIALBLDG")
        nextbuttonRef.current.focus();
      if (emptyQuoteData.viewType === "NONRESIDENTIALBLDG")
        nextbuttonRef.current.focus();
    }
  };
  const handleTabPressDate = (e) => {
    e.preventDefault();
    nextbuttonRef.current.focus();
  };

  const handleEnterPress = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      openModal();
    }
  };
  const handleToggleEnter = (e) => {
    if (e.key === "Enter") {
      if (e.target.name === "s_DtOfConstructionSource")
        setIsLocated(!isLocated);
      if (e.target.name === "s_IsResidentialUnit")
        setIsResidentialUnit(e.target.checked ? "NO" : "YES");
      if (e.target.name === "s_CondoOwnership")
        setCondoAssociation(e.target.checked ? "NO" : "YES");
    }
  };
  const handleShiftTabPress=(e)=>{
    if (e.shiftKey && e.key === 'Tab') {
      e.preventDefault();
      switch (emptyQuoteData.viewType){
        case "MOBILEHOME":
          if(e.target.title === "nextButton") {
            const element = document.getElementById('dateOfConstruction');
            element.focus();
          };
          if (e.target.id === 'dateOfConstruction')locatedRef.current.focus();
          if(e.target.name === "n_ReplacementCost") foundationRef.current.focus();
          break;
        default:
          if(e.target.title === "nextButton") estreplacementcostRef.current.focus();
          if(e.target.name === "n_ReplacementCost") foundationRef.current.focus();
          break;
      }
    }
  };
  const handleYearBuiltChange = (e) => {
    const re = /^[0-9\b]+$/;
    if (e.currentTarget.value === '' || re.test(e.currentTarget.value)) {
      props.handleChange(e);
      setYearBuiltd(e.currentTarget.value);
    }
  };
  
  const handleNoOfFloors = (e) => {
    const re = /^[0-9\b]+$/;
    if (e.target.value === '' || re.test(e.target.value)) {
       props.handleChange(e);
       setNoOfFloors(e.currentTarget.value);
    }
  };

  const handleSquareFootage = (e) => {
    const re = /^[0-9\b]+$/;
    if (e.target.value === '' || re.test(e.target.value)) {
      props.handleChange(e);
      setTotalSqFootage(e.currentTarget.value);
    }
  };
    return (
      <div>
      {pageMode ? <ModeRouter /> : 
      (
      <section className="property-details-section">
        {
          Object.keys(emptyQuoteData).length === 0 && <Navigate to={'/flood/instantquote/step/address'} />
        }
        {/* Form */}
        <Row className="justify-content-center">
          <Col xs="12" sm="12" md="10" lg="8" xl="8" className="p-form">
            <h1 style={{ paddingLeft: "42px" }}>
              {t('Please Check Property Details')}
            </h1>
            <Row className="justify-content-start">
              <Col xs="12">
                <Form validated={validated} className="confirm-address-form">
                  <Row>
                    <Col xs="12" sm="6" md="6" lg="4" xl="4">
                      <Form.Group
                        className="confirm-address-field"
                        controlId="formBasicEmail"
                      >
                        <Form.Label className="confirm-address-label">
                          {t(sflabel)}<AiOutlineInfoCircle size={25} style={{paddingLeft : '5%'}} data-tip data-for="square-footage" tabIndex="-1"/>
                        </Form.Label>
                        <ReactTooltip id="square-footage" place="top" effect="solid">
                          {t('Provide the total finished living area')}. <br /> {t('An attached garage converted to a living space')} <br /> {t('or an attic converted to living space')} <br /> {t('should be included in the square footage calculation')}.
                        </ReactTooltip>
                        <Form.Control
                          className="confirm-address-input"
                          type="text"
                          placeholder=""
                          name='s_TotalSqFootage'
                          title={t(sflabel)}
                          parentobject="propertyInfo"
                          value={totalSqFootage}
                          defaultValue={''}
                          onChange={(e) => {
                            handleSquareFootage(e)
                          }}
                          required
                          autoFocus={true}
                          tabIndex="0"
                        />
                        <Form.Control.Feedback type="invalid">
                          {t('Please Add Square footage error')}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    {
                      ['HOME', 'UNIT', 'RESIDENTIALBLDG', 'NONRESIDENTIALBLDG'].includes(viewType) && (
                        <Col xs="12" sm="6" md="6" lg="4" xl="4">
                          <Form.Group
                            controlId="formBasicPassword"
                            className="confirm-address-field"
                          >
                            <Form.Label className="confirm-address-label">
                              {t('Year built')}
                            </Form.Label>
                            <Form.Control
                              className="confirm-address-input"
                              type="text"
                              placeholder=""
                              name='s_YearBuilt'
                              title={t('Year built')}
                              parentobject="propertyInfo"
                              value={yearBuilt}
                              defaultValue={''}
                              onChange={(e) => {
                                handleYearBuiltChange(e)
                            }}
                              required
                              tabIndex="0"
                            />
                            <Form.Control.Feedback type="invalid">
                              {t('Please Add Year Build Error')}
                            </Form.Control.Feedback>
                          </Form.Group>
                        </Col>
                      )
                    }
                    {
                      viewType === 'HOME' && (
                        <Col xs="12" sm="6" md="6" lg="4" xl="4">
                          <Form.Group className="confirm-address-field">
                            <Form.Label className="confirm-address-label">
                              {t('Occupancy type')}<AiOutlineQuestionCircle size={25} style={{ paddingLeft: '5%' }} data-tip data-for="occupation-type" tabIndex="-1" />
                            </Form.Label>
                            <ReactTooltip id="occupation-type" place="left" effect="solid">
                              <Row>
                                <Col>{t('Single Family')}</Col>
                                <Col> <img src={Slab} alt="image" width="180px" height="90px" /> </Col>
                                <Col>{t('Two to Four Family Building')}</Col>
                                <Col> <img src={twoToFour} alt="image" width="180px" height="90px" /></Col>
                              </Row>
                            </ReactTooltip>
                            <Form.Select
                              className="confirm-address-input"
                              name='s_Occupancy'
                              title={t('Occupancy type')}
                              parentobject="propertyInfo"
                              value={occupancyType}
                              onChange={(e) => {
                                props.handleChange(e);
                                setOccupancyType(e.currentTarget.value);
                              }}
                              required
                              tabIndex="0"
                            >
                              <option value={''}>{t('Select')}</option>
                              {
                                Object.keys(occupancyDD).length > 0 && occupancyDD.map((v, i) => {
                                  return <option key={i} value={v.s_AppCodeName}>{t(v.s_AppCodeNameForDisplay)}</option>
                                })
                              }
                            </Form.Select>
                            <Form.Control.Feedback type="invalid">
                              {t('Please Select Occupancy Type')}
                            </Form.Control.Feedback>
                          </Form.Group>
                        </Col>
                      )
                    }
                    <Col xs="12" sm="6" md="6" lg="4" xl="4">
                      <Form.Group className="confirm-address-field">
                        <Form.Label className="confirm-address-label">
                          {t('Construction type')}<AiOutlineInfoCircle size={25} style={{paddingLeft : '5%'}} data-tip data-for="cons-type" tabIndex="-1"/>
                        </Form.Label>
                        <ReactTooltip id="cons-type" place="top" effect="solid">
                          {t('Construction Type based on the construction')} <br /> {t('used for the wall on the building’s first floor')}.
                        </ReactTooltip>
                        <Form.Select
                          className="confirm-address-input"
                          name='s_BldgConstructionType'
                          title={t('Construction type')}
                          parentobject="propertyInfo"
                          value={bldgConstructionType}
                          onChange={(e) => {
                            props.handleChange(e);
                            setBldgConstructionType(e.currentTarget.value);
                          }}
                          required
                          tabIndex="0"
                        >
                          <option value={''}>{t('Select')}</option>
                          {
                            Object.keys(floodSystemDD).length > 0 && floodSystemDD.FLOODCONSTRUCTIONTYPE20.map((v, i) => {
                              return <option key={i} value={v.s_AppCodeName}>{t(v.s_AppCodeNameForDisplay)}</option>
                            })
                          }
                        </Form.Select>
                        <Form.Control.Feedback type="invalid">
                          {t('Please Select Construction Type Error')}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <Col xs="12" sm="6" md="6" lg="4" xl="4">
                      <Form.Group
                        className="confirm-address-field"
                        controlId="formBasicPassword"
                      >
                        <Form.Label className="confirm-address-label">
                          {t(noOfFloorLabel)}<AiOutlineInfoCircle size={25} style={{paddingLeft : '5%'}} data-tip data-for="noOfStory" tabIndex="-1"/>
                        </Form.Label>
                        <ReactTooltip id="noOfStory" place="top" effect="solid">
                            {t('Number of floors above the ground, excluding')} <br /> {t('enclosures or basements (finished or unfinished)')}, <br /> {t('crawlspaces (on grade or subgrade)')} <br /> {t('and attics (if not used for living space)')}.
                        </ReactTooltip>
                        <Form.Control
                          className="confirm-address-input"
                          type="text"
                          placeholder=""
                          name='n_NoOfFloors'
                          title={t('Number of Floors')}
                          parentobject="propertyInfo"
                          value={noOfFloors}
                          defaultValue={''}
                          min={0}
                          onChange={(e) => {
                            handleNoOfFloors(e)
                          }}
                          required
                          tabIndex="0"
                        />
                        <Form.Control.Feedback type="invalid">
                          {t("Please Add " + noOfFloorLabel + " Error")}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    {
                      !['RESIDENTIALBLDG', 'NONRESIDENTIALBLDG'].includes(viewType) && (
                        <Col xs="12" sm="6" md="6" lg="4" xl="4">
                          <Form.Group
                            className="confirm-address-field"
                            controlId="formBasicEmail"
                          >
                            <Form.Label className="confirm-address-label">
                              {t('Usage')}
                            </Form.Label>
                            <Form.Select
                              className="confirm-address-input"
                              name="s_Usage"
                              title={t('Usage')}
                              parentobject="propertyInfo"
                              value={usage}
                              onChange={(e) => {
                                props.handleChange(e);
                                setUsage(e.currentTarget.value);
                              }}
                              required
                              tabIndex="0"
                            >
                              <option value={''}>{t('Select')}</option>
                              {
                                Object.keys(floodSystemDD).length > 0 && floodSystemDD.FLDUSAGETYPE.map((v, i) => {
                                  return <option key={i} value={v.s_AppCodeName}>{t(v.s_AppCodeNameForDisplay)}</option>
                                })
                              }
                            </Form.Select>
                            <Form.Control.Feedback type="invalid">
                              {t('Please Select Usage Error')}
                            </Form.Control.Feedback>
                          </Form.Group>
                        </Col>
                      )
                    }
                    {
                      ['UNIT', 'RESIDENTIALBLDG', 'NONRESIDENTIALBLDG'].includes(viewType) && (
                        <>
                          {
                            viewType === 'UNIT' && (
                              <Col xs="12" sm="6" md="6" lg="4" xl="4">
                                <Form.Group
                                  className="confirm-address-field"
                                  controlId="formBasicPassword"
                                >
                                  <Form.Label className="confirm-address-label">
                                    {t('Residential Unit')}
                                  </Form.Label>
                                  <div className="flood-construction-row">
                                    <div className="flood-construction-row-actions">
                                      <span>{t('No')}</span>
                                      <div className="custom-main-swtich">
                                        <Form.Check
                                          type="switch"
                                          id="custom-switch"
                                          className="flood-construction-row-switch"
                                          parentobject="propertyInfo"
                                          name="s_IsResidentialUnit"
                                          checked={isResidentialUnit === 'YES' ? true : false}
                                          onChange={(e) => handleStateChange(e, 's_IsResidentialUnit')}
                                          checkedvalue='YES'
                                          uncheckedvalue='NO'
                                          tabIndex="0"
                                          onKeyDown={handleToggleEnter}
                                        />
                                      </div>
                                      <span>{t('Yes')}</span>
                                    </div>
                                  </div>
                                </Form.Group>
                              </Col>
                            )
                          }
                          {
                            ((viewType === 'UNIT' && isResidentialUnit === 'YES') || viewType === 'RESIDENTIALBLDG') && (
                              <Col xs="12" sm="6" md="6" lg="4" xl="4">
                                <Form.Group
                                  className="confirm-address-field"
                                  controlId="formBasicPassword"
                                >
                                  <Form.Label className="confirm-address-label">
                                    {t('Condominium Association')}
                                  </Form.Label>
                                  <div className="flood-construction-row">
                                    <div className="flood-construction-row-actions">
                                      <span>{t('No')}</span>
                                      <div className="custom-main-swtich">
                                        <Form.Check
                                          type="switch"
                                          id="custom-switch"
                                          className="flood-construction-row-switch"
                                          parentobject="propertyInfo"
                                          name="s_CondoOwnership"
                                          checked={condoAssociation === 'YES' ? true : false}
                                          onChange={(e) => handleStateChange(e, 's_CondoOwnership')}
                                          checkedvalue='YES'
                                          uncheckedvalue='NO'
                                          tabIndex="0"
                                          onKeyDown={handleToggleEnter}
                                        />
                                      </div>
                                      <span>{t('Yes')}</span>
                                    </div>
                                  </div>
                                </Form.Group>
                              </Col>
                            )
                          }
                          {
                            <>
                              {
                                (viewType === 'UNIT' && noOfFloors > 1) && (
                                  <Col xs="12" sm="6" md="6" lg="4" xl="4">
                                    <Form.Group
                                      className="confirm-address-field"
                                      controlId="formBasicPassword"
                                    >
                                      <Form.Label className="confirm-address-label">
                                        {t('Floor of Unit')}
                                      </Form.Label>
                                      <Form.Control
                                        className="myform-input"
                                        type="number"
                                        placeholder="0000"
                                        id="flood-constructon-input"
                                        name="s_UnitLocatedOnFloor"
                                        title={t('Floor of Unit')}
                                        parentobject="propertyInfo"
                                        value={floorOfUnit}
                                        min={0}
                                        onChange={(e) => handleStateChange(e, 's_UnitLocatedOnFloor')}
                                        required
                                        tabIndex="0"
                                      />
                                      <Form.Control.Feedback type="invalid">
                                        {t('Please Enter Floor of Unit')}
                                      </Form.Control.Feedback>
                                    </Form.Group>
                                  </Col>
                                )
                              }
                            </>
                          }
                        </>
                      )
                    }
                    {
                      (['UNIT', 'RESIDENTIALBLDG', 'NONRESIDENTIALBLDG'].includes(viewType) || (viewType === 'HOME' && occupancyType === 'TWOFOURFAMILY20')) && (
                        <Col xs="12" sm="12" md="6" lg="4" xl="4">
                          <Form.Group
                            className="confirm-address-field"
                            controlId="formBasicEmail"
                          >
                            <Form.Label className="confirm-address-label">
                              {t('Number of Units')}:
                            </Form.Label>
                            <Form.Control
                              className="myform-input"
                              type="number"
                              placeholder="0"
                              name="s_NoOfUnits"
                              title={t('in feet')}
                              parentobject="propertyInfo"
                              value={noOfUnit}
                              min={0}
                              onChange={(e) => handleStateChange(e, 's_NoOfUnits')}
                              required
                              tabIndex="0"
                            />
                            <Form.Control.Feedback type="invalid">
                              {t('Please Enter Number of Units')}
                            </Form.Control.Feedback>
                          </Form.Group>
                        </Col>
                      )
                    }
                    {
                      ((['MOBILEHOME', 'UNIT', 'NONRESIDENTIALBLDG'].includes(viewType)) || (viewType === 'RESIDENTIALBLDG' && condoAssociation === 'NO' && noOfUnit > 0)) && (
                        <Col xs="12" sm="6" md="6" lg="4" xl="4">
                          <Form.Group className="confirm-address-field">
                            <Form.Label className="confirm-address-label">
                              {t(descLabel)}
                            </Form.Label>
                            <Form.Select
                              className="confirm-address-input"
                              name='s_BuildingUse'
                              title={t(descLabel)}
                              parentobject="propertyInfo"
                              value={description}
                              onChange={(e) => handleStateChange(e, 's_BuildingUse')}
                              required
                              tabIndex="0"
                            >
                              <option value={''}>{t('Select')}</option>
                              {
                                descDD.length > 0 && descDD.map((v, i) => {
                                  return <option key={i} value={v.s_AppCodeName}>{t(v.s_AppCodeNameForDisplay)}</option>
                                })
                              }
                            </Form.Select>
                            <Form.Control.Feedback type="invalid">
                              {t('Please Select Buildind Description')}
                            </Form.Control.Feedback>
                          </Form.Group>
                        </Col>
                      )
                    }
                    <Col xs="12" sm="6" md="6" lg="4" xl="4">
                      <Form.Group className="confirm-address-field">
                        <Form.Label className="confirm-address-label">
                        {t('Foundation type')}<AiOutlineQuestionCircle size={25} style={{paddingLeft : '5%'}} data-tip data-for="foundation-type" tabIndex="-1"/>
                        </Form.Label>
                        <ReactTooltip id="foundation-type" place="top" effect="solid">
                          <Row>
                              <Col>{t('Slab')}</Col>
                              <Col> <img src={Slab} alt="image" width="180px" height="90px" /> </Col>
                              <Col>{t('Crawlspace')}</Col>
                              <Col> <img src={Crowlspace} alt="image" width="180px" height="90px" /></Col>
                          </Row>
                          <br />
                          <Row>
                              <Col>{t('Basement')}</Col>
                              <Col> <img src={Basement} alt="image" width="180px" height="90px" /> </Col>
                              <Col>{t('Elevated without enclosure on piers, posts & piles')}</Col>
                              <Col> <img src={elevatedWithoutEnclosure} alt="image" width="180px" height="90px" /></Col>
                              
                          </Row>
                          <br />
                          <Row>
                              <Col>{t('Elevated with enclosure on piers, posts & piles')}</Col>
                              <Col> <img src={elevatedWithEnclosure} alt="image" width="180px" height="90px" /> </Col>
                              <Col>{t('Elevated with enclosure not on piers, posts & piles')}</Col>
                              <Col> <img src={walkout} alt="image" width="180px" height="90px" /></Col>
                          </Row>
                        </ReactTooltip>
                        <Form.Select
                          className="confirm-address-input"
                          name="s_Foundation"
                          title={t('Foundation type')}
                          parentobject="propertyInfo"
                          value={foundation}
                          onChange={(e) => {
                            props.handleChange(e);
                            setFoundation(e.currentTarget.value);
                          }}
                          required
                          ref={foundationRef}
                        >
                          <option value={''}>{t('Select')}</option>
                          {
                            Object.keys(floodSystemDD).length > 0 && floodSystemDD.FLOODFOUNDATION20.map((v, i) => {
                              return <option key={i} value={v.s_AppCodeName}>{t(v.s_AppCodeNameForDisplay)}</option>
                            })
                          }
                        </Form.Select>
                        <Form.Control.Feedback type="invalid">
                          {t('Please Select Foundation Type Error')}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <Col xs="12" sm="6" md="6" lg="4" xl="4">
                      <Form.Group
                        className="confirm-address-field"
                      >
                        <Form.Label className="confirm-address-label">
                          {t('Estimated Replacement Cost')}
                        </Form.Label>
                        <Form.Control
                          className="confirm-address-input"
                          type="number"
                          placeholder=""
                          name="n_ReplacementCost"
                          title={t('Estimated Replacement Cost')}
                          parentobject="propertyInfo"
                          value={rcvVal}
                          onChange={(e) => {
                            props.handleChange(e);
                            setRcvVal(e.currentTarget.value);
                          }}
                          required
                          onKeyDown={(e)=>{handleTabPressEst(e) ; handleShiftTabPress(e)}}
                          tabIndex="0"
                          ref={estreplacementcostRef}
                        />
                        <Form.Control.Feedback type="invalid">
                          {t('Please Enter Estimated Replacement Cost')}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    {
                      ['ELEWATEDONCRAWLSPACE20', 'ELEVATEDWITHENCLOSURE20', 'ELEVATEDWITHENCLOSURENOTPPP20'].includes(foundation) && (
                        <Col xs="12" sm="6" md="6" lg="4" xl="4">
                          <Form.Group
                            className="confirm-address-field"
                          >
                            <Form.Label className="confirm-address-label">
                              {t('Size of Enclosure')}
                            </Form.Label>
                            <Form.Control
                              className="confirm-address-input"
                              type="text"
                              placeholder=""
                              name="s_AreaInSqFt"
                              title={t('Size of Enclosure')}
                              parentobject="propertyInfo"
                              value={areaInSqFt}
                              defaultValue={''}
                              onChange={(e) => {
                                props.handleChange(e);
                                setAreaInSqFt(e.currentTarget.value);
                              }}
                              required
                            />
                            <Form.Control.Feedback type="invalid">
                              {t('Please Enter Size of Enclosure')}
                            </Form.Control.Feedback>
                          </Form.Group>
                        </Col>
                      )
                    }
                    {
                      viewType === 'MOBILEHOME' && (
                        <>
                          <Col xs="12" sm="6" md="6" lg="4" xl="4">
                            <Form.Group
                              className="confirm-address-field"
                              controlId="formBasicPassword"
                            >
                              <Form.Label className="confirm-address-label">
                                {t('Located in Park/Subdivision')}
                              </Form.Label>
                              <div className="flood-construction-row">
                                <div className="flood-construction-row-actions">
                                  <span>{t('No')}</span>
                                  <div className="custom-main-swtich">
                                    <Form.Check
                                      type="switch"
                                      id="custom-switch"
                                      className="flood-construction-row-switch"
                                      parentobject="propertyInfo"
                                      name="s_DtOfConstructionSource"
                                      checked={isLocated}
                                      onChange={() => setIsLocated(!isLocated)}
                                      ref={locatedRef}
                                      onKeyDown={handleToggleEnter}
                                      tabIndex="0"
                                    />
                                  </div>
                                  <span>{t('Yes')}</span>
                                </div>
                              </div>
                            </Form.Group>
                          </Col>
                          <Col xs="12" sm="6" md="6" lg="4" xl="4">
                            <Form.Group
                              controlId="formBasicPassword"
                              className="confirm-address-field"
                            >
                              <Form.Label className="confirm-address-label">
                                {t(label)}
                              </Form.Label>
                              <DatePicker
                                className="custom-info-date-picker"
                                placeholderText="MM/DD/YYYY"
                                selected={DOC}
                                // minDate={DOC}
                                onChange={(date) => {
                                  props.handleDateChange(date, 'd_DateOfConstruction', 'propertyInfo');
                                  setDOC(date);
                                }}
                                onKeyDown={(e)=>{
                                  handleTabPressDate(e);
                                  handleShiftTabPress(e);
                                }}
                                tabIndex="0"
                                id="dateOfConstruction"
                              />
                            </Form.Group>
                          </Col>
                        </>
                      )
                    }
                    <Col xs="12">
                      <div className="form-map">
                        <GoogleMap />
                      </div>
                    </Col>
                    <Col xs="12" className="form-btns">
                      <div className="flood-bts-div">
                       {
                          tenant !== 'pti' ?
                         (
                           <>
                            <Button
                              id="primary-grey-btn-flood"
                              variant="outline-dark"
                              disabled={true}
                             >
                             {t('Previous')}

                            </Button>
                            {" "}
                           </>
                         ):null
                        }
                        <Button
                          id="primary-colored-btn"
                          variant="primary"
                          onClick={() => openModal()}
                          onKeyDown={(e) => {handleEnterPress(e); handleShiftTabPress(e)}}
                          ref={nextbuttonRef}
                          tabIndex="1"
                          title="nextButton"
                        >
                          {t('Next')}
                        </Button>{" "}
                      </div>
                      {model}
                    </Col>
                  </Row>
                </Form>
              </Col>
            </Row>
          </Col>
        </Row >
      </section >
      )}
      </div>
    );
  };

  export default FloodPropertyDetail;
