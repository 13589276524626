import React from "react";
import PropTypes from "prop-types";
import arrowSquareLeft from "../../../../assets/arrow-square-left.svg";
import { CreateQuoteContext, useMyContext } from "../CreateQuote";
import "./StepperDesign.css";
import { useTranslation } from "react-i18next";
import { Box, Flex, HStack, Image, Spacer, Text } from "@chakra-ui/react";
import { Step, Steps } from "chakra-ui-steps";
import { Currency } from "../../../common/Currency";

const StepperDesign = ({ step }) => {
  const {
    locationQuoteObject,
    applicantDetails,
    quoteNumber,
    premiumFeesData,
  } = useMyContext(CreateQuoteContext);

  const { t } = useTranslation()
  const steps = [
    t("Policy Type"),
    t("Property Details"),
    t("Applicant Information"),
    t("Construction Details"),
    t("Financial Details"),
    t("Additional Information"),
    t("Premium"),
  ];

  const premiumFees = premiumFeesData.premium + premiumFeesData.totalFee || 0;
  const roundPremiumFees = premiumFees.toFixed(2);
  return (
    <Box p={2}>
      <Flex fontSize={{ base: "12px", md: "16px", lg: "20px" }} mt={2}>
        <HStack pl={7}>
          <Flex alignItems={"center"} gap={2}>
            <Box pb={"1.0rem"}>
              <Image src={arrowSquareLeft} width={22} height={22} />
            </Box>
            <Text fontWeight="var(--chakra-fontWeights-medium)">
              {t("Quote No: ")}
            </Text>
          </Flex>
          <Text
            color="var(--chakra-colors-primary-500)"
            fontWeight="var(--chakra-fontWeights-medium)"
          >
            {!quoteNumber ? "FPCXXXXXXXXXX" : quoteNumber}
          </Text>
        </HStack>
        <Spacer />
        <Flex flexDir={"column"} gap={0} pt={2} pr={2}>
          <Text
            my={0}
            textAlign={"end"}
            fontWeight="var(--chakra-fontWeights-medium)"
            color={"var(--chakra-colors-primary-500)"}
          >
            {applicantDetails?.firstName || ""}{" "}
            {applicantDetails?.lastName || "N/A"}
          </Text>
          <Text my={0} fontWeight="var(--chakra-fontWeights-medium)">
            {locationQuoteObject.addressLine}
          </Text>
        </Flex>
        <Box pl={2} pt={1} pb={2} pr={5} mb={4}>
          <Flex
            px={4}
            py={1}
            justifyContent={"center"}
            alignItems={"center"}
            color={"white"}
            flexDir={"column"}
            bg={"var(--chakra-colors-primary-500)"}
            borderRadius={"xl"}
          >
            <Text my={"0.15rem"} fontSize={"xl"} textAlign={"center"}>
              <Currency
              amount={
                roundPremiumFees 
              }
              />
            </Text>
            <Text my={"0.1rem"} fontSize={"xs"} textAlign={"center"}>
              ESTIMATED ANNUAL QUOTE
            </Text>
          </Flex>
        </Box>
      </Flex>
      <Flex flexDir={"column"} mt={4}>
        <Steps pl={2}
          responsive={true}
          alignItems="center"
          variant={"circles"}
          colorScheme="blue"
          activeStep={step - 1}
          className="arrow-steps clearfix"
          sx={{
            fontSize: ["14px", "16px", "18px"],
            "& .arrow-steps": {
              display: "flex",
            },
          }}>
          {
            steps.map((label, index) => (
              <Step label={label} />
            ))
          }
        </Steps>
      </Flex>
    </Box>
  );
};

StepperDesign.propTypes = {
  step: PropTypes.number.isRequired,
};

export default StepperDesign;