import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  Input,
  Select,
  Spinner,
  Switch,
  Text,
  Tooltip,
} from "@chakra-ui/react";

import { useTranslation } from "react-i18next";
import { useStarDataContext } from "../../StarDataContext";
import MaskedFormControl from "react-bootstrap-maskedinput";
import { useEffect, useState } from "react";
import { CgAddR } from "react-icons/cg";
import { AiOutlineDelete } from "react-icons/ai";
import states from "../../../common/States";
import { useAutoZipSelection } from "../../utils/useAutoZipDetection";
import LocationSelectionModal from "../../../common/LocationSelectionModal";
import { FcInfo } from "react-icons/fc";
import NewPurchaseLocation from "./NewPurchaseLocation";
import { TiInfoLarge } from "react-icons/ti";
const ppcDescription = `
  The Public Protection Classification (PPC) program is a community fire protection scoring system based on a Fire Suppression Rating Schedule (FSRS) used by the Insurance Services Office, also known as ISO Mitigation.
  Communities get a PPC score from 1 to 10.
  1 means the community has superior property fire protection.
  10 means the community's fire protection efforts don't meet ISO's minimum criteria.
  Most home and business insurers use PPC scores to determine insurance premiums. Businesses and individuals in a community with a good PPC score typically pay less for fire insurance.
`;

function isValidEmail(email) {
  if (typeof email !== "string") {
    return "";
  }
  const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailPattern.test(email);
}

function isValidPhoneNumber(phoneNumber) {
  return (
    phoneNumber &&
    phoneNumber !== "" &&
    phoneNumber !== "+1 " &&
    typeof phoneNumber === "string"
  );
}

export default function PropertyDetail() {
  const {
    underWriting,
    setUnderWriting,
    baseDD,
    validationError,
    confirmation,
    setConfirmation,
    primaryMailingAddress,
    setPrimaryMailingAddress,
  } = useStarDataContext();

  const [foundationData] = useState(baseDD?.STARFOUNDATIONTYPE ?? []);
  const [claddingData] = useState(baseDD?.STARCLADDINGTYPE ?? []);
  const [dwellingData] = useState(baseDD?.STARDWELLINGSTORIES ?? []);
  const [constructionData] = useState(baseDD?.STARCONSTRUCTIONTYPE ?? []);
  const [customerType] = useState(baseDD?.STARCUSTOMERTYPE ?? []);
  const [usageData] = useState(baseDD?.STAROCCUPANCYTYPE ?? []);
  const [ppcData] = useState(baseDD?.PROTECTIONCLS ?? []);
  const [zipData, setZipData] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const { t } = useTranslation();

  const { selectedLocation, showModal, isLoading } = useAutoZipSelection(
    primaryMailingAddress?.zipcode,
    !primaryMailingAddress.sameAsPropertyAddress
  );

  useEffect(() => {
    if (typeof selectedLocation === "object" && selectedLocation !== null) {
      setZipData(selectedLocation);
    }
    if (showModal) {
      setOpenModal(true);
    }
  }, [selectedLocation, showModal]);

  useEffect(() => {
    if (
      zipData &&
      Object.keys(zipData).length > 0 &&
      !primaryMailingAddress.sameAsPropertyAddress
    ) {
      setPrimaryMailingAddress({
        ...primaryMailingAddress,
        city: zipData?.s_CityName || "",
        state: zipData?.s_StateCode || "",
        cityId: zipData.city_id ?? 0,
        stateId: zipData.state_id ?? 0,
        countryId: zipData.n_CountryId_FK ?? 1,
        countyId: zipData.county_id ?? 0,
        county: zipData.s_CountyName ?? "",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [zipData]);

  return (
    <>
      <LocationSelectionModal
        isOpen={openModal}
        selectedLocation={selectedLocation}
        setOpenModal={setOpenModal}
        setZipData={setZipData}
      />
      <Box border="1px solid #E6E9ED" borderRadius="12px" mb={6}>
        <Box
          background="var(--chakra-colors-primary-50)"
          p={"0.1rem"}
          borderRadius={"xl"}
        >
          <Text
            mt={3}
            pl="1.5rem"
            fontWeight="var(--chakra-fontWeights-semibold)"
            color="black"
            fontSize="1.5rem"
          >
            {t("property_details")}
          </Text>
        </Box>
        <Box w="100%" p={4}>
          <Flex>
            <Grid
              w="100%"
              templateColumns="repeat(4, 1fr)"
              gap={4}
              pt={2}
              pb={2}
            >
              <FormControl isRequired>
                <FormLabel fontWeight={500}> {t("Customer Type")}: </FormLabel>
                <Select
                  className="inputBorder"
                  size="lg"
                  value={underWriting?.propertyDetails?.customerType}
                  onChange={(e) => {
                    setUnderWriting({
                      ...underWriting,
                      propertyDetails: {
                        ...underWriting?.propertyDetails,
                        customerType: e.target.value,
                      },
                    });
                  }}
                >
                  <option value=""></option>
                  {customerType
                    .filter((item) =>
                      [
                        "individual",
                        "organization",
                        "corporation",
                        "llc",
                        "other",
                      ].includes(item.s_AppCodeName)
                    )
                    .map((item, index) => (
                      <option key={index} value={item.s_AppCodeName}>
                        {item.s_AppCodeNameForDisplay.toUpperCase()}
                      </option>
                    ))}
                </Select>
                {validationError.underWritingPage &&
                  !underWriting?.propertyDetails?.customerType && (
                    <Text color={"red"}>
                      {t("Customer type cannot be empty")}.
                    </Text>
                  )}
              </FormControl>
            </Grid>
          </Flex>

          <Grid w="100%" templateColumns="repeat(4, 1fr)" gap={4} pt={2} pb={2}>
            {underWriting?.propertyDetails?.customerType === "individual" && (
              <>
                <GridItem>
                  <FormControl isRequired>
                    <FormLabel fontWeight={500} color="black">
                      {t("primary_application_first_name")}:{" "}
                    </FormLabel>
                    <Input
                      className="inputBorder"
                      size="lg"
                      name="first-name"
                      type="text"
                      focusBorderColor="secondary.500"
                      // _placeholder={{ opacity: 1, color: "neutral.500" }}
                      value={underWriting?.propertyDetails?.firstName || ""}
                      onChange={(e) => {
                        setUnderWriting({
                          ...underWriting,
                          propertyDetails: {
                            ...underWriting?.propertyDetails,
                            firstName: e.target.value,
                          },
                        });
                      }}
                    />
                    {validationError.underWritingPage &&
                      !underWriting?.propertyDetails?.firstName && (
                        <Text color={"red"}>
                          {t("First Name is required")}.
                        </Text>
                      )}
                  </FormControl>
                </GridItem>
                <GridItem>
                  <FormControl isRequired>
                    <FormLabel fontWeight={500} color="black">
                      {t("primary_application_last_name")}:{" "}
                    </FormLabel>
                    <Input
                      size="lg"
                      className="inputBorder"
                      name="last-name"
                      type="text"
                      // placeholder={t("Doe")}
                      focusBorderColor="secondary.500"
                      // _placeholder={{ opacity: 1, color: "neutral.500" }}
                      value={underWriting?.propertyDetails?.lastName || ""}
                      onChange={(e) => {
                        setUnderWriting({
                          ...underWriting,
                          propertyDetails: {
                            ...underWriting?.propertyDetails,
                            lastName: e.target.value,
                          },
                        });
                      }}
                    />
                    {validationError.underWritingPage &&
                      !underWriting?.propertyDetails?.lastName && (
                        <Text color={"red"}>{t("Last name is required")}.</Text>
                      )}
                  </FormControl>
                </GridItem>
              </>
            )}

            {underWriting?.propertyDetails?.customerType !== "individual" && (
              <GridItem colSpan={2}>
                <FormControl isRequired>
                  <FormLabel fontWeight={500} color="black">
                    Business Name:
                  </FormLabel>
                  <Input
                    className="inputBorder"
                    type="text"
                    placeholder="Please Enter Business Name"
                    size="lg"
                    focusBorderColor="secondary.500"
                    value={underWriting?.propertyDetails?.buissnessName || ""}
                    onChange={(e) => {
                      setUnderWriting({
                        ...underWriting,
                        propertyDetails: {
                          ...underWriting?.propertyDetails,
                          buissnessName: e.target.value,
                        },
                      });
                    }}
                  />
                  {validationError.underWritingPage &&
                    !underWriting?.propertyDetails?.buissnessName && (
                      <Text color={"red"}>{t("Name cannot be empty")}.</Text>
                    )}
                </FormControl>
              </GridItem>
            )}

            <GridItem>
              <FormControl isRequired>
                <FormLabel fontWeight={500} color="black">
                  {t("priamry_application_phone_number")}:{" "}
                </FormLabel>
                <Box
                  className="inputBorder"
                  py={"1"}
                  _hover={{
                    borderColor: "neutral.300",
                  }}
                  _activeLink={{
                    borderColor: "secondary.500",
                  }}
                >
                  <MaskedFormControl
                    style={{
                      borderColor: "transparent",
                      paddingTop: "5px",
                      paddingBottom: "5px",
                    }}
                    name="phone-number"
                    placeholder="&nbsp;"
                    size="md"
                    mask="(111) 111-1111"
                    guide={false}
                    value={
                      (underWriting?.propertyDetails?.phoneNumber &&
                        underWriting?.propertyDetails?.phoneNumber.replace(
                          /^\+\d+\s?/,
                          ""
                        )) ||
                      ""
                    }
                    onChange={(e) => {
                      setUnderWriting({
                        ...underWriting,
                        propertyDetails: {
                          ...underWriting?.propertyDetails,
                          phoneNumber: e.target.value,
                          //only for US numbers
                        },
                      });
                    }}
                  />
                </Box>
                {validationError.underWritingPage &&
                  (underWriting?.propertyDetails?.phoneNumber === "+1 " ||
                    underWriting?.propertyDetails?.phoneNumber === "") &&
                  !isValidPhoneNumber(
                    underWriting?.propertyDetails?.phoneNumber
                  ) && (
                    <Text color={"red"}>
                      {t("Must be valid a phone number")}.
                    </Text>
                  )}
                {/* {validationError.underWritingPage &&
                  !isValidPhoneNumber(
                    underWriting?.propertyDetails?.phoneNumber
                  ) && (
                    <Text color={"red"}>
                      {t("is a valid phone number")}.
                    </Text>
                  )} */}
              </FormControl>
            </GridItem>
            <GridItem>
              <FormControl isRequired>
                <FormLabel fontWeight={500} color="black">
                  {t("primary_application_email")}:
                </FormLabel>
                <Input
                  className="inputBorder"
                  name="primary-application"
                  type="email"
                  size="lg"
                  focusBorderColor="secondary.500"
                  // _placeholder={{ opacity: 1, color: "neutral.500" }}
                  value={underWriting?.propertyDetails?.email || ""}
                  onChange={(e) => {
                    setUnderWriting({
                      ...underWriting,
                      propertyDetails: {
                        ...underWriting?.propertyDetails,
                        email: e.target.value,
                      },
                    });
                  }}
                />
                {validationError.underWritingPage &&
                  !underWriting?.propertyDetails?.email && (
                    <Text color={"red"}>{t("Email cannot be empty.")}</Text>
                  )}
                {validationError.underWritingPage &&
                  underWriting?.propertyDetails?.email &&
                  !isValidEmail(underWriting?.propertyDetails?.email) && (
                    <Text color={"red"}>
                      {t("Please enter a valid email address")}
                    </Text>
                  )}
              </FormControl>
            </GridItem>
          </Grid>

          {confirmation?.additionalInsuredFlag &&
          underWriting?.propertyDetails?.customerType === "individual" ? (
            <>
              <Grid
                w="100%"
                templateColumns="repeat(4, 1fr)"
                gap={4}
                pt={4}
                pb={2}
              >
                <GridItem>
                  <FormControl isRequired>
                    <FormLabel fontWeight={500} color="black">
                      Secondary Applicant's First Name:
                    </FormLabel>
                    <Input
                      type="text"
                      value={
                        confirmation?.additionalInsured[0]?.firstName || ""
                      }
                      onChange={(e) => {
                        setConfirmation({
                          ...confirmation,
                          additionalInsured: [
                            {
                              ...confirmation?.additionalInsured[0],
                              firstName: e.target.value,
                            },
                          ],
                        });
                      }}
                      className="inputBorder"
                      size={"lg"}
                    />
                  </FormControl>
                  {validationError.underWritingPage &&
                    !confirmation?.additionalInsured[0]?.firstName && (
                      <Text color={"red"}>{t("First Name is required")}.</Text>
                    )}
                </GridItem>
                <GridItem>
                  <FormControl isRequired>
                    <FormLabel fontWeight={500} color="black">
                      Secondary Applicant's Last Name:
                    </FormLabel>
                    <Input
                      className="inputBorder"
                      type="text"
                      value={confirmation?.additionalInsured[0]?.lastName || ""}
                      onChange={(e) => {
                        setConfirmation({
                          ...confirmation,
                          additionalInsured: [
                            {
                              ...confirmation?.additionalInsured[0],
                              lastName: e.target.value,
                            },
                          ],
                        });
                      }}
                      size={"lg"}
                    />
                  </FormControl>
                  {validationError.underWritingPage &&
                    !confirmation?.additionalInsured[0]?.lastName && (
                      <Text color={"red"}>{t("Last Name is required")}.</Text>
                    )}
                </GridItem>
                <GridItem>
                  <FormControl>
                    <FormLabel fontWeight={500} color="black">
                      Secondary Applicant's Phone No:
                    </FormLabel>
                    <Box
                      className="inputBorder"
                      py={"0.21rem"}
                      _hover={{
                        borderColor: "neutral.300",
                      }}
                      _activeLink={{
                        borderColor: "secondary.500",
                      }}
                    >
                      <MaskedFormControl
                        style={{
                          borderColor: "transparent",
                          paddingTop: "5px",
                          paddingBottom: "5px",
                        }}
                        name="phone-number"
                        size="md"
                        placeholder="&nbsp;"
                        mask="(111) 111-1111"
                        guide={false}
                        value={
                          (confirmation?.additionalInsured[0]?.phoneNumber &&
                            confirmation?.additionalInsured[0]?.phoneNumber.replace(
                              /^\+\d+\s?/,
                              ""
                            )) ||
                          ""
                        }
                        onChange={(e) => {
                          setConfirmation({
                            ...confirmation,
                            additionalInsured: [
                              {
                                ...confirmation?.additionalInsured[0],
                                phoneNumber: e.target.value,
                                //only for US numbers
                              },
                            ],
                          });
                        }}
                      />
                    </Box>
                    {/* {(validationError.underWritingPage &&
                      !confirmation?.additionalInsured[0]?.phoneNumber) ||
                      (confirmation?.additionalInsured[0]?.phoneNumber ===
                        "+1 " && <Text color={"red"}>{t("Required")}.</Text>)} */}
                  </FormControl>
                </GridItem>
                <GridItem>
                  <Flex gap={1}>
                    <FormControl>
                      <FormLabel fontWeight={500} color="black">
                        Secondary Applicant's Email:
                      </FormLabel>
                      <Input
                        type="email"
                        value={confirmation?.additionalInsured[0]?.email || ""}
                        onChange={(e) => {
                          setConfirmation({
                            ...confirmation,
                            additionalInsured: [
                              {
                                ...confirmation?.additionalInsured[0],
                                email: e.target.value,
                              },
                            ],
                          });
                        }}
                        className="inputBorder"
                        size={"lg"}
                      />
                    </FormControl>
                    <Flex
                      pb={"2px"}
                      flexDir={"column"}
                      justifyContent={"flex-end"}
                      alignItems={"center"}
                    >
                      <Button
                        onClick={() => {
                          setConfirmation(() => ({
                            ...confirmation,
                            additionalInsuredFlag: false,
                          }));
                        }}
                        h={"46px"}
                        variant={"outline"}
                        fontWeight={300}
                        borderRadius={"10px"}
                        _hover={{ bgColor: "gray.50" }}
                        _active={{ bgColor: "primary.50" }}
                      >
                        <AiOutlineDelete size={20} />
                      </Button>
                    </Flex>
                  </Flex>
                </GridItem>
              </Grid>
            </>
          ) : (
            <>
              {underWriting?.propertyDetails?.customerType === "individual" && (
                <Flex justifyContent={"flex-end"} alignItems={"end"} mt={2}>
                  <Button
                    onClick={() => {
                      setConfirmation(() => ({
                        ...confirmation,
                        additionalInsuredFlag: true,
                      }));
                    }}
                    variant={"outline"}
                    fontWeight={300}
                    borderRadius={"xl"}
                    _hover={{ bgColor: "gray.50" }}
                    _active={{ bgColor: "primary.50" }}
                  >
                    <CgAddR />
                    <Text pl={2} as={"span"}>
                      {" "}
                      Additional Insured
                    </Text>
                  </Button>
                </Flex>
              )}
            </>
          )}

          <>
            <Flex
              px={1}
              justifyContent={"flex-start"}
              alignItems={"center"}
              alignContent={"center"}
              gap={3}
              width={"45%"}
            >
              <Text
                fontSize={"sm"}
                pt={"1.1rem"}
                fontWeight={500}
                color="neutral.500"
              >
                Is the mailing address the same as the property address?
              </Text>
              <Tooltip
                textAlign={"center"}
                hasArrow
                p={2}
                label={
                  "Same as the Property address used in the quote. will be assign to Primary Insured"
                }
                placement="top"
              >
                <Box mt={1}>
                  <FcInfo size={20} />
                </Box>
              </Tooltip>
              <Switch
                colorScheme="blue"
                name="sameAsMailingAddress"
                isChecked={primaryMailingAddress?.sameAsPropertyAddress}
                onChange={(e) => {
                  setPrimaryMailingAddress({
                    ...primaryMailingAddress,
                    sameAsPropertyAddress: e.target.checked,
                  });
                }}
                size={"lg"}
              />
            </Flex>

            {!primaryMailingAddress?.sameAsPropertyAddress && (
              <Grid w="100%" templateColumns="repeat(4, 1fr)" gap={4} py={2}>
                <>
                  <GridItem w="100%">
                    <FormControl isRequired>
                      <FormLabel pl={1} fontWeight={500}>
                        Street Address / PO Box:
                      </FormLabel>
                      <Input
                        className="inputBorder"
                        border={"2px solid var(--chakra-colors-neutral-500)"}
                        borderRadius={"xl"}
                        name="street_address"
                        type="text"
                        size="lg"
                        focusBorderColor="secondary.500"
                        value={primaryMailingAddress?.street_address || ""}
                        onChange={(e) => {
                          setPrimaryMailingAddress({
                            ...primaryMailingAddress,
                            street_address: e.target.value,
                          });
                        }}
                      />
                      {validationError?.underWritingPage &&
                        !primaryMailingAddress?.sameAsPropertyAddress &&
                        !primaryMailingAddress?.street_address && (
                          <Text pr={1} color={"red"}>
                            {t("Street Address is required")}.
                          </Text>
                        )}
                    </FormControl>
                  </GridItem>

                  <GridItem w="100%">
                    <FormControl isRequired>
                      <FormLabel pl={1} fontWeight={500}>
                        {t("City")}:
                      </FormLabel>
                      <Input
                        bg={"gray.100"}
                        cursor={"not-allowed"}
                        autoComplete="off"
                        isDisabled
                        _disabled={{ opacity: 1 }}
                        className="inputBorder"
                        border={"2px solid var(--chakra-colors-neutral-500)"}
                        borderRadius={"xl"}
                        name="city"
                        type="text"
                        size="lg"
                        focusBorderColor="secondary.500"
                        value={primaryMailingAddress?.city || ""}
                        onChange={(e) =>
                          setPrimaryMailingAddress({
                            ...primaryMailingAddress,
                            city: e.target.value,
                          })
                        }
                      />
                      {validationError?.underWritingPage &&
                        !primaryMailingAddress?.sameAsPropertyAddress &&
                        !primaryMailingAddress?.city && (
                          <Text pr={1} color={"red"}>
                            {t("City is required")}.
                          </Text>
                        )}
                    </FormControl>
                  </GridItem>

                  <GridItem w="100%">
                    <FormControl isRequired>
                      <FormLabel pl={1} fontWeight={500}>
                        {t("State")}:
                      </FormLabel>
                      <Select
                        bg={"gray.100"}
                        cursor={"not-allowed"}
                        isDisabled
                        _disabled={{ opacity: 1 }}
                        autoComplete="off"
                        className="inputBorder"
                        border={"2px solid var(--chakra-colors-neutral-500)"}
                        borderRadius={"xl"}
                        name="state"
                        type="text"
                        size="lg"
                        focusBorderColor="secondary.500"
                        value={primaryMailingAddress?.state || ""}
                        onChange={(e) =>
                          setPrimaryMailingAddress({
                            ...primaryMailingAddress,
                            state: e.target.value,
                          })
                        }
                      >
                        <option value=""></option>
                        {states.map((state) => (
                          <option key={state?.name} value={state?.abbr}>
                            {state?.name} ({state?.abbr})
                          </option>
                        ))}
                      </Select>
                      {validationError?.underWritingPage &&
                        !primaryMailingAddress?.sameAsPropertyAddress &&
                        !primaryMailingAddress?.state && (
                          <Text pr={1} color={"red"}>
                            {t("State is required")}.
                          </Text>
                        )}
                    </FormControl>
                  </GridItem>
                  <GridItem w="100%">
                    <FormControl isRequired>
                      <FormLabel pl={1} fontWeight={500}>
                        {t("Zip Code")}:{" "}
                        {isLoading && (
                          <Spinner size={"sm"} color="primary.500" ml={2} />
                        )}
                      </FormLabel>
                      <Input
                        className="inputBorder"
                        border={"2px solid var(--chakra-colors-neutral-500)"}
                        borderRadius={"xl"}
                        name="zipcode"
                        type="number"
                        size="lg"
                        onKeyDown={(e) =>
                          ["e", "E", "+", "-"].includes(e.key) &&
                          e.preventDefault()
                        }
                        onWheel={(e) => {
                          e.target.blur();
                        }}
                        focusBorderColor="secondary.500"
                        value={primaryMailingAddress?.zipcode || ""}
                        onChange={(e) => {
                          if (e.target.value.length <= 5) {
                            setPrimaryMailingAddress({
                              ...primaryMailingAddress,
                              zipcode: e.target.value,
                            });
                          }
                        }}
                      />

                      {validationError?.underWritingPage &&
                        !primaryMailingAddress?.sameAsPropertyAddress &&
                        !primaryMailingAddress?.zipcode && (
                          <Text pr={1} color={"red"}>
                            {t("Zip Code is required")}.
                          </Text>
                        )}
                    </FormControl>
                  </GridItem>
                </>
              </Grid>
            )}

            <Flex
              px={1}
              justifyContent={"flex-start"}
              alignItems={"center"}
              alignContent={"center"}
              gap={3}
              width={"45%"}
            >
              <Text
                fontSize={"sm"}
                pt={"1.1rem"}
                fontWeight={500}
                color="neutral.500"
              >
                Is this a new purchase?
              </Text>
              <Switch
                isChecked={underWriting?.propertyDetails?.isThisNewPurchase}
                onChange={(e) => {
                  setUnderWriting({
                    ...underWriting,
                    propertyDetails: {
                      ...underWriting?.propertyDetails,
                      isThisNewPurchase: e.target.checked,
                    },
                  });
                }}
                colorScheme="blue"
                size={"lg"}
              />
            </Flex>

            {underWriting?.propertyDetails?.isThisNewPurchase && (
              <>
                <NewPurchaseLocation />
              </>
            )}
          </>

          <Box my={5} borderTop={"1px solid"} borderColor={"gray.200"} />
          <Grid w="100%" templateColumns="repeat(4, 1fr)" gap={4} pt={2} pb={2}>
            <GridItem>
              <FormControl isRequired>
                <FormLabel fontWeight={500} color="black">
                  {" "}
                  {t("square_footage")}:{" "}
                </FormLabel>
                <Input
                  className="inputBorder"
                  name="EnterRuleCode"
                  type="text"
                  size="lg"
                  focusBorderColor="secondary.500"
                  value={underWriting?.propertyDetails?.squareFootage || ""}
                  onChange={(e) => {
                    setUnderWriting({
                      ...underWriting,
                      propertyDetails: {
                        ...underWriting?.propertyDetails,
                        squareFootage: Number(e.target.value),
                      },
                    });
                  }}
                />
                {(validationError.underWritingPage &&
                  (!underWriting?.propertyDetails?.squareFootage ||
                    underWriting.propertyDetails.squareFootage <= 0) && (
                    <Text color="red">
                      {t(
                        "Square Footage is required and must be greater than zero."
                      )}
                    </Text>
                  )) ||
                  (underWriting?.propertyDetails?.squareFootage >= 20000 && (
                    <Text color="orange">
                      {t("Possibly a Excess Square Footage.")}
                    </Text>
                  ))}
              </FormControl>
            </GridItem>
            <GridItem>
              <FormControl isRequired>
                <FormLabel fontWeight={500} color="black">
                  {" "}
                  {t("year_built")}:{" "}
                </FormLabel>
                <Input
                  className="inputBorder"
                  name="Description"
                  type="number"
                  // placeholder={t("2009")}
                  size={"lg"}
                  focusBorderColor="secondary.500"
                  value={underWriting?.propertyDetails?.yearBuilt || ""}
                  onKeyDown={(e) =>
                    ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()
                  }
                  onChange={(e) => {
                    if (e.target.value.length <= 4) {
                      setUnderWriting({
                        ...underWriting,
                        propertyDetails: {
                          ...underWriting?.propertyDetails,
                          yearBuilt: Number(e.target.value),
                        },
                      });
                    }
                  }}
                />
                {(validationError.underWritingPage &&
                  (underWriting?.propertyDetails?.yearBuilt === undefined ||
                    underWriting?.propertyDetails?.yearBuilt === null ||
                    (underWriting?.propertyDetails?.yearBuilt === "" && (
                      <Text color={"red"}>{t("Year Built is required")}.</Text>
                    )))) ||
                  (underWriting?.propertyDetails?.yearBuilt >
                    new Date().getFullYear() && (
                    <Text color={"red"}>
                      {t("Year Built cannot be greater than the current year")}
                    </Text>
                  )) ||
                  (underWriting?.propertyDetails?.yearBuilt &&
                    underWriting?.propertyDetails?.yearBuilt.toString()
                      .length !== 4 && (
                      <Text color={"red"}>
                        {t("Year must have exactly 4 digits")}
                      </Text>
                    )) ||
                  (validationError.underWritingPage &&
                    underWriting?.propertyDetails?.yearBuilt <= 0 && (
                      <Text color={"red"}>{t("Year Built is required")}.</Text>
                    ))}
              </FormControl>
            </GridItem>

            <GridItem>
              <FormControl isRequired>
                <FormLabel fontWeight={500} color="black">
                  {" "}
                  {t("usage")}:{" "}
                </FormLabel>
                <Select
                  className="inputBorder"
                  size="lg"
                  value={underWriting?.propertyDetails?.usage || ""}
                  onChange={(e) => {
                    setUnderWriting({
                      ...underWriting,
                      propertyDetails: {
                        ...underWriting?.propertyDetails,
                        usage: e.target.value,
                      },
                    });
                  }}
                >
                  <option value=""></option>
                  {usageData.map((item, index) => (
                    <option key={index} value={item.s_AppCodeName}>
                      {item?.s_AppCodeNameForDisplay}
                    </option>
                  ))}
                </Select>
                {validationError.underWritingPage &&
                  !underWriting?.propertyDetails?.usage && (
                    <Text color={"red"}>{t("Usage field is required")}.</Text>
                  )}
              </FormControl>
            </GridItem>
            <GridItem>
              <FormControl isRequired>
                <FormLabel fontWeight={500} color="black">
                  {t("Construction Type")}:{" "}
                </FormLabel>
                <Select
                  className="inputBorder"
                  size="lg"
                  value={underWriting?.propertyDetails?.constructionType || ""}
                  onChange={(e) => {
                    setUnderWriting({
                      ...underWriting,
                      propertyDetails: {
                        ...underWriting?.propertyDetails,
                        constructionType: e.target.value,
                      },
                    });
                  }}
                >
                  <option value=""></option>
                  {constructionData.map((item, index) => (
                    <option key={index} value={item.s_AppCodeName}>
                      {item.s_AppCodeNameForDisplay}
                    </option>
                  ))}
                </Select>
                {validationError.underWritingPage &&
                  !underWriting?.propertyDetails?.constructionType && (
                    <Text color={"red"}>
                      {t("Construction type is required")}.
                    </Text>
                  )}
              </FormControl>
            </GridItem>
            <GridItem>
              <FormControl isRequired>
                <FormLabel fontWeight={500} color="black">
                  {" "}
                  {t("Foundation Type")}:{" "}
                </FormLabel>
                <Select
                  className="inputBorder"
                  size="lg"
                  value={underWriting?.propertyDetails?.foundationType || ""}
                  onChange={(e) => {
                    setUnderWriting({
                      ...underWriting,
                      propertyDetails: {
                        ...underWriting?.propertyDetails,
                        foundationType: e.target.value,
                      },
                    });
                  }}
                >
                  <option value=""></option>
                  {foundationData.map((item, index) => (
                    <option key={index} value={item.s_AppCodeName}>
                      {item.s_AppCodeNameForDisplay}
                    </option>
                  ))}
                </Select>
                {validationError.underWritingPage &&
                  !underWriting?.propertyDetails?.foundationType && (
                    <Text color={"red"}>
                      {t("Foundation type is required")}.
                    </Text>
                  )}
              </FormControl>
            </GridItem>
            <GridItem>
              <FormControl isRequired>
                <FormLabel fontWeight={500} color="black">
                  {" "}
                  {t("Cladding Type")}:{" "}
                </FormLabel>
                <Select
                  className="inputBorder"
                  size="lg"
                  value={underWriting?.propertyDetails?.claddingType || ""}
                  onChange={(e) => {
                    setUnderWriting({
                      ...underWriting,
                      propertyDetails: {
                        ...underWriting?.propertyDetails,
                        claddingType: e.target.value,
                      },
                    });
                  }}
                >
                  <option value=""></option>
                  {claddingData.map((item, index) => (
                    <option key={index} value={item.s_AppCodeName}>
                      {item.s_AppCodeNameForDisplay}
                    </option>
                  ))}
                </Select>
                {validationError.underWritingPage &&
                  !underWriting?.propertyDetails?.claddingType && (
                    <Text color={"red"}>{t("Cladding type is required")}.</Text>
                  )}
              </FormControl>
            </GridItem>
            <GridItem>
              <FormControl isRequired>
                <FormLabel fontWeight={500} color="black">
                  {" "}
                  {t("Dwelling Stories")}:{" "}
                </FormLabel>
                <Select
                  className="inputBorder"
                  size="lg"
                  value={underWriting?.propertyDetails?.dwellingStories || ""}
                  onChange={(e) => {
                    setUnderWriting({
                      ...underWriting,
                      propertyDetails: {
                        ...underWriting?.propertyDetails,
                        dwellingStories: parseFloat(e.target.value),
                      },
                    });
                  }}
                >
                  <option value=""></option>
                  {dwellingData.map((item, index) => (
                    <option key={index} value={item.s_AppCodeName}>
                      {item.s_AppCodeNameForDisplay}
                    </option>
                  ))}
                </Select>
                {validationError.underWritingPage &&
                  !underWriting?.propertyDetails?.dwellingStories && (
                    <Text color={"red"}>
                      {t("Dwelling stories are required")}.
                    </Text>
                  )}
              </FormControl>
            </GridItem>

            {/* <GridItem>
              <FormControl isRequired>
                <FormLabel fontWeight={500} color="black">
                  {t("Building Is Currently Vacant")}:
                </FormLabel>
                <Select
                  className="inputBorder"
                  size="lg"
                  value={underWriting?.propertyDetails?.vacantIndicator}
                  onChange={(e) => {
                    setUnderWriting({
                      ...underWriting,
                      propertyDetails: {
                        ...underWriting?.propertyDetails,
                        vacantIndicator: e.target.value,
                      },
                    });
                  }}
                >
                  <option value={""}></option>
                  <option value={true}>Yes</option>
                  <option value={false}>No</option>
                </Select>
                {validationError.underWritingPage &&
                  underWriting?.propertyDetails?.vacantIndicator === "" && (
                    <Text color={"red"}>
                      {t("This field cannot be empty")}.
                    </Text>
                  )}
              </FormControl>
            </GridItem> */}
            {/* <GridItem>
              <FormControl isRequired>
                <FormLabel fontWeight={500} color="black">
                  {t("Building Is Under Construction")}:
                </FormLabel>
                <Select
                  className="inputBorder"
                  size="lg"
                  value={underWriting?.propertyDetails?.underConstruction}
                  onChange={(e) => {
                    setUnderWriting({
                      ...underWriting,
                      propertyDetails: {
                        ...underWriting?.propertyDetails,
                        underConstruction: e.target.value,
                      },
                    });
                  }}
                >
                  <option value={""}></option>
                  <option value={true}>Yes</option>
                  <option value={false}>No</option>
                </Select>
                {validationError.underWritingPage &&
                  underWriting?.propertyDetails?.underConstruction === "" && (
                    <Text color={"red"}>
                      {t("This field cannot be empty")}.
                    </Text>
                  )}
              </FormControl>
            </GridItem> */}
            <GridItem>
              <FormControl>
                <FormLabel>
                  <Flex flexDir={"row"} alignItems={"center"} gap={2}>
                    PPC{" "}
                    <Tooltip
                      bg={"black"}
                      p={3}
                      color={"white"}
                      hasArrow
                      label={ppcDescription}
                      placement="top"
                    >
                      <Box>
                        <FcInfo size={18} />
                      </Box>
                    </Tooltip>
                    <Text as={"span"} color={"red.300"}>
                      *
                    </Text>
                  </Flex>
                </FormLabel>
                <Select
                  className="inputBorder"
                  size="lg"
                  value={underWriting?.propertyDetails?.PPC || ""}
                  onChange={(e) => {
                    setUnderWriting({
                      ...underWriting,
                      propertyDetails: {
                        ...underWriting?.propertyDetails,
                        PPC: e.target.value,
                      },
                    });
                  }}
                >
                  <option value=""></option>
                  {ppcData &&
                    ppcData?.map((item, index) => (
                      <option key={index} value={item.s_AppCodeName}>
                        {item.s_AppCodeNameForDisplay}
                      </option>
                    ))}
                </Select>
                {validationError.underWritingPage &&
                  !underWriting?.propertyDetails?.PPC && (
                    <Text px={1} color={"red"}>
                      {t("(PPC) is required")}.
                    </Text>
                  )}
              </FormControl>
            </GridItem>
          </Grid>
          <Box />
        </Box>
      </Box>
    </>
  );
}
