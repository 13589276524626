import { useEffect, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { getCvg } from "../../../../services/coreSystemService";
import { isAllowedToBindQuoteInHiscoxFloodPlus } from "../../../../services/moduleVisibilityService";
import { EncryptOrDecrypt } from "../../../../services/commonService";
import { FaLink, FaPrint } from "react-icons/fa";
import { getBindStatus } from "../api/saveBindAndIssueToDB";
import { postRenewBind } from "../api/postRenewBind";
import { printQuote } from "../api/printQuote";

export const BindRnewedPolicy = ({
  policyNumber,
  productType,
  commercialProductType,
  coreIDs,
  quoteDetailData,
  setLoading,
  productCode,
}) => {
  const [coverageData, setCoverageData] = useState(null);
  const dispatch = useDispatch();
  const reduxStore = useSelector((state) => state);
  const [bindClicked, setBindClicked] = useState(false);
  const [callBind, setCallBind] = useState(false);
  const userDatas = EncryptOrDecrypt(localStorage.getItem("user"), "D");
  const userLevel = userDatas.accessRights.levelName;
  const userSubLevel = userDatas.accessRights.subLevelName;
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [bindingRef, setBindingRef] = useState("");

  const handleBindClick = () => {
    setShowConfirmModal(true);
  };

  const handleConfirmBind = () => {
    setShowConfirmModal(false);
    setBindClicked(true);
  };

  const handleCancelBind = () => {
    setShowConfirmModal(false);
  };

  const getCoverageInfo = async () => {
    const policyNoID = coreIDs.PolicyPK;
    const termMasterPK = coreIDs.TransPK;
    try {
      setLoading(true);
      const res = await dispatch(getCvg(policyNoID, termMasterPK));
      if (res?.data) {
        setCoverageData(res?.data?.basicCvgData);
        setCallBind(true);
      } else {
        setCoverageData(null);
        toast.error("Can't Fetch Policy Details!");
      }
    } catch (error) {
      toast.error("Something Went Wrong!");
    } finally {
      setBindClicked(false);
      setLoading(false);
    }
  };

  async function fetchBindStatus() {
    try {
      setLoading(true);
      const res = await getBindStatus({ trans_pk: coreIDs.TransPK });
      if (res && res?.status === true) {
        window.location.reload();
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }

  function findCoverageInfo(data, coverageCode) {
    for (let i = 0; i < data.length; i++) {
      if (data[i].s_CoverageCode === coverageCode) {
        return data[i];
      }
    }
    return null;
  }

  useEffect(() => {
    if (bindClicked) {
      getCoverageInfo();
    }
  }, [bindClicked]);

  useEffect(() => {
    if (callBind && coverageData !== null) {
      bindPremium();
    }
  }, [coverageData, callBind]);

  const bindPremium = async () => {
    const reqBody = {
      productType,
      commercialProductType,
      policyType:
        quoteDetailData?.RA?.metadata?.premium?.selectedPremium?.type || "",
      premium: Number(
        parseFloat(
          reduxStore?.coreSystem?.applicationData?.n_PremiumChange
        ).toFixed(2)
      ),
      includeLossOfUse: !commercialProductType
        ? findCoverageInfo(coverageData, "HOMCVGD")?.n_InsuredValue * 1 !== 0
        : findCoverageInfo(coverageData, "BUSIINCOMEEXP")?.n_InsuredValue *
            1 !==
          0,
      includeContents:
        findCoverageInfo(coverageData, "FLDCONTCVGAMT")?.n_InsuredValue * 1 !==
        0,
      buildingLimit:
        commercialProductType === "Tenanted"
          ? findCoverageInfo(coverageData, "FLDBLDCVGAMT")?.n_InsuredValue * 1
          : findCoverageInfo(coverageData, "FLDBLDCVGAMT")?.n_InsuredValue * 1,
      contentsLimit:
        findCoverageInfo(coverageData, "FLDCONTCVGAMT")?.n_InsuredValue * 1 ??
        null,
      deductible:
        findCoverageInfo(
          coverageData,
          "FLDCONTCVGAMT"
        )?.s_PrDiscountCode?.replace("FLDDED", "") * 1,
      effectiveDate: "",
      bindingReference: bindingRef,
      fullName: reduxStore?.coreSystem?.applicationData?.s_FullLegalName || "",
    };
    const product = productCode === "HiscoxFloodPlus" ? "hiscox" : productCode;
    const cleanedRequestBody = Object.fromEntries(
      Object.entries(reqBody).filter(([_, value]) => value !== null)
    );
    const quoteNo = policyNumber;
    try {
      setLoading(true);
      const res = await postRenewBind({
        requestBody: cleanedRequestBody,
        policyId: quoteNo,
        productName: product,
      });

      if (res?.status === false && Array.isArray(res?.errors)) {
        res.errors.forEach((error) =>
          toast.error(error, { autoClose: 5000, toastId: "premiumUpdated" })
        );
      } else if (res?.status === true) {
        toast.success("Quote Renewed Successfully", {
          autoClose: 5000,
          toastId: "premiumUpdated1",
        });
        fetchBindStatus();
      }
    } catch (error) {
      console.error("Error binding premium:", error);
    } finally {
      setLoading(false);
    }
  };

  /**
   * Generate print quote
   * @param {'HCX_QUOTE'} formType 
   */
  const generatePrintQuote = async (formType) => {
    try {
      setLoading(true);
      const res = await printQuote({
        formType: formType,
        poTransPK: coreIDs.TransPK
      });

      if (res?.status === false && res?.error) {
        toast.error(res?.error, { autoClose: 5000, toastId: "printQuote" })
      } else if (res?.status === true) {
        toast.success("Print quote generated Successfully", {
          autoClose: 5000,
          toastId: "printQuote",
        });
        window.open(res?.url, '_blank');
      }
    } catch (error) {
      console.error("Error while generating print quote:", error);
    } finally {
      setLoading(false);
    }
  }

  return (
    <>
      <Button
        className="d-flex alignt-items-center gap-2"
        onClick={() => generatePrintQuote('HCX_QUOTE')}
        id="common-btn"
        variant="primary"
        size="sm"
        style={{ padding: "4px  6px" }}
      >
        <FaPrint size={18} />
        Print Quote
      </Button>
      {isAllowedToBindQuoteInHiscoxFloodPlus(userLevel, userSubLevel) && (
        <>
          <Button
            className="d-flex alignt-items-center gap-2"
            onClick={handleBindClick}
            id="common-btn"
            variant="primary"
            size="sm"
            style={{ padding: "4px  6px" }}
          >
            <FaLink size={18} />
            Bind Renewed Policy
          </Button>
        </>
      )}

      <Modal size="lg" show={showConfirmModal} onHide={handleCancelBind}>
        <Modal.Header id="common-btn" closeButton>
          <Modal.Title>Confirm Binding Renewed Policy</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group
              controlId="bindingRef"
              className="d-flex gap-2 align-items-center"
            >
              <Form.Label className="flex-grow-0 w-100 align-items-center mt-2">
                Binding Reference :
              </Form.Label>
              <Form.Control
                type="text"
                placeholder="_"
                value={bindingRef}
                onChange={(e) => setBindingRef(e.target.value)}
                isInvalid={!bindingRef}
                required
              />
              <Form.Control.Feedback type="invalid">
                Binding Reference is Required.
              </Form.Control.Feedback>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            id="common-btn"
            style={{ padding: "6px 8px" }}
            className="mr-2"
            variant="secondary"
            onClick={handleCancelBind}
          >
            Cancel
          </Button>
          <Button
            id="common-btn"
            style={{ padding: "6px 8px" }}
            variant="primary"
            onClick={handleConfirmBind}
            disabled={!bindingRef}
          >
            Bind Renewal
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
