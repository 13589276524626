import { Box, Flex, Spacer, Switch, Text, Button } from "@chakra-ui/react";

import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import { useStarDataContext } from "../../StarDataContext";
import { v4 as uuidv4 } from "uuid";
import { useEffect } from "react";

export default function Agreements() {
  const { lang } = useParams();
  const { t } = useTranslation(lang, "quote");
  const {
    agreements,
    setAgreements,
    setUniqueID,
    setBoundData,
    setStarDataObject,
    setVendors,
  } = useStarDataContext();

  useEffect(() => {
    //WE ARE RESETTING HERE,
    //Due to Single Bound Agreement Mentioned by @StarLight
    //@Author : TheEinshine
    //@Date : 05-15-2024
    //UUID Crud Through v2 api.
    // setUniqueID(uuidv4());
    setBoundData({});
    // setStarDataObject({});
    setVendors({
      credit_score: false,
      geocoding: false,
      replacement_cost: false,
      property_characteristics: false,
      cat_losses: false,
    });

    return () => {};
  }, []);

  useEffect(() => {
    setAgreements({
      ...agreements,
      acknowledge1: false,
      acknowledge2: false,
      acknowledge3: false,
      acknowledge4: false,
      acknowledge5: false,
      acknowledge6: false,
      acknowledge7: false,
      acknowledge8: false,
    });
  }, []);

  return (
    <Box pb={"2.5rem"}>
      <Box
        background="var(--chakra-colors-primary-50)"
        borderRadius={"xl"}
        p={"0.1rem"}
        mb="10px"
        display={"flex"}
        justifyContent={"space-between"}
        alignItems={"center"}
      >
        <Text fontSize={"2xl"} mt={3} pl="1.5rem" fontWeight="400">
          {t("Disclosures and Signatures")}
        </Text>
      </Box>
      <Flex p={3}>
        <Box width="95%">
          <Text fontSize={"17.5px"} pt={2} pl="10px" fontWeight="400">
            {t(
              "I acknowledge that this policy will not cover bodily injury or property damage caused by any animal owned or kept by any insured, whether or not the injury occurs on your Insured Property or in any other location."
            )}
          </Text>
        </Box>
        <Spacer />
        <Box pt={2}>
          <Switch
            size="lg"
            isChecked={agreements?.acknowledge1}
            onChange={(e) =>
              setAgreements({
                ...agreements,
                acknowledge1: e.target.checked,
              })
            }
          />
        </Box>
      </Flex>
      <Box my={"0.1rem"} borderTop={"1px solid"} borderColor={"gray.200"} />
      <Flex p={3}>
        <Box width="95%">
          <Text fontSize={"17.5px"} pt={"1.5rem"} pl="10px" fontWeight="400">
            {t(
              "I acknowledge the company's access to the dwelling for the limited purpose of obtaining relevant underwriting data. Inspections that require access to the interior of the dwelling will be scheduled in advance with the applicant. "
            )}
          </Text>
        </Box>
        <Spacer />
        <Box pt={6}>
          <Switch
            size="lg"
            isChecked={agreements?.acknowledge2}
            onChange={(e) =>
              setAgreements({
                ...agreements,
                acknowledge2: e.target.checked,
              })
            }
          />
        </Box>
      </Flex>
      <Box my={"0.1rem"} borderTop={"1px solid"} borderColor={"gray.200"} />
      <Flex p={3}>
        <Box width="93%">
          <Text fontSize={"17.5px"} pt={1} pl="10px" fontWeight="400">
            {t(
              "I acknowledge the policy excludes flood coverage. Flood insurance is not provided under this policy written by the Company, and the Company will not cover my property for any loss caused by or resulting from flood waters."
            )}
          </Text>
        </Box>
        <Spacer />
        <Box pt={2}>
          <Switch
            isChecked={agreements?.acknowledge3}
            size="lg"
            onChange={(e) =>
              setAgreements({
                ...agreements,
                acknowledge3: e.target.checked,
              })
            }
          />
        </Box>
      </Flex>
      <Box my={"0.1rem"} borderTop={"1px solid"} borderColor={"gray.200"} />
      <Flex p={3}>
        <Box width="95%">
          <Text fontSize={"17.5px"} pt={1} pl="10px" fontWeight="400">
            {t(
              "I acknowledge that should the Insured Property be situated within Jackson County, Harrison County, or Hancock County, I am presently maintaining and will continuously have an active flood insurance policy in force, which is essential for the validity of this policy."
            )}
          </Text>
        </Box>
        <Spacer />
        <Box pt={2}>
          <Switch
            isChecked={agreements?.acknowledge4}
            size="lg"
            onChange={(e) =>
              setAgreements({
                ...agreements,
                acknowledge4: e.target.checked,
              })
            }
          />
        </Box>
      </Flex>
      <Box my={"0.1rem"} borderTop={"1px solid"} borderColor={"gray.200"} />
      <Flex p={3}>
        <Box width="95%">
          <Text fontSize={"17.5px"} pt={1} pl="10px" fontWeight="400">
            {t(
              "I acknowledge and understand that Starlight Insurance Group may send me additional communications regarding Starlight Insurance Group services."
            )}
          </Text>
        </Box>
        <Spacer />
        <Box pt={2}>
          <Switch
            size="lg"
            isChecked={agreements?.acknowledge5}
            onChange={(e) =>
              setAgreements({
                ...agreements,
                acknowledge5: e.target.checked,
              })
            }
          />
        </Box>
      </Flex>
      <Box my={"0.1rem"} borderTop={"1px solid"} borderColor={"gray.200"} />
      <Flex p={3}>
        <Box width="95%">
          <Text fontSize={"17.5px"} pt={1} pl="10px" fontWeight="400">
            {t(
              "I acknowledge that personal information may be collected from persons other than you in connection with this insurance application and subsequent amendments and renewals. "
            )}
          </Text>
        </Box>
        <Spacer />
        <Box pt={2}>
          <Switch
            size="lg"
            isChecked={agreements?.acknowledge6}
            onChange={(e) =>
              setAgreements({
                ...agreements,
                acknowledge6: e.target.checked,
              })
            }
          />
        </Box>
      </Flex>
      <Box my={"0.1rem"} borderTop={"1px solid"} borderColor={"gray.200"} />
      <Flex p={3}>
        <Box width="93%">
          <Text fontSize={"17.5px"} pt={1} pl="10px" fontWeight="400">
            {t(
              "I acknowledge that I am responsible for notifying the Company within 60 days of any change of ownership, title, use or occupancy of the Insured Property. If the company has not been notified within 60 days, any loss occurring from the 61st day after such a change to the date proper notice is given will be excluded from coverage. If this occurs, the premium will be refunded for the period during which the coverage is suspended."
            )}
          </Text>
        </Box>
        <Spacer />
        <Box pt={2}>
          <Switch
            size="lg"
            isChecked={agreements?.acknowledge7}
            onChange={(e) =>
              setAgreements({
                ...agreements,
                acknowledge7: e.target.checked,
              })
            }
          />
        </Box>
      </Flex>
      <Box my={"0.1rem"} borderTop={"1px solid"} borderColor={"gray.200"} />
      <Flex p={3}>
        <Box width="95%">
          <Text fontSize={"17.5px"} pt={1} pl="10px" fontWeight="400">
            {t(
              " I acknowledge that any person who knowingly and with intent to injure, defraud, or deceive any insurer, files a statement of claim or an application containing any false, incomplete or misleading information is guilty of a felony of the third degree."
            )}
          </Text>
        </Box>
        <Spacer />
        <Box pt={2}>
          <Switch
            size="lg"
            isChecked={agreements?.acknowledge8}
            onChange={(e) =>
              setAgreements({
                ...agreements,
                acknowledge8: e.target.checked,
              })
            }
          />
        </Box>
      </Flex>
      <Box my={"0.1rem"} borderTop={"1px solid"} borderColor={"gray.200"} />
      <Flex justifyContent={"flex-end"}>
        <Box py={4} pt={6}>
          <Button
            onClick={() =>
              setAgreements({
                ...agreements,
                acknowledge1: true,
                acknowledge2: true,
                acknowledge3: true,
                acknowledge4: true,
                acknowledge5: true,
                acknowledge6: true,
                acknowledge7: true,
                acknowledge8: true,
              })
            }
            borderRadius={"2xl"}
            size={"md"}
            fontWeight={300}
          >
            Agree to All
          </Button>
        </Box>
      </Flex>
      <Box
        my={"0.1rem"}
        mt={"0.5rem"}
        borderTop={"1px solid"}
        borderColor={"gray.200"}
      />
    </Box>
  );
}
