import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col, Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Navigate } from "react-router-dom";
import Validation from "../../Validation";
import MaskedFormControl from 'react-bootstrap-maskedinput'
import "./flood-contact-information.scss";
import { getEditEmptyQuoteData } from "../../../../../../services/floodService";
import ModeRouter from "./../../../form-mode/ModeRouter";

const FloodContactInformation = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const inputRef = useRef();
  const { floodSystemDD, propertyDataFromAdd } = useSelector(state => state.flood);
  const { emptyQuoteData, pageMode } = useSelector(state => state.flood);
  const [validated, setValidated] = useState(false);
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [memberNo, setMemberNo] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [applicantType, setApplicantType] = useState('INDIVIDUAL');
  const [isSecondInsuredExist, setIsSecondInsuredExist] = useState(false);
  const [secondInsuredFirstName, setSecondInsuredFirstName] = useState('');
  const [secondInsuredLastName, setSecondInsuredLastName] = useState('');



  const toggle = (e) => {
    setValidated(e);
  }

  // Set State from reducer
  useEffect(() => {
    if (Object.keys(emptyQuoteData).length > 0) {
      setApplicantType(emptyQuoteData.personInfo.s_EntityType || '')
      setFirstName(emptyQuoteData.personInfo.s_FirstName || '');
      setLastName(emptyQuoteData.personInfo.s_LastOrganizationName || '');
      setEmail(emptyQuoteData.personEmailContact.s_CommValue);
      setPhone(emptyQuoteData.personPhoneContact.s_CommValue);
      setIsSecondInsuredExist(emptyQuoteData.propertyInfo.s_IsAddInsureds === 'YES' ? true : false);
      if (emptyQuoteData.propertyInfo.s_IsAddInsureds === 'YES') {
        setSecondInsuredFirstName(emptyQuoteData?.additionaInsuredData[0]?.person?.s_FirstName);
        setSecondInsuredLastName(emptyQuoteData?.additionaInsuredData[0]?.person?.s_LastOrganizationName)
      }
      if (emptyQuoteData.personInfo.metadata) {
        setMemberNo(emptyQuoteData.personInfo.metadata.member_id);
      }
    }
  }, [emptyQuoteData])

  useEffect(() => {
    if (propertyDataFromAdd && propertyDataFromAdd?.EastedData) {
      let secondInsuredFirstName = propertyDataFromAdd?.EastedData?.data?.deeds?.[0]?.buyer2_first_name || '';
      let secondInsuredLastName = propertyDataFromAdd?.EastedData?.data?.deeds?.[0]?.buyer2_last_name || '';

      if (secondInsuredFirstName || secondInsuredLastName) {
        // second insured first name
        let secondInsured = secondInsuredFirstName;
        // if not empty concat space
        if (secondInsured) secondInsured = secondInsured + ' ';
        // concat second insured last name
        secondInsured = secondInsured + secondInsuredLastName;

        if (secondInsured) {
          setIsSecondInsuredExist(true);
          setSecondInsuredFirstName(secondInsuredFirstName);
          setSecondInsuredLastName(secondInsuredLastName)
        }

        props.handleChange({}, {
          InsuredFirstName: secondInsuredFirstName,
          InsuredLastName: secondInsuredLastName
        })
      }
    }
  }, [propertyDataFromAdd])

  useEffect(() => {
    if (["BUSINESS", "ASSOCIATION"].includes(applicantType)) {
      setFirstName('');
      var emptyQuote = { ...emptyQuoteData };
      emptyQuote.personInfo.s_FirstName = '';
      dispatch(getEditEmptyQuoteData(emptyQuote));
    }
  }, [applicantType])

  // set membor no in emptyquote
  const handleMemborNoChange = (value) => {
    var emptyQuote = { ...emptyQuoteData };
    emptyQuote.personInfo.metadata = {
      member_id: value
    }
    dispatch(getEditEmptyQuoteData(emptyQuote));
    setMemberNo(value);
  }

  useEffect(() => {
    // inputRef.current.focus();
  }, []);

  const handleCheckboxChange = (value) => {
    let isAdditInsured = 'NO';
    if (value) isAdditInsured = 'YES';
    var emptyQuote = { ...emptyQuoteData };
    emptyQuote.propertyInfo.s_IsAddInsureds = isAdditInsured;
    // reset if false
    if (isAdditInsured === 'NO') {
      // to delete 0th index from an array
      emptyQuote.additionaInsuredData.splice(0);

      // setSecondInsuredName(null);
      setSecondInsuredFirstName(null);
      setSecondInsuredLastName(null);
    }
    setIsSecondInsuredExist(value);
    dispatch(getEditEmptyQuoteData(emptyQuote));
  };

  const updateFullLegalName = (forCode) => {
    if (forCode === 'INSURED') {
      var emptyQuote = { ...emptyQuoteData };
      let fullLegalName = firstName;
      if (fullLegalName) fullLegalName += ' ';
      fullLegalName += lastName;
      emptyQuote.personInfo.s_FullLegalName = fullLegalName;
      dispatch(getEditEmptyQuoteData(emptyQuote));
    } else {
      props.handleChange('', {
        InsuredFirstName: secondInsuredFirstName,
        InsuredLastName: secondInsuredLastName
      })
    }
  }

  return (
    <div>
      {pageMode ? <ModeRouter /> :
        (
          <section className="property-details-section">
            {
              Object.keys(emptyQuoteData).length === 0 && <Navigate to={'/flood/instantquote/step/address'} />
            }

            {/* Form */}
            <Row className="justify-content-center">
              <Col
                xs="12"
                sm="12"
                md="10"
                lg="8"
                xl="8"
                className="p-form"
                id="flood-cif"
              >
                <Row className="justify-content-center" id="contact-info-div">
                  <Col xs="12" sm="10" md="10" lg="10" xl="10">
                    <h1>{t('Please Add Policyholder/Member Contact Information')}</h1>
                    <Row className="justify-content-start">
                      <Col xs="12">
                        <Form validated={validated} className="confirm-address-form">
                          <Row>
                            <Col xs="12">
                              <Form.Group
                                className="confirm-address-field"
                                controlId="formBasicEmail"
                              >
                                <Form.Label className="confirm-address-label">
                                  {t('Applicant Type')}
                                </Form.Label>
                                <Form.Select
                                  className="option-group"
                                  name="s_EntityType"
                                  parentobject="personInfo"
                                  title={t('Applicant Type')}
                                  value={applicantType}
                                  onChange={(e) => {
                                    props.handleChange(e);
                                    setApplicantType(e.currentTarget.value)
                                  }}
                                  required
                                  tabIndex="0"
                                >
                                  {
                                    Object.keys(floodSystemDD).length > 0 && floodSystemDD.FLOODAPPLICANTTYPE.map((v, i) => {
                                      return <option key={i} value={v.s_AppCodeName}>{t(v.s_AppCodeNameForDisplay)}</option>
                                    })
                                  }
                                </Form.Select>
                              </Form.Group>
                            </Col>
                            {
                              (["INDIVIDUAL"].includes(applicantType)) && (
                                <React.Fragment>
                                  <Col xs="6">
                                    <Form.Group
                                      controlId="formBasicPassword"
                                      className="confirm-address-field"
                                    >
                                      <Form.Label className="confirm-address-label">
                                        {t('First Name')}
                                      </Form.Label>
                                      <Form.Control
                                        className="confirm-address-input"
                                        type="text"
                                        placeholder={t("Enter your first name")}
                                        name="s_FirstName"
                                        title={t('First Name')}
                                        parentobject="personInfo"
                                        value={firstName}
                                        defaultValue={''}
                                        onChange={(e) => {
                                          props.handleChange(e);
                                          setFirstName(e.currentTarget.value);
                                        }}
                                        required
                                      />
                                      <Form.Control.Feedback type="invalid">
                                        {t("Please Add First Name")}
                                      </Form.Control.Feedback>
                                    </Form.Group>
                                  </Col>
                                </React.Fragment>
                              )
                            }
                            <Col xs={(["INDIVIDUAL"].includes(applicantType)) ? "6" : "12"}>
                              <Form.Group
                                className="confirm-address-field"
                                controlId="formBasicPassword"
                              >
                                <Form.Label className="confirm-address-label">
                                  {(["INDIVIDUAL"].includes(applicantType)) ? t('Last Name') : t('Full Name')}
                                </Form.Label>
                                <Form.Control
                                  className="confirm-address-input"
                                  type="text"
                                  placeholder={
                                    (applicantType === "ASSOCIATION" && t('Association Name')) ||
                                    (applicantType === "INDIVIDUAL" && t('Please enter last name')) ||
                                    (applicantType === "BUSINESS" && t('Business Name'))
                                  }
                                  name="s_LastOrganizationName"
                                  title={(["INDIVIDUAL"].includes(applicantType)) ? t('Last Name') : t('Full Name')}
                                  parentobject="personInfo"
                                  value={lastName}
                                  defaultValue={''}
                                  onChange={(e) => {
                                    props.handleChange(e);
                                    setLastName(e.currentTarget.value);
                                  }}
                                  onBlur={() => updateFullLegalName('INSURED')}
                                  required
                                />
                                <Form.Control.Feedback type="invalid">
                                  {["INDIVIDUAL"].includes(applicantType) ? t("Please Add Last Name") : t("Please Add Full Name")}
                                </Form.Control.Feedback>
                              </Form.Group>
                            </Col>
                            <Col xs="12">
                              <Form.Group
                                controlId="formBasicPassword"
                                className="confirm-address-field"
                                style={{ display: "flex" }}
                              >
                                <Form.Label className="confirm-address-label" style={{ paddingTop: "20px" }}>
                                  {t('Is there second insured?')}
                                </Form.Label>
                                <Form.Check
                                  type="checkbox"
                                  defaultChecked
                                  style={{ paddingTop: "18px", paddingLeft: "10px" }}
                                  checked={isSecondInsuredExist}
                                  onChange={() => handleCheckboxChange(!isSecondInsuredExist)}
                                />
                              </Form.Group>
                            </Col>
                            {
                              // Second Insured Fields
                              (isSecondInsuredExist) && (
                                <React.Fragment>
                                  <Col xs="6" >
                                    <Form.Group
                                      controlId="formBasicPassword"
                                      className="confirm-address-field"
                                    >
                                      <Form.Control
                                        className="confirm-address-input"
                                        type="text"
                                        placeholder={t("Second Insured First Name")}
                                        name="s_FirstName"
                                        title={t('Second Insured First Name')}
                                        parentobject="person"
                                        value={secondInsuredFirstName}
                                        defaultValue={''}
                                        onChange={(e) => {
                                          setSecondInsuredFirstName(e.currentTarget.value);
                                        }}
                                        onBlur={() => updateFullLegalName()}
                                        required
                                      />
                                    </Form.Group>
                                  </Col>
                                  <Col xs="6">
                                    <Form.Group
                                      controlId="formBasicPassword"
                                      className="confirm-address-field"
                                    >
                                      <Form.Control
                                        className="confirm-address-input"
                                        type="text"
                                        placeholder={t("Second Insured Last Name")}
                                        name="s_LastOrganizationName"
                                        title={t('Second Insured Last Name')}
                                        parentobject="person"
                                        value={secondInsuredLastName}
                                        defaultValue={''}
                                        onChange={(e) => {
                                          setSecondInsuredLastName(e.currentTarget.value);
                                        }}
                                        onBlur={() => updateFullLegalName()}
                                        required
                                      />
                                    </Form.Group>
                                  </Col>
                                </React.Fragment>
                              )
                            }
                            <Col xs="6">
                              <Form.Group
                                controlId="formBasicPassword"
                                className="confirm-address-field"
                              >
                                <Form.Label className="confirm-address-label">
                                  {t('Email')}
                                </Form.Label>
                                <Form.Control
                                  className="confirm-address-input"
                                  type="email"
                                  placeholder={t("Enter Email")}
                                  name="s_CommValue"
                                  title={t('Email')}
                                  parentobject="personEmailContact"
                                  value={email}
                                  defaultValue={''}
                                  onChange={(e) => {
                                    props.handleChange(e);
                                    setEmail(e.currentTarget.value);
                                  }}
                                  required
                                />
                                <Form.Control.Feedback type="invalid">
                                  {t("Please Add Valid Email Address Error")}
                                </Form.Control.Feedback>
                              </Form.Group>
                            </Col>
                            <Col xs="6">
                              <Form.Group
                                className="confirm-address-field"
                                controlId="formBasicPassword"
                              >
                                <Form.Label className="confirm-address-label">
                                  {t('Phone Number')}
                                </Form.Label>
                                <MaskedFormControl
                                  type='text'
                                  name='s_CommValue'
                                  mask="(111)111-1111"
                                  placeholder={t("Enter Phone Number")}
                                  title={t('Phone Number')}
                                  parentobject="personPhoneContact"
                                  value={phone}
                                  defaultValue={''}
                                  onChange={(e) => {
                                    props.handleChange(e);
                                    setPhone(e.currentTarget.value);
                                  }}
                                  className="confirm-address-input"
                                  required
                                />
                                <Form.Control.Feedback type="invalid">
                                  {t("Please Add Mobile Number Error")}
                                </Form.Control.Feedback>
                              </Form.Group>
                            </Col>
                            {localStorage.getItem('member_id') === 'Y' ?
                              <Col xs="6">
                                <Form.Group
                                  className="confirm-address-field"
                                  controlId="formBasicEmail"
                                >
                                  <Form.Label className="confirm-address-label">
                                    {t('Member')}#
                                  </Form.Label>
                                  <Form.Control
                                    className="confirm-address-input"
                                    type="text"
                                    placeholder={t("Enter Member")}
                                    title={t('Member')}
                                    value={memberNo}
                                    defaultValue={''}
                                    onChange={(e) => {
                                      handleMemborNoChange(e.currentTarget.value);
                                    }}
                                    required
                                  />
                                </Form.Group>
                                <Form.Control.Feedback type="invalid">
                                  {t("Please Add Member#")}
                                </Form.Control.Feedback>
                              </Col> : null}
                          </Row>
                        </Form>
                      </Col>
                    </Row>
                    <Row>
                      <Col
                        xs="12"
                        id="flood-contact-btns"
                        className="contact-form-btns"
                      >
                        <Validation currentStep={3} toggle={toggle} saveQuoteData={props.saveQuoteData} />
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>
          </section>
        )}
    </div>

  );
};

export default FloodContactInformation;