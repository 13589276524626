import { Box, Flex, HStack, Link, Text } from "@chakra-ui/react";
import React from "react";
import { useTranslation } from "react-i18next";

export default function QuickLinks() {
  const { t } = useTranslation();
  const linksData = [
    {
      title: "Fema.gov",
      link: "https://www.fema.gov",
    },
    {
      title: "Flood Smart",
      link: "https://www.floodsmart.gov",
    },
    {
      title: "Fema.gov Flood & Maps",
      link: "https://www.fema.gov/flood-insurance",
    },
    {
      title: "Flood Damage Cost Calculator",
      link: "https://www.floodsmart.gov/cost-flooding",
    },
    {
      title: "Flood Insurance Education & Resources",
      link: "https://edu.myflood.com/",
    },
  ];
  return (
    <Box
      bgColor={"white"}
      borderRadius={"2xl"}
      py={"0.85rem"}
      height={"100%"}
      width={"100%"}
      boxShadow={"lg"}
    >
      <Box px={'3px'}>
        <Text fontSize="20px" fontWeight={600} px={4} pl={3}>
          <HStack spacing="10px">
            <Box>
              <img src={"/assets/icons/linkIcon.svg"} alt="tag" width={25} height={25} />
            </Box>
            <Box>{t("quick_links")}</Box>
          </HStack>
        </Text>
        <Box my={3} borderTop={"2px solid"} borderColor={"gray.200"} />
        <Box mb={3}>
          <Flex sx={{ flexWrap: "wrap", gap: "10px" }} px={3}>
            {linksData.map((item, i) => {
              return (
                <Box>
                  <HStack>
                    <Link
                      key={i}
                      href={item.link}
                      isExternal
                      sx={{
                        textDecoration: "underline",
                        padding: "2.5px",
                        margin: "1px",
                        borderRadius: "5px",
                      }}
                      color="black"
                      fontWeight={500}
                      _hover={{
                        bg: "var(--createQuoteHeaderButton)",
                        color: "white",
                        px: 3,
                        transition: "all 0.2s ease-in-out",
                      }}
                    >
                      {item.title}
                    </Link>
                  </HStack>
                </Box>
              );
            })}
          </Flex>
        </Box>
      </Box>
    </Box>
  );
}
