import React, { useEffect, useState } from "react";
import {
  Alert,
  Box,
  Flex,
  ListItem,
  Select,
  Spacer,
  Switch,
  Text,
  UnorderedList,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { useStarDataContext } from "../../StarDataContext";
import MultiSelect from "../../utils/ChakraMultiSelect";

export default function UnderWritingQuestion() {
  const {
    baseDD,
    underWriting,
    setUnderWriting,
    selectedRisk,
    setSelectedRisk,
    lossHistoryData,
    errorMessages,
    setErrorMessages,
  } = useStarDataContext();
  const { t } = useTranslation("underwriting");
  const [underWriterQues, setUnderWriterQues] = useState([]);
  const [isBaseDataLoaded, setIsBaseDataLoaded] = useState(false);
  const [solarPanelLocation, setSolarPanelLocation] = useState([]);
  const [riskAssociated, setRiskAssociated] = useState([]);

  useEffect(() => {
    if (baseDD) {
      const sortedQuestions = (
        (Array.isArray(baseDD.GetQuestionForgeneralTab) &&
          baseDD.GetQuestionForgeneralTab.length > 0 &&
          baseDD.GetQuestionForgeneralTab) ||
        (Array.isArray(baseDD.GetQuestion) &&
          baseDD.GetQuestion.length > 0 &&
          baseDD.GetQuestion) ||
        []
      )
        .filter((ques) => ques.n_DisplaySequenceNo)
        .sort(
          (a, b) =>
            parseFloat(a.n_DisplaySequenceNo) -
            parseFloat(b.n_DisplaySequenceNo)
        );
      setUnderWriterQues(sortedQuestions);
      setSolarPanelLocation(baseDD.STARSOLARPANELLOCATION || []);
      setRiskAssociated(baseDD.STAROTHERRISKASSOCIATED || []);
      setIsBaseDataLoaded(true);
    }
  }, [baseDD]);

  useEffect(() => {
    const hasAnimalClaim =
      Array.isArray(lossHistoryData) && lossHistoryData.length > 0;
    const hasAnimals = underWriting.underWritingQuestions.hasAnimals;

    setErrorMessages((prev) => {
      const newErrorMessages = { ...prev };

      if (hasAnimals && hasAnimalClaim) {
        newErrorMessages.hasAnimals = t("hasAnimals");
      } else {
        delete newErrorMessages.hasAnimals;
      }

      return newErrorMessages;
    });
  }, [lossHistoryData, underWriting]);

  const handleSwitchChange = (questionKey) => {
    const newValue = !underWriting.underWritingQuestions[questionKey];
    setUnderWriting((prevState) => ({
      ...prevState,
      underWritingQuestions: {
        ...prevState.underWritingQuestions,
        [questionKey]: newValue,
      },
    }));

    const newErrorMessages = { ...errorMessages };
    if (newValue) {
      newErrorMessages[questionKey] = t(questionKey);
    } else {
      delete newErrorMessages[questionKey];
    }

    setErrorMessages(newErrorMessages);
  };

  const renderQuestion = (ques) => {
    const { n_PRPolicyAddStatQuestions_Pk, s_PRQuestionDesc, poolManagerKey } =
      ques;
    const commonProps = {
      px: 4,
      alignItems: "center",
      pt: 5,
      pb: 2,
      style: { marginBottom: "-10px" },
    };

    const renderErrorMessage = (key) =>
      errorMessages[key] && (
        <Box px={3} w={"90%"}>
          <Alert
            status="error"
            borderRadius={"xl"}
            color={"red"}
            p={1.5}
            bg={"transparent"}
            fontSize={"md"}
          >
            {errorMessages[key]}
          </Alert>
        </Box>
      );

    switch (n_PRPolicyAddStatQuestions_Pk) {
      case 13:
        return (
          underWriting?.underWritingQuestions?.hasSolarPanels && (
            <Flex
              px={4}
              alignItems="center"
              pt={3}
              style={{ marginBottom: "-10px" }}
            >
              <UnorderedList pl={3}>
                <ListItem color={"neutral.500"}>{s_PRQuestionDesc}</ListItem>
              </UnorderedList>
              <Spacer />
              <Select
                w={"12%"}
                mb={2}
                value={underWriting?.underWritingQuestions?.solarPanelLocation}
                onChange={(e) =>
                  setUnderWriting((prevState) => ({
                    ...prevState,
                    underWritingQuestions: {
                      ...prevState.underWritingQuestions,
                      solarPanelLocation: e.target.value,
                    },
                  }))
                }
              >
                <option value=""></option>
                {solarPanelLocation.map((item, i) => (
                  <option key={i} value={item.s_AppCodeName}>
                    {item.s_AppCodeNameForDisplay}
                  </option>
                ))}
              </Select>
              {underWriting &&
                underWriting?.underWritingQuestions?.solarPanelLocation ===
                  "" && (
                  <Text pt={2} color="red">
                    Solar Panel Location is required
                  </Text>
                )}
            </Flex>
          )
        );
      case 5:
        return null;
      // return (
      //   underWriting?.underWritingQuestions?.hasSwimmingPool && (
      //     <Flex
      //       px={4}
      //       alignItems="center"
      //       pt={3}
      //       style={{ marginBottom: "-10px" }}
      //     >
      //       <UnorderedList pl={3}>
      //         <ListItem color={"neutral.500"}>{s_PRQuestionDesc}</ListItem>
      //       </UnorderedList>
      //       <Spacer />
      //       <Switch
      //         size="lg"
      //         pb={4}
      //         isChecked={underWriting.underWritingQuestions[poolManagerKey]}
      //         onChange={() => handleSwitchChange(poolManagerKey)}
      //       />
      //     </Flex>
      //   )
      // );
      case 10:
      case 12:
      case 14:
      case 16:
        return null;

      default:
        return (
          <Box>
            <Flex
              {...commonProps}
              justifyContent="space-between"
              textAlign="left"
            >
              <Text pr={5}>{s_PRQuestionDesc}</Text>
              <Spacer />
              <Switch
                size="lg"
                pb={4}
                isChecked={underWriting.underWritingQuestions[poolManagerKey]}
                onChange={() => handleSwitchChange(poolManagerKey)}
              />
            </Flex>
            {renderErrorMessage(poolManagerKey)}
          </Box>
        );
    }
  };

  return (
    <Box border="1px solid #E6E9ED" borderRadius="12px" mb={6} mt={7}>
      <Box
        mb={1}
        background="var(--chakra-colors-primary-50)"
        borderRadius={"xl"}
        p={"0.5rem"}
      >
        <Text
          mt={3}
          pl="1.5rem"
          fontWeight="var(--chakra-fontWeights-semibold)"
          color="black"
          fontSize="1.5rem"
        >
          {t("Underwriting Questions")}
        </Text>
      </Box>
      {underWriterQues.length && isBaseDataLoaded ? (
        underWriterQues.map((ques) => (
          <Box
            pl={2}
            key={ques.n_PRPolicyAddStatQuestions_Pk}
            fontSize={"17.5px"}
          >
            {renderQuestion(ques)}
            {(!underWriting?.underWritingQuestions?.hasSolarPanels &&
              ques.n_PRPolicyAddStatQuestions_Pk === 13) ||
            (!underWriting?.underWritingQuestions?.hasSwimmingPool &&
              ques.n_PRPolicyAddStatQuestions_Pk === 5) ||
            ques.n_PRPolicyAddStatQuestions_Pk === 14 ||
            ques.n_PRPolicyAddStatQuestions_Pk === 16 ||
            ques.n_PRPolicyAddStatQuestions_Pk === 5 ||
            (!underWriting?.underWritingQuestions?.additionalRisks &&
              ques.n_PRPolicyAddStatQuestions_Pk === 11) ||
            ques.n_PRPolicyAddStatQuestions_Pk === 10 ||
            ques.n_PRPolicyAddStatQuestions_Pk === 12 ? null : (
              <Box my={4} borderTop={"1px solid"} borderColor={"gray.200"} />
            )}
          </Box>
        ))
      ) : (
        <Text>No underwriting questions found.</Text>
      )}
      {!underWriting?.underWritingQuestions?.additionalRisks && <Box my={3} />}
      {underWriting?.underWritingQuestions?.additionalRisks &&
        isBaseDataLoaded && (
          <Flex
            px={4}
            alignItems="center"
            justifyContent={"space-between"}
            py={4}
          >
            <UnorderedList pl={3}>
              <ListItem color={"neutral.500"}>
                Please Select Additional Risks (Multiple Allowed)
              </ListItem>
            </UnorderedList>
            <MultiSelect
              options={riskAssociated.map((item) => ({
                label: item.s_AppCodeNameForDisplay,
                value: item.s_AppCodeName,
              }))}
              setSelected={setSelectedRisk}
              selected={selectedRisk}
              placeholder="Select Additional Risks"
            />
          </Flex>
        )}
    </Box>
  );
}
