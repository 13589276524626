import React, { useEffect, useState } from 'react';
import { Row, Col, Form } from "react-bootstrap";
import DatePicker from "react-datepicker";
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { getEditEmptyQuoteData } from '../../../../services/floodService';
import Moment from 'moment';
import "react-datepicker/dist/react-datepicker.css";

const NewlyMapDiscount = (props) => {
    const { emptyQuoteData, floodSystemDD, handleChange, handleDateChange, checkDateisValid, isCurrMapDateGreater, isPropNewlyMapped, validated, isBind } = props;
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [communityNum, setCommunityNum] = useState('');
    const [panelNumber, setPanelNumber] = useState('');
    const [mapSuffix, setMapSuffix] = useState('');
    const [floodZone, setFloodZone] = useState('');
    const [mapDate, setMapDate] = useState('');
    const [PBE, setPBE] = useState('');
    const [isNewlyMapped, setIsNewlyMapped] = useState('');
    const [SFAI, setSFAI] = useState('');
    const [BFEVDT, setBFEVDT] = useState('');

    // set state
    useEffect(() => {
        if (Object.keys(emptyQuoteData).length > 0) {
            setIsNewlyMapped(emptyQuoteData.propertyInfo.s_IsPropNewlyMapped || '');
            setCommunityNum(emptyQuoteData.propertyInfo.s_PriorCommunityNumber || '');
            setPanelNumber(emptyQuoteData.propertyInfo.s_PriorPanelNumber || '');
            setMapSuffix(emptyQuoteData.propertyInfo.s_PriorMapSuffix || '');
            setFloodZone(emptyQuoteData.propertyInfo.s_PriorFloodZone || '');

            if (checkDateisValid(emptyQuoteData.propertyInfo.d_PriorMapDate)) {
                setMapDate(new Date(Moment(emptyQuoteData.propertyInfo.d_PriorMapDate)));
            }
            setPBE(emptyQuoteData.propertyInfo.s_PriorBaseElevation || '');
            setIsNewlyMapped(emptyQuoteData.propertyInfo.s_IsPropNewlyMapped || '');
            setSFAI(emptyQuoteData.propertyInfo.S_CommunitySFHA || '');
            // EC Data
            setBFEVDT(emptyQuoteData.propertyInfo.s_ElevationDatum || '');
        }
    }, [emptyQuoteData])

    useEffect(() => {
        if (![null, ""].includes(panelNumber) && panelNumber.length > 4) {
            setPanelNumber(panelNumber.substring(0, 4));
        }
    }, [panelNumber])

    // To set newly mapped value based on current map date > effective Date
    useEffect(() => {
        setIsNewlyMapped(isCurrMapDateGreater ? 'NO' : 'YES');
    }, [isCurrMapDateGreater])

    const validatePanelNumber = () => {
        if (![null, ""].includes(panelNumber) && panelNumber.length !== 4) {
            toast.error(t('Panel Number should be of 4 Numbers'));
        }
    }

    const validateSuffix = () => {
        if (![null, ""].includes(mapSuffix) && mapSuffix.length !== 1) {
            setMapSuffix(mapSuffix.substring(0, 1));
        }
    }

    // update state in reducer
    useEffect(() => {
        var emptyQuote = emptyQuoteData;
        if (emptyQuoteData.propertyInfo) {
            emptyQuote['propertyInfo']['s_ElevationDatum'] = BFEVDT;
            emptyQuote['propertyInfo']['S_CommunitySFHA'] = SFAI;
        }
        if (isNewlyMapped === 'NO') {
            emptyQuote['propertyInfo'] = clearPriorCommunityDetails();
            clearCurrentState();
        }
        dispatch(getEditEmptyQuoteData(emptyQuote));
    }, [BFEVDT, SFAI, isNewlyMapped])

    // update state in reducer
    useEffect(() => {
        if (isNewlyMapped && isPropNewlyMapped === false) {
            var emptyQuote = emptyQuoteData;
            if (emptyQuoteData.propertyInfo) {
                emptyQuote['propertyInfo']['s_ElevationDatum'] = null;
                emptyQuote['propertyInfo']['S_CommunitySFHA'] = null;
                setSFAI(null);
                setBFEVDT(null);
            }
            emptyQuote['propertyInfo'] = clearPriorCommunityDetails();
            clearCurrentState();
            setIsNewlyMapped('NO');
            
            dispatch(getEditEmptyQuoteData(emptyQuote));
        }
    }, [isPropNewlyMapped])

    const clearCurrentState = () => {
        setCommunityNum(null);
        setPanelNumber(null);
        setMapSuffix(null);
        setFloodZone(null);
        setMapDate(null);
        setPBE(null);
    }

    const clearPriorCommunityDetails = () => {
        let priorCommunity = {
            ...emptyQuoteData['propertyInfo'],
            's_IsPropNewlyMapped': null,
            's_PriorCommunityNumber': null,
            's_PriorPanelNumber': null,
            's_PriorMapSuffix': null,
            'd_PriorMapDate': null,
            's_PriorFloodZone': null,
            's_PriorBaseElevation': null
        }
        return priorCommunity;
    }

    return (
        <Form validated={validated}>
            <Row>
                <Col xs="12" sm="12" md="6" lg="2" xl="2">
                    <Form.Group
                        className="search-quote-form-group"
                        controlId="formBasicEmail"
                    >
                        <Form.Label className="dashboard-group-label">
                            {t('Has the property been re-mapped from a non-sfha into a sfha within the last year')}?
                        </Form.Label>
                        <Form.Select
                            className="table-show-entries-select"
                            name="s_IsPropNewlyMapped"
                            title={t('Is Newly Mapped')}
                            parentobject="propertyInfo"
                            value={isNewlyMapped}
                            onChange={(e) => {
                                setIsNewlyMapped(e.currentTarget.value);
                                handleChange(e);
                            }}
                            disabled={isBind}
                        >
                            <option value={''}>{t('Select')}</option>
                            {
                                Object.keys(floodSystemDD).length > 0 && floodSystemDD.YESNOONLY.map((v, i) => {
                                    return <option key={i} value={v.s_AppCodeName}>{t(v.s_AppCodeNameForDisplay)}</option>
                                })
                            }
                        </Form.Select>
                    </Form.Group>
                </Col>
                {
                    isNewlyMapped === 'YES' && (
                        <>
                            <Col xs="12" sm="12" md="6" lg="2" xl="2">
                                <Form.Group
                                    className="search-quote-form-group"
                                    controlId="formBasicEmail"
                                >
                                    <Form.Label className="dashboard-group-label">
                                        {t("Prior Comunity Number")}
                                    </Form.Label>
                                    <Form.Control
                                        className="dashboard-group-input-flood"
                                        type="text"
                                        placeholder={t("Prior Comunity Number")}
                                        name="s_PriorCommunityNumber"
                                        title={t('Prior Comunity Number')}
                                        parentobject="propertyInfo"
                                        value={communityNum}
                                        onChange={(e) => {
                                            handleChange(e);
                                            setCommunityNum(e.currentTarget.value);
                                        }}
                                        autoComplete="off"
                                        disabled={isBind}
                                    />
                                </Form.Group>
                            </Col>
                            <Col xs="12" sm="12" md="6" lg="2" xl="2">
                                <Form.Group
                                    className="search-quote-form-group"
                                    controlId="formBasicEmail"
                                >
                                    <Form.Label className="dashboard-group-label">
                                        {t('Prior Panel Number')}
                                    </Form.Label>
                                    <Form.Control
                                        className="dashboard-group-input-flood"
                                        type="text"
                                        placeholder={t("Prior Panel Number")}
                                        name="s_PriorPanelNumber"
                                        title={t('Prior Panel Number')}
                                        parentobject="propertyInfo"
                                        value={panelNumber}
                                        onChange={(e) => {
                                            handleChange(e);
                                            setPanelNumber(e.currentTarget.value);
                                        }}
                                        onBlur={() => validatePanelNumber()}
                                        autoComplete="off"
                                        disabled={isBind}
                                    />
                                </Form.Group>
                            </Col>
                            <Col xs="12" sm="12" md="6" lg="2" xl="2">
                                <Form.Group
                                    className="search-quote-form-group"
                                    controlId="formBasicEmail"
                                >
                                    <Form.Label className="dashboard-group-label">
                                        {t('Prior Map Suffix')}
                                    </Form.Label>
                                    <Form.Control
                                        className="dashboard-group-input-flood"
                                        type="text"
                                        placeholder={t("Prior Map Suffix")}
                                        name="s_PriorMapSuffix"
                                        title={t('Prior Map Suffix')}
                                        parentobject="propertyInfo"
                                        value={mapSuffix}
                                        onChange={(e) => {
                                            handleChange(e);
                                            setMapSuffix(e.currentTarget.value);
                                        }}
                                        onBlur={() => validateSuffix()}
                                        autoComplete="off"
                                        disabled={isBind}
                                    />
                                </Form.Group>
                            </Col>
                            <Col xs="12" sm="12" md="6" lg="2" xl="2">
                                <Form.Group
                                    className="search-quote-form-group"
                                    controlId="formBasicEmail"
                                >
                                    <Form.Label className="dashboard-group-label">
                                        {t('Prior Flood Zone')}
                                    </Form.Label>
                                    <Form.Select
                                        className="table-show-entries-select"
                                        name="s_PriorFloodZone"
                                        title={t('Prior Flood Zone')}
                                        parentobject="propertyInfo"
                                        value={floodZone}
                                        onChange={(e) => {
                                            setFloodZone(e.currentTarget.value);
                                            handleChange(e);
                                        }}
                                        disabled={isBind}
                                    >
                                        <option value={''}>{t('Select')}</option>
                                        {
                                            Object.keys(floodSystemDD).length > 0 && floodSystemDD.FoodZones.map((v, i) => {
                                                return <option key={i} value={v.s_FloodZone}>{t(v.s_FloodZone)}</option>
                                            })
                                        }
                                    </Form.Select>
                                </Form.Group>
                            </Col>
                            <Col xs="12" sm="12" md="6" lg="2" xl="2">
                                <Form.Group
                                    className="search-quote-form-group"
                                    controlId="formBasicEmail"
                                >
                                    <Form.Label className="dashboard-group-label">
                                        {t("Prior Map Date")}
                                    </Form.Label>
                                    <DatePicker
                                        id="dashboard-datepicker"
                                        className="dashboard-datepicker-main"
                                        placeholderText="MM/DD/YYYY"
                                        selected={mapDate}
                                        onChange={(date) => {
                                            setMapDate(date);
                                            handleDateChange(date, 'd_PriorMapDate', 'propertyInfo');
                                        }}
                                        autoComplete="off"
                                        disabled={isBind}
                                    />
                                </Form.Group>
                            </Col>
                            <Col xs="12" sm="12" md="6" lg="2" xl="2">
                                <Form.Group
                                    className="search-quote-form-group"
                                    controlId="formBasicEmail"
                                >
                                    <Form.Label className="dashboard-group-label">
                                        {t('Prior Base Flood Elevation/Depth (in feet)')}:
                                    </Form.Label>
                                    <Form.Control
                                        className="dashboard-group-input-flood"
                                        type="number"
                                        placeholder="00"
                                        name="s_PriorBaseElevation"
                                        title={t('in feet')}
                                        parentobject="propertyInfo"
                                        value={PBE}
                                        min={0}
                                        onChange={(e) => {
                                            handleChange(e);
                                            setPBE(e.currentTarget.value);
                                        }}
                                        disabled={isBind}
                                    />
                                </Form.Group>
                            </Col>
                        </>
                    )
                }

            </Row>
        </Form>
    )
}

export default NewlyMapDiscount;