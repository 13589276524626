import React, { useCallback, useState } from 'react';
import { toast } from 'react-toastify';
import { Button } from "react-bootstrap";
import { useDispatch } from 'react-redux';
import { useDropzone } from "react-dropzone";
import { useTranslation } from 'react-i18next';
import { FaFileUpload, FaPlus, FaWindowClose, FaTimes, FaUpload } from 'react-icons/fa';
import { uploadCancelDocument } from '../../../services/floodService';

const AddAttachment = (props) => {
    const { loading, setLoading, policyNumber, policyPK, cancelJSON, handleOnAttachmentClose, setDocData, docData, setCancelJSON } = props;

    const { t } = useTranslation();
    const dispatch = useDispatch();

    const [selectedFiles, setSelectedFiles] = useState([]);
    const [isFileSelected, setIsFileSelected] = useState(true);

    // on drop file
    const onDrop = useCallback(event => {
        setSelectedFiles(event[0]);
    }, [])

    const { acceptedFiles, getRootProps, getInputProps } = useDropzone({ onDrop, multiple: false });

    // display file name 
    var files = acceptedFiles.map((file, i) => (
        <li key={file.path}>
            {file.name} &nbsp;&nbsp;<FaTimes size={15} onClick={() => removeFile(i)} />
        </li>
    ));

    // to delete file
    const removeFile = (file) => {
        const newFiles = [...files];
        newFiles.splice(file, 1);
        acceptedFiles.splice(file, 1);
        setSelectedFiles(newFiles);
    };

    // reset state
    const resetAttachmentState = () => {
        setDocData({});
        setSelectedFiles([]);
        acceptedFiles.length = 0;
        acceptedFiles.splice(0, acceptedFiles.length);
    };

    // validation
    const validate = () => {
        var isValid = true;
        if (!selectedFiles || selectedFiles.length === 0) {
            toast.error(t('Please Select File'))
            isValid = false;
            setIsFileSelected(false);
        }
        return isValid;
    };

    // submit form
    const handleSubmit = async () => {
        const isValid = validate();
        if (isValid) {
            const formData = new FormData();
            formData.append("file", selectedFiles, selectedFiles.name);
            formData.append("docScreenName", docData.docScreenName);
            formData.append("docType", docData.docType);
            formData.append("policyNumber", policyNumber);
            formData.append("policyPK", policyPK);
            formData.append("cancelJSON", JSON.stringify(cancelJSON));

            setLoading(true);
            await dispatch(uploadCancelDocument(formData)).then((res) => {
                setLoading(false);
                if (res.status === 'Y') {
                    if (res.data && res.data.hasOwnProperty("metadata")) {
                        var tempJSON = { ...res.data.metadata };
                        tempJSON['preCancelQuoteId'] = res.data.preCancelQuoteId;
                        setCancelJSON(tempJSON);
                    }
                    resetAttachmentState();
                    toast.success(res.msg);
                    handleOnAttachmentClose(false);
                } else {
                    toast.error(t('Something Went Wrong!'));
                }
            }).catch(() => {
                setLoading(false);
                toast.error(t('Something Went Wrong!'));
            });
        }
    };

    return (
        <div className='interview-questions-div'>
            <span className="card-header-main-flood">
                <Button
                    id="common-outline-btn-flood"
                    variant="outline-primary"
                    style={{ float: 'right' }}
                    onClick={() => handleOnAttachmentClose(false)}
                    disabled={loading}
                >
                    {t('Close')} <FaWindowClose size={20} />
                </Button>
            </span>
            <div
                id="drop-document-type-div"
                {...getRootProps({ className: "dropzone" })}
            >
                <input {...getInputProps()} />
                <FaFileUpload size={34} color={"#64D6CD"} />
                <p>{t('Drag and Drop File Here')}</p>
                <Button id="common-btn" variant="primary">
                    <FaPlus size={15} />
                    {t('Add File')}
                </Button>{" "}
            </div>

            <aside className="reply-document-name">
                <ul>{files}</ul>
            </aside>
            {
                !isFileSelected && (
                    <aside className="reply-document-name" style={{ color: 'red' }}>
                        <ul>{t('Please Select File')}</ul>
                    </aside>
                )
            }
            <div className="card-header-main-flood">
                <Button
                    id="common-outline-btn-flood"
                    variant="outline-primary"
                    onClick={() => handleSubmit()}
                    disabled={loading}
                >
                    <FaUpload size={15} />{" "}
                    {t('Upload')}
                </Button>{" "}
            </div>
        </div>
    )
}

export default AddAttachment;
