import axios from 'axios';
import store from './store';
import * as actions from './store/actions';

const token = localStorage.getItem('access_token') || '';

axios.defaults.baseURL = process.env.REACT_APP_API_URL;
axios.defaults.headers.common.Authorization = `Bearer ${token}`;
axios.defaults.headers.common["X-Tenant"] = window.location.host.split('.')[0];

// request interceptor - get updated token for each request
axios.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("access_token") || '';
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
	  
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  response => response,
  (error) => {
    if (error.response && error.response.status === 401) {
      store.dispatch(actions.authLogout());
      window.location = "/login";
    }
    return Promise.reject(error);
  },
);

export default axios;
