import Http from '../Http';
import * as action from '../store/actions/index'
const producerServiceUrl = process.env.REACT_APP_PRODUCER_API_URL;
const coreServiceUrl = process.env.REACT_APP_CORE_API_URL;

export function createAchConfig(params) {
    return new Promise((resolve, reject) => {
        
        Http.post('/api/ach-config/create',params)
          .then((res) => {
            return resolve(res);
          }).catch((err) => {
              return reject(err.response.data);
          });
      })
    
  }
export function listAchConfig(personId) {
    return new Promise((resolve, reject) => {
        
        Http.get('/api/ach-config/list/'+personId)
          .then((res) => {
            return resolve(res);
          }).catch((err) => {
            if (err.response) {
              return reject(err.response);
            }
            
          });
      })
    
  }

export function getAchHelp() {
  return new Promise((resolve, reject) => {
    Http.get('/api/ach-config/getAchHelperData')
      .then((res) => {
        return resolve(res);
      }).catch((err) => {
        if (err.response) {
          return reject(err.response);
        }
      });
  })
  
}
export function updateSignStatus(id,status) {
  return new Promise((resolve, reject) => {
    Http.post('/api/ach-config/updateStatus/'+id+'/'+status)
      .then((res) => {
        return resolve(res);
      }).catch((err) => {
        if (err.response) {
          return reject(err.response);
        }
      });
  })
}

export function isAchConfigAvailable() {
  return new Promise((resolve, reject) => {
    Http.get('/api/ach-config/isAchConfigAvailable')
      .then((res) => {
        return resolve(res.data);
      }).catch((err) => {
        if (err.response) {
          return reject(err.response);
        }
      });
  })
  
}
//add ach payment log
export function submitAchPayment(payload) {
  return new Promise((resolve, reject) => {
    Http.post('/api/ach-config/submitAchPayment',payload)
      .then((res) => {
        return resolve(res.data);
      }).catch((err) => {
        if (err.response) {
          return reject(err.response);
        }
      });
  })
  
}

export function getProducerData(producerId) {
  let url = producerServiceUrl + '/api/v1/agency/' + producerId;
  return dispatch => (
    new Promise((resolve, reject) => { 
      
        Http.get(url).then(res => {
            if (res.status === 200) {
              dispatch(action.getProducerData(res.data.data)); 
              
              resolve(res.data.data);
            }else {
                reject(res)
                
            }
        })
    })
  );  
}

export function getBrandedCompanies(){
  let url = coreServiceUrl + '/api/v1/brandedCompany/list';
  return dispatch => (
    new Promise((resolve, reject) => { 
      
        Http.get(url).then(res => {
            if (res.data.status === true) {
              resolve(res.data);
            }else {
                reject(res)
                
            }
        })
    })
  );  
}

export function searchAgency(data){
  let url = producerServiceUrl + '/api/v1/agency/1/searchAgency';
  return dispatch => (
    new Promise((resolve, reject) => { 
      
        Http.post(url,data).then(res => {
            if (res.status === 200) {
              resolve(res.data);
            }else {
                reject(res)
                
            }
        })
    })
  );  
}

export function searchAgencyUsers(param){
  let url = producerServiceUrl + '/api/v1/searchAgent'+param;
  return dispatch => (
    new Promise((resolve, reject) => { 
      
        Http.get(url).then(res => {
            if (res.data.status === 200) {
              resolve(res.data);
            }else {
                reject(res)
                
            }
        })
    })
  );  
}

export function getAgencyUsers(AgencyPersonPk){
  let url = producerServiceUrl + '/api/v1/agency/' + AgencyPersonPk + '/showAUsers';
  return dispatch => (
    new Promise((resolve, reject) => { 
      
        Http.get(url).then(res => {
            if (res.data.status === 200) {
              resolve(res.data);
            }else {
                reject(res)
                
            }
        })
    })
  );  
}

export function getAUsersDetails(AgencyPersonPk,agencyUserPersonPk){
  let url = producerServiceUrl + '/api/v1/agency/' + AgencyPersonPk + '/showAUserDetails/'+ agencyUserPersonPk;
  return dispatch => (
    new Promise((resolve, reject) => { 
        Http.get(url).then(res => {
            if (res.data.status === 200) { 
              resolve(res.data);
            }else {
                reject(res)
            }
        })
    })
  );  
}

export function loadCeLicenseDocumentDataForProducerView(params) {
  return new Promise((resolve, reject) => {

      Http.get(
          `${coreServiceUrl}/api/v1/getCeLicenceDocumentList?Params=` +
          JSON.stringify(params)
      )
          .then((res) => {
              return resolve(res.data);
          })
          .catch((err) => {
              const { status, errors } = err.response.data;
              return reject(false);
          });
  });
}

export function getViewDocument(DocInfo_PK, Sources,  module = 'NA') {
  const url = `${coreServiceUrl}/api/v1/generateTempurl/` + DocInfo_PK + '/' + Sources + '/' + module;
  return new Promise((resolve, reject) => {
    Http.get(url)
      .then(res => {
        if (res.status === 200) {
          resolve(res);
        } else {
          reject(res)
        }
      })
  })
}
