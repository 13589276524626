import { useEffect } from "react";
import { CreateQuoteContext, useMyContext } from "../../CreateQuote";

const useUpdateConstructionDetails = () => {
  const {
    autoGenMapData,
    constructionDetails,
    setConstructionDetails,
    stepLock,
    setStepLock,
  } = useMyContext(CreateQuoteContext);
  useEffect(() => {
    if (!autoGenMapData || stepLock) {
      return;
    }

    const hiscoxConstructionTypeKeys = {
      FRAME20: "Frame",
      MASONRY20: "Masonry",
      OTHER20: "Frame", // other does not exist in hiscox, so generic frame is used
      BRICK20: "BrickVeneer",
      LOG20: "Log",
      STUCCO20: "Stucco",
      BRICK: "BrickVeneer",
      FRAME: "Frame",
      LOG: "Log",
      MASONRY: "Masonry",
      OTHER: "Frame",
      STUCCO: "Stucco",
      ASBESTOS: "Asbestos",
      EIFS: "EIFS",
    };

    const hiscoxUsageKeys = {
      PRIMARY: "Primary",
      SEASONAL: "Seasonal",
      TENANT: "Tenanted",
      RENT: "Rent",
      UNDERCONST: "Coc",
      VACATION: "Vacation",
      SECONDARY: "Secondary",
    };

    const hiscoxFoundationTypeKeys = {
      null: "SlabOnGrade",
      SLAB: "SlabOnGrade",
      PIER: "PiersPostsPilings",
      SHEAR_WALL: "ReinforcedShearWalls",
      SOLID_WALL: "SolidFoundationWalls",
      FOUNDATION_WALL: "FoundationWall",
      SLAB_FILL: "SlabOnFill",
      BLOCK: "SolidFoundationWalls",
      RAISED: "FoundationWall",
    };

    const waterTypeMapping = {
      null: "No",
      PRIVATE: "No",
      YES: "Entirely",
      ENTIRE: "Entirely",
      NO: "No",
      NONE: "No",
      PARTIAL: "Partially",
      PART: "Partially",
    };

    function getNumberOfStories(stories) {
      try {
        const numberPart = stories.match(/\b\d+\b/);
        return numberPart ? Number(numberPart[0]) : 1;
      } catch (error) {
        console.error("Error occurred while parsing the number:", error);
        return 1;
      }
    }

    setConstructionDetails({
      ...constructionDetails,
      noOfStories: getNumberOfStories(autoGenMapData?.PropDetails?.NO_STORIES), // At least 1 is needed for the api,
      yearBuilt: autoGenMapData?.PropDetails?.ACT_YR_BLT
        ? Number(autoGenMapData?.PropDetails?.ACT_YR_BLT)
        : "",
      squareFootage: autoGenMapData?.PropDetails?.TOT_LVG_AR || "",
      constructionType:
        hiscoxConstructionTypeKeys[
          autoGenMapData?.EastedData?.data?.structure?.construction_type
        ] ||
        hiscoxConstructionTypeKeys[autoGenMapData?.ConstructionType] ||
        "",
      occupancyType:
        constructionDetails?.productType === "Residential"
          ? hiscoxUsageKeys[autoGenMapData?.Usage] || ""
          : "",

      foundationType:
        hiscoxFoundationTypeKeys[
          autoGenMapData?.EastedData?.data?.structure?.foundation_type
        ] || "",

      buildingWaterType:
        waterTypeMapping[
          autoGenMapData?.EastedData?.data?.structure?.water_type
        ] || "",
    });
    setStepLock(true);
  }, [autoGenMapData]);
};

export default useUpdateConstructionDetails;
