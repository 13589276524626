import { Center, Grid, GridItem, Text, Image } from "@chakra-ui/react";
import { useParams } from "react-router";
import { useStarDataContext } from "../../StarDataContext";
import { useTranslation } from "react-i18next";
export default function Card() {
  const {
    policy,
    setPolicy,
    setValidationError,
    validationError,
    cardSelected,
    setCardSelected,
  } = useStarDataContext();
  const { lang } = useParams();
  const { t } = useTranslation(lang, "quote");
  const datas = [
    {
      title: t("Home"),
      description: "1-4 Residential Occupancies",
      icon: "/starImages/HomeProperty.svg",
      value: "Single Family",
    },
    {
      title: t("Duplex"),
      icon: "/starImages/UnitProperty.svg",
      description: "Residential or Non-Residential",
      value: "Duplex",
    },
    {
      title: "Apartment / Condo",
      icon: "/starImages/ResidentialBuilding.svg",
      description: "Residential or Non-Residential",
      value: "Apartment / Condo",
    },
    {
      title: "Other",
      icon: "/starImages/mobile-home.svg",
      description: "Residential or Non-Residential",
      value: "Other",
    },
  ];
  const handleCard = (value, index) => {
    if (value === "Single Family") {
      setCardSelected(index);
      setPolicy({
        ...policy,
        propertyType: value,
      });
      setValidationError({
        ...validationError,
        propertyType: true,
      });
    }
  };
  return (
    <>
      <Grid
        ml={3}
        templateColumns={{
          base: "1fr",
          md: "repeat(4, 1fr)",
        }}
        gap={10}
        p={6}
      >
        {datas.map(({ title, icon, description, value }, index) => (
          <GridItem
            border={
              cardSelected === index
                ? "1px solid var(--chakra-colors-primary-800)"
                : "1px solid var(--chakra-colors-neutral-100)"
            }
            onClick={() => handleCard(value, index)}
            _hover={
              value === "Single Family"
                ? {
                    boxShadow:
                      "0px 4px 12px 0px var(--chakra-colors-neutral-50)",
                    border: "1px solid",
                    borderColor: "var(--chakra-colors-secondary-500)",
                  }
                : ""
            }
            key={index}
            w="90%"
            h="100%"
            bg="white"
            boxShadow="0px 4px 12px 0px var(--chakra-colors-neutral-50)"
            borderRadius="12px"
            opacity={value === "Single Family" ? 1 : 0.5}
          >
            <Center pt="20px">
              <Image blendMode={"multiply"} src={icon} height="145px" />
            </Center>
            <Center>
              <Text>{title}</Text>
            </Center>
            <Center>
              <Text fontSize="13px" color="var(--chakra-colors-neutral-500)">
                {description}
              </Text>
            </Center>
          </GridItem>
        ))}
      </Grid>
    </>
  );
}
