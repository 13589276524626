import { Box, Flex, FormControl, FormLabel } from "@chakra-ui/react";
import Card from "./Card";
import { useTranslation } from "react-i18next";
import { useStarDataContext } from "../../StarDataContext";
import { useEffect, useState } from "react";
import DateInput from "../../utils/DateInput";
import moment from "moment";
import GoogleMaps from "../../utils/GoogleMaps";

export default function Policy() {
  const { policy, setPolicy, setValidationError, validationError, location } =
    useStarDataContext();
  const { t } = useTranslation();
  const today = new Date();
  const todayMinDate = moment().toDate();
  const thirtyDaysLater = new Date(today.getTime() + 30 * 24 * 60 * 60 * 1000);
  const [date, setDate] = useState(formatDate(thirtyDaysLater));
  const { latitude, longitude } = location;
  useEffect(() => {
    setPolicy({ ...policy, effectiveDate: date });
    setValidationError({ ...validationError, effectiveDate: true });
  }, [date]);
  const isNotNullEmptyUndefined = (value) => {
    return (
      value !== null &&
      value !== "" &&
      value !== undefined &&
      !isNaN(value) &&
      isFinite(value)
    );
  };
  return (
    <>
      <Card />
      {latitude &&
        longitude &&
        isNotNullEmptyUndefined(latitude) &&
        isNotNullEmptyUndefined(longitude) && (
          <Flex
            justifyContent={"center"}
            mt={2}
            p={"4px"}
            border={"2px solid"}
            borderColor={"primary.50"}
            borderRadius={"md"}
            bg={"white"}
            boxShadow={"md"}
            overflow={"hidden"}
          >
            <Box w={"100%"}>
              <GoogleMaps
                lat={Number(latitude) || 0}
                lng={Number(longitude) || 0}
              />
            </Box>
          </Flex>
        )}

      <Flex py={"2rem"} justifyContent={"center"} alignItems={"center"}>
        <FormControl isRequired w={"25%"}>
          <FormLabel>{t("Effective Date")}:</FormLabel>
          <DateInput
            minDate={todayMinDate}
            value={date}
            styleProps={{
              border: "2px solid",
              borderColor: "gray.400",
              borderRadius: "xl",
              size: "lg",
              readOnly: false,
            }}
            onChange={(e) => {
              setDate(e);
            }}
          />
        </FormControl>
      </Flex>
      <Box py={2} borderTop={"1px solid"} borderColor={"gray.200"} />
    </>
  );
}

function formatDate(date) {
  return moment(date).format("MM/DD/YYYY");
}
