import {
  Box,
  Button,
  Card,
  CardBody,
  Flex,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  Image,
  Input,
  Select,
  Text,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import GooglePlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-google-places-autocomplete";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  propertyDataAutocompleteStore,
  getAgentAuthorizeState,
} from "../../../services/floodService";
import StarImage from "./starInsurance.jpg";
import ChakraLoader from "../create-star-quote/utils/ChakraLoader";
import { toast } from "react-toastify";
import { useAutoZipSelection } from "../create-star-quote/utils/useAutoZipDetection";
import LocationSelectionModal from "./LocationSelectionModal";
import { EncryptOrDecrypt } from "../../../services/commonService";
import { useTranslation } from "react-i18next";
import states from "./States";

let updatedAutocomplete = "";

// @TheEinshine
// We will launch this based on product, if  /quote/'ProductName'/start
// ProductName Based page rendering to start a quotation
export default function QuoteStartLandingPage() {
  const { productID } = useParams();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const navigation = useNavigate();
  const [search, setSearch] = useState(null);
  const [manualEntry, setManualEntry] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [zipData, setZipData] = useState({});
  const userData = EncryptOrDecrypt(localStorage.getItem("user"), "D");
  const { coreSystem } = useSelector((state) => state);
  const { t } = useTranslation();
  const AccessRights = userData.accessRights
    ? userData.accessRights.levelName
    : "";
  const [selectedPropertyData, setSelectedPropertyData] = useState({
    street_number: "",
    street_name: "",
    city: "",
    CountyName: "",
    PostalCode: "",
    country: "US",
    latitude: null,
    longitude: null,
    value: "",
    autocomplete: "",
    state: "",
    s_PostalCodeSuffix: "",
    apt_Number: "",
    stateCode: "",
  });
  const { selectedLocation, showModal, isLoading } = useAutoZipSelection(
    selectedPropertyData.PostalCode,
    manualEntry
  );

  useEffect(() => {
    if (showModal) {
      setOpenModal(showModal);
    }
  }, [showModal]);

  useEffect(() => {
    if (typeof selectedLocation === "object" && selectedLocation !== null) {
      setZipData(selectedLocation);
    }
  }, [selectedLocation]);

  const onAutocompleteSubmit = async () => {
    try {
      setLoading(true);
      handlePropertyDataFetch().then(() => {
        agentAuthorizeState();
      });
    } catch (error) {
      console.error("An error occurred:", error);
    } finally {
      setLoading(false);
    }
  };

  async function agentAuthorizeState() {
    const userAccess = AccessRights;
    let params = {
      userPersonInfoFK: userData.n_PersonInfoId_FK,
      product: coreSystem?.applicationData?.n_ProductId_FK || "1",
      state: selectedPropertyData?.state,
    };
    if (userAccess !== "EMPLOYEE") {
      setLoading(true);
      await dispatch(getAgentAuthorizeState(params)).then((res) => {
        setLoading(false);
        if (res.userAuthorizedState === false) {
          toast.error(
            t("State Not Authorized To Agent!", {
              position: "top-center",
              toastId: "stateNotAuthorized",
            })
          );
        } else if (res.userAuthorizedState === true && search) {
          setLoading(false);
          navigation(`/quote/${productID}/create`);
        }
      });
    } else {
      if (search) navigation(`/quote/${productID}/create`);
    }
  }

  async function handlePropertyDataFetch() {
    dispatch(propertyDataAutocompleteStore(selectedPropertyData));
  }

  const onManualAddressClick = () => {
    setManualEntry(!manualEntry);
    setSelectedPropertyData({
      street_number: "",
      street_name: "",
      city: "",
      CountyName: "",
      PostalCode: "",
      country: "US",
      latitude: null,
      longitude: null,
      value: "",
      autocomplete: "",
      state: "",
      s_PostalCodeSuffix: "",
      apt_Number: "",
      stateCode: "",
    });
  };

  const onManualSearch = async () => {
    if (
      !selectedPropertyData.street_number ||
      !selectedPropertyData.street_name ||
      !selectedPropertyData.city ||
      !(selectedPropertyData.stateCode || selectedPropertyData.state) ||
      !selectedPropertyData.PostalCode
    ) {
      toast.error("Please fill all the required fields");
      return;
    }
    dispatch(propertyDataAutocompleteStore(selectedPropertyData));
    const userAccess = AccessRights;
    let params = {
      userPersonInfoFK: userData.n_PersonInfoId_FK,
      product: "1",
      state: selectedPropertyData?.stateCode,
    };
    if (userAccess !== "EMPLOYEE") {
      setLoading(true);
      await dispatch(getAgentAuthorizeState(params)).then((res) => {
        setLoading(false);
        if (res.userAuthorizedState === false) {
          toast.error(
            t("State Not Authorized To Agent!", {
              position: "top-center",
              toastId: "stateNotAuthorized",
            })
          );
        } else if (res.userAuthorizedState) {
          setLoading(false);
          navigation(`/quote/${productID}/create`);
        }
      });
    } else {
      navigation(`/quote/${productID}/create`);
    }
  };

  useEffect(() => {
    const getGeoCode = (description) => {
      geocodeByAddress(description).then((addr) => {
        const propData = { ...selectedPropertyData };
        addr &&
          addr[0].address_components.forEach((e) => {
            if (e.types.includes("postal_code")) {
              propData.PostalCode = e.short_name;
            } else if (e.types.includes("street_number")) {
              propData.street_number = e.short_name;
            } else if (e.types.includes("route")) {
              propData.street_name = e.short_name;
            } else if (e.types.includes("locality", "political")) {
              propData.city = e.short_name;
            } else if (e.types.includes("country", "political")) {
              propData.country = e.short_name;
            } else if (
              e.types.includes("administrative_area_level_2", "political")
            ) {
              propData.CountyName = e.short_name;
            } else if (
              e.types.includes("administrative_area_level_1", "political")
            ) {
              propData.state = e.short_name;
            } else if (e.types.includes("postal_code_suffix")) {
              // Require For Flood Product
              propData.zipPlus4 = e.short_name;
              propData.s_PostalCodeSuffix = e.short_name;
            } else if (e.types.includes("subpremise")) {
              propData.apt_Number = "#" + e.short_name;
            }
          });
        propData.autocomplete = addr[0].formatted_address;
        if (addr && addr[0]) {
          getLatLng(addr[0]).then(({ lat, lng }) => {
            propData.latitude = lat.toFixed(7);
            propData.longitude = lng.toFixed(7);
          });
        }
        setSelectedPropertyData(propData);
      });
    };
    if (search != null) {
      getGeoCode(search.value.description);
    }
  }, [search]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    const {
      street_number,
      street_name,
      city,
      CountyName,
      stateCode,
      PostalCode,
    } = selectedPropertyData;

    setSelectedPropertyData((prevData) => ({
      ...prevData,
      [name]: value,
    }));

    updatedAutocomplete = [
      street_number,
      street_name,
      city,
      CountyName,
      stateCode,
      PostalCode,
    ]
      .map((item, index) => {
        if (index === Object.keys(selectedPropertyData).indexOf(name)) {
          return value;
        }
        return item;
      })
      .join(" ");
  };

  useEffect(() => {
    if (zipData && manualEntry && zipData.s_CityName && zipData.s_StateCode) {
      const { s_CityName, s_StateCode, s_CountyName } = zipData;
      const state =
        states.find((state) => state.abbr === s_StateCode)?.name || "";

      setSelectedPropertyData((prevData) => ({
        ...prevData,
        city: s_CityName || "",
        stateCode: s_StateCode || "",
        state,
        CountyName: s_CountyName || "",
      }));
      handleChange({ target: { name: "city", value: s_CityName } });
      handleChange({ target: { name: "stateCode", value: s_StateCode } });
      handleChange({ target: { name: "CountyName", value: s_CountyName } });
    }
  }, [zipData]);

  useEffect(() => {
    if (
      updatedAutocomplete !== "" &&
      updatedAutocomplete !== null &&
      manualEntry
    ) {
      setSelectedPropertyData({
        ...selectedPropertyData,
        autocomplete: updatedAutocomplete,
      });
    }
  }, [updatedAutocomplete]);

  return (
    <Box>
      <LocationSelectionModal
        isOpen={openModal}
        selectedLocation={selectedLocation}
        setOpenModal={setOpenModal}
        setZipData={setZipData}
      />
      {/* <Code fontSize={"xs"} my={0} bg={"lightgray"}>
        @Dev Code: {productID === "starins" ? "StarInsurance" : productID}
      </Code> */}
      <ChakraLoader isLoading={loading || isLoading}>
        <Flex
          h={"70vh"}
          w={"100%"}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <Card
            borderRadius="2xl"
            boxShadow="2xl"
            width={"80%"}
            h="35rem"
            style={{ boxShadow: "0 0 30px var(--chakra-colors-primary-50)" }}
            border={"1px solid"}
            borderColor={"primary.50"}
          >
            {!manualEntry ? (
              <CardBody p={4}>
                <Flex
                  gap={2}
                  h={"100%"}
                  border={"1px solid"}
                  borderRadius={"xl"}
                  borderColor={"primary.50"}
                  flexDir={"column"}
                  justifyContent={"center"}
                  alignItems={"center"}
                >
                  <Box pb={4}>
                    <Image src={StarImage} />
                  </Box>

                  <Flex
                    flexDir="row"
                    pb={2}
                    gap={2}
                    fontSize="3xl"
                    textAlign="center"
                    alignItems="center"
                  >
                    Create a
                    <Text as="span" color="primary.500">
                      StarLight
                    </Text>
                    Insurance Quote
                  </Flex>

                  <Flex
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    width="100%"
                    gap={3}
                  >
                    <Box width={{ base: "100%", md: "55%" }}>
                      <GooglePlacesAutocomplete
                        id="places-input"
                        apiKey={process.env.REACT_APP_GOOGLE_API_KEY}
                        selectProps={{
                          value: search,
                          onChange: (e) => {
                            setSearch(e);
                          },
                          placeholder: "Enter Your Address",
                          autoFocus: true,
                          styles: {
                            container: (provided) => ({
                              ...provided,
                              border: "1px solid",
                              borderColor: "blue",
                              borderRadius: "5px",
                            }),
                          },
                        }}
                      />
                    </Box>
                    <Button
                      fontWeight={400}
                      size={"md"}
                      variant="outline"
                      borderRadius={"lg"}
                      borderWidth={1}
                      borderColor={"blue"}
                      onClick={onAutocompleteSubmit}
                      isDisabled={!search}
                    >
                      Continue
                    </Button>
                  </Flex>
                  <Text
                    cursor={"pointer"}
                    onClick={onManualAddressClick}
                    fontSize={"sm"}
                    textAlign={"left"}
                    textDecor={"underline"}
                    color={"gray.400"}
                  >
                    Manual Address Entry
                  </Text>
                </Flex>
              </CardBody>
            ) : (
              <>
                <CardBody p={4}>
                  <Flex
                    gap={2}
                    h={"100%"}
                    border={"1px solid"}
                    borderRadius={"xl"}
                    borderColor={"primary.50"}
                    flexDir={"column"}
                    justifyContent={"center"}
                    alignItems={"center"}
                  >
                    <Box pb={4}>
                      <Image src={StarImage} />
                    </Box>
                    <Grid
                      p={6}
                      templateColumns="repeat(4, 1fr)"
                      gap={6}
                      alignItems="center"
                      mb={8}
                    >
                      <GridItem colSpan={2}>
                        <FormControl>
                          <FormLabel fontWeight={500} color="black">
                            Full Address
                          </FormLabel>
                          <Input
                            type="text"
                            placeholder="Please Enter Business Name"
                            size="md"
                            focusBorderColor="secondary.500"
                            borderColor={"gray.300"}
                            value={selectedPropertyData?.autocomplete || ""}
                            disabled
                            name="autocomplete"
                            // placeholder="Read Only"
                            onChange={(e) => handleChange(e)}
                          />
                        </FormControl>{" "}
                      </GridItem>
                      <GridItem>
                        <FormControl isRequired>
                          <FormLabel fontWeight={500} color="black">
                            Street Number{" "}
                          </FormLabel>
                          <Input
                            type="number"
                            size="md"
                            focusBorderColor="secondary.500"
                            borderColor={"gray.300"}
                            // placeholder="Enter street number"
                            name="street_number"
                            onChange={(e) => handleChange(e)}
                          />
                        </FormControl>{" "}
                      </GridItem>
                      <GridItem>
                        <FormControl isRequired>
                          <FormLabel fontWeight={500} color="black">
                            Street Name
                          </FormLabel>
                          <Input
                            type="text"
                            size="md"
                            focusBorderColor="secondary.500"
                            borderColor={"gray.300"}
                            name="street_name"
                            onChange={(e) => handleChange(e)}
                            // placeholder="Enter street name"
                          />
                        </FormControl>{" "}
                      </GridItem>

                      <GridItem>
                        <FormControl isRequired>
                          <FormLabel fontWeight={500} color="black">
                            Zip Code
                          </FormLabel>
                          <Input
                            type="number"
                            size="md"
                            focusBorderColor="secondary.500"
                            borderColor={"gray.300"}
                            value={selectedPropertyData?.PostalCode || ""}
                            // placeholder="Enter Zip Code"
                            name="PostalCode"
                            onChange={(e) => handleChange(e)}
                          />
                        </FormControl>{" "}
                      </GridItem>
                      <GridItem>
                        <FormControl isRequired>
                          <FormLabel fontWeight={500} color="black">
                            City
                          </FormLabel>
                          <Input
                            type="text"
                            size="md"
                            focusBorderColor="secondary.500"
                            borderColor={"gray.300"}
                            value={selectedPropertyData?.city || ""}
                            // placeholder="Enter city"
                            name="city"
                            onChange={(e) => handleChange(e)}
                          />
                        </FormControl>{" "}
                      </GridItem>
                      <GridItem>
                        <FormControl>
                          <FormLabel fontWeight={500} color="black">
                            County Name
                          </FormLabel>
                          <Input
                            type="text"
                            size="md"
                            focusBorderColor="secondary.500"
                            borderColor={"gray.300"}
                            value={selectedPropertyData?.CountyName || ""}
                            name="CountyName"
                            // placeholder="Enter county name"
                            onChange={(e) => handleChange(e)}
                          />
                        </FormControl>
                      </GridItem>
                      <GridItem>
                        <FormControl isRequired>
                          <FormLabel>State Code</FormLabel>
                          <Select
                            size="md"
                            focusBorderColor="secondary.500"
                            borderColor={"gray.300"}
                            name="stateCode"
                            defaultValue={selectedPropertyData?.stateCode || ""}
                            onChange={(e) => {
                              const stateName = states.find(
                                (state) => state.abbr === e.target.value
                              )?.name;
                              setSelectedPropertyData({
                                ...selectedPropertyData,
                                stateCode: e.target.value,
                                state: stateName,
                              });
                              handleChange(e);
                            }}
                          >
                            <option value=""></option>
                            <option value={states[23].abbr}>
                              {states[23].name} ({states[23].abbr})
                            </option>
                            {/* {states.map((state) => (
                              <option key={state?.abbr} value={state?.abbr}>
                                {state?.name} ({state?.abbr})
                              </option>
                            ))} */}
                          </Select>
                        </FormControl>{" "}
                      </GridItem>
                    </Grid>
                    <Flex gap={2}>
                      <Button
                        variant={"solid"}
                        border={"1px solid"}
                        fontWeight={500}
                        fontSize={"0.9rem"}
                        borderRadius={"md"}
                        onClick={onManualAddressClick}
                      >
                        Google Auto Entry
                      </Button>
                      <Button
                        onClick={onManualSearch}
                        variant={"solid"}
                        border={"1px solid"}
                        fontWeight={500}
                        fontSize={"0.9rem"}
                        borderRadius={"md"}
                      >
                        Search Manually
                      </Button>
                    </Flex>
                  </Flex>
                </CardBody>
              </>
            )}
          </Card>
        </Flex>
      </ChakraLoader>
    </Box>
  );
}
