import { useEffect, useState } from "react";
import {
  Modal,
  Container,
  Row,
  Col,
  Card,
  Button,
  Table,
  Spinner,
} from "react-bootstrap";
import Form from "react-bootstrap/Form";
import "../Premium.css";
import { MdEditNote } from "react-icons/md";
import { toast } from "react-toastify";
import { postSaveHiscoxPremiumCard } from "../../api/postSaveHiscoxPremium";
import { standardizeKeyName } from "../StandarizeNames";
import { useTranslation } from "react-i18next";
import { postEndorseBind } from "../../api/postEndorseBind";
import FeeTable from "../FeeTable";
import { fetchFees } from "../../api/getFees";
import { postRenewBind } from "../../api/postRenewBind";
import { useSelector } from "react-redux";
import { Currency } from "../../../../common/Currency";
let pageLoader = false;

export const SavePremiumModal = ({
  selectedCardData,
  showModal,
  handleCloseModal,
  setChoosedPreferredCard,
  premiumResponseData,
  preferredType,
  setLoading,
  includeLossOfUse,
  setIncludeLossOfUse,
  businessIncomePremium,
  setBusinessIncomePremium,
  includeContents,
  setIncludeContents,
  setTotalPremium,
  totalPremium,
  productType,
  commercialProductType,
  loading,
  onHide,
  productCode,
  productId,
  setDataForRenewal,
  handleTransaction,
  handleClosePremiumModal,
  rootLoading,
}) => {
  const { t } = useTranslation();

  const calculateTotalPremium = () => {
    const total =
      (selectedCardData?.hasOwnProperty("improvementsAndBettermentsPremium")
        ? selectedCardData?.improvementsAndBettermentsPremium
        : 0) +
      (selectedCardData?.hasOwnProperty("buildingPremium")
        ? selectedCardData.buildingPremium
        : 0) +
      (includeContents && selectedCardData?.hasOwnProperty("contentsPremium")
        ? selectedCardData.contentsPremium
        : 0) +
      (includeLossOfUse && selectedCardData?.hasOwnProperty("lossOfUsePremium")
        ? selectedCardData.lossOfUsePremium
        : 0) +
      (selectedCardData?.hasOwnProperty("otherStructuresPremium")
        ? selectedCardData.otherStructuresPremium
        : 0) +
      (businessIncomePremium &&
      selectedCardData?.hasOwnProperty("businessIncomePremium")
        ? selectedCardData.businessIncomePremium
        : 0);
    return Number(total);
  };
  const [limits, setLimits] = useState({});
  const [feeLoader, setFeeLoader] = useState(false);
  const [bindingRef, setBindingRef] = useState("");
  const reduxStore = useSelector((state) => state);

  const [fees, setFees] = useState({
    fees: {},
    premium: 0,
    totalFee: 0,
    finalPremium: 0,
  });
  useEffect(() => {
    if (setChoosedPreferredCard) {
      setChoosedPreferredCard(false);
      setBusinessIncomePremium(true);
      setIncludeContents(true);
      setIncludeLossOfUse(true);
    }
  }, [selectedCardData]);

  const onSaveClick = async () => {
    try {
      pageLoader = true;
      setChoosedPreferredCard(true);
      await handleTransaction();
      handleClosePremiumModal();
      handleCloseModal();
      // bindPremium();
    } catch (error) {
      console.error("Error updating premium:", error);
    } finally {
      pageLoader = false;
    }
  };

  useEffect(
    () => {
      setTotalPremium(Number(calculateTotalPremium()) || 0);
    },
    [
      includeLossOfUse,
      includeContents,
      businessIncomePremium,
      selectedCardData,
    ],
    [includeLossOfUse, includeContents, businessIncomePremium, selectedCardData]
  );

  function findLimitsByCardData(selectedCardData, premiumResponseData) {
    if (!selectedCardData || !premiumResponseData) {
      return null;
    }
    const dataType = [
      "commercialOwned",
      "residential",
      "commercialTenanted",
    ].find((type) => premiumResponseData?.hasOwnProperty(type));

    if (!dataType) {
      console.error("No matching data type found.");
      return null;
    }

    const apiResponseData = premiumResponseData[dataType];

    if (!apiResponseData) {
      console.error("No matching data type found.");
      return null;
    }

    const findMatchingPolicy = (policies) => {
      return policies?.filter((policy) =>
        policy?.deductibles?.some((deductible) =>
          Object.keys(selectedCardData).every(
            (key) => deductible[key] === selectedCardData[key]
          )
        )
      );
    };

    const findPolicies = (policyType) => {
      const policies = findMatchingPolicy(apiResponseData[policyType]);
      if (!policies?.length) {
        return null;
      }
      return policies;
    };

    const primaryPolicies = findPolicies("primary");
    const excessPolicies = findPolicies("excess");

    const policies = primaryPolicies || excessPolicies;
    if (!policies) {
      console.error("No matching policies found.");
      return null;
    }

    return {
      buildingLimit: policies[0].buildingLimit,
      contentsLimit: policies[0].contentsLimit,
      otherStructureLimit: policies[0].otherStructuresLimit ?? null,
      lossOfUseLimit: policies[0].lossOfUseLimit ?? null,
      improvementsAndBettermentsLimit:
        policies[0].improvementsAndBettermentsLimit || null,
      businessIncomeAndExtraExpenseAnnualValue:
        policies[0].businessIncomeAndExtraExpenseAnnualValue || null,
    };
  }

  useEffect(() => {
    setLimits(
      findLimitsByCardData(selectedCardData, premiumResponseData) || {}
    );
  }, [selectedCardData, premiumResponseData]);

  const savePremiumCard = async () => {
    const requestBody = {
      includeLossOfUse: productType === "Commercial" ? null : includeLossOfUse,
      includeContents: includeContents,
      includeBusinessIncomeAndExtraExpense:
        productType === "Residential" ? null : businessIncomePremium,
      deductible: selectedCardData?.deductible,
      chargedPremium: totalPremium,
      type: preferredType,
      building_coverage: limits?.buildingLimit ?? null,
      content_coverage: limits?.contentsLimit ?? 0,
      improvement_betterment_coverage:
        limits?.improvementsAndBettermentsLimit ?? null,
    };
    const cleanedRequestBody = Object.fromEntries(
      Object.entries(requestBody).filter(([_, value]) => value !== null)
    );
    const quoteNo = premiumResponseData?.hiscoxId;
    try {
      setLoading(true);
      const data = await postSaveHiscoxPremiumCard({
        requestBody: cleanedRequestBody,
        quoteNo,
      });
      if (data?.status === true) {
        toast.success(
          data?.message == "Coverage Update successfully"
            ? "Coverage Updated"
            : data?.message,
          {
            autoClose: 1500,
          }
        );
        // bindPremium();
      } else {
        toast.error(data?.message);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (totalPremium !== undefined && totalPremium !== null) {
      getFees();
    }
  }, [totalPremium]);

  async function getFees() {
    const requestBody = {
      state: premiumResponseData?.geocodedLocation?.stateCode || "",
      productId: productId || "",
      premium: Number(totalPremium) ?? 0,
      prTransTypeCode: "RENEW",
    };
    const product = productCode === "HiscoxFloodPlus" ? "hiscox" : productCode;
    setFeeLoader(true);
    try {
      const fees = await fetchFees(requestBody, product);
      if (fees?.data?.success) {
        setFees(fees?.data?.data);
      } else {
        setFees(null);
      }
      return fees;
    } catch (error) {
      console.error("Failed to calculate fees:", error);
      return null;
    } finally {
      setFeeLoader(false);
    }
  }

  const bindPremium = async () => {
    const reqBody = {
      productType,
      commercialProductType,
      policyType: preferredType,
      premium: Number(parseFloat(totalPremium).toFixed(2)),
      includeLossOfUse: !commercialProductType
        ? includeLossOfUse
        : businessIncomePremium,
      includeContents,
      buildingLimit:
        commercialProductType === "Tenanted"
          ? limits?.improvementsAndBettermentsLimit
          : limits?.buildingLimit ?? null,
      contentsLimit: limits?.contentsLimit ?? null,
      deductible: selectedCardData?.deductible,
      effectiveDate: "",
      bindingReference: bindingRef,
      fullName: reduxStore?.coreSystem?.applicationData?.s_FullLegalName || "",
    };
    const product = productCode === "HiscoxFloodPlus" ? "hiscox" : productCode;
    const cleanedRequestBody = Object.fromEntries(
      Object.entries(reqBody).filter(([_, value]) => value !== null)
    );
    const quoteNo = premiumResponseData?.hiscoxId;
    try {
      setLoading(true);
      const res = await postRenewBind({
        requestBody: cleanedRequestBody,
        policyId: quoteNo,
        productName: product,
      });

      if (res?.status === false && Array.isArray(res?.errors)) {
        res.errors.forEach((error) =>
          toast.error(error, { autoClose: 5000, toastId: "premiumUpdated" })
        );
      } else if (res?.status === true) {
        toast.success("Quote Renewed Successfully", {
          autoClose: 5000,
          toastId: "premiumUpdated1",
        });
        handleCloseModal();
        onHide();
      }
    } catch (error) {
      console.error("Error binding premium:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    setDataForRenewal({
      limits: limits,
      totalPremium: totalPremium,
      selectedCardData: selectedCardData,
      options: {
        includeLossOfUse,
        includeContents,
        businessIncomePremium,
      },
    });
  }, [selectedCardData, totalPremium]);

  const CustomSpinner = () => {
    return (
      <div className="custom-spinner-container d-flex align-items-center flex-column">
        <Spinner variant="primary" animation="border" role="status">
          <span className="visually-hidden">{t("Loading...")}</span>
        </Spinner>
        <p
          className="custom-spinner-text mt-2 text-center text-secondary"
          style={{ fontSize: "small" }}
        >
          {t("Loading...")}
        </p>
      </div>
    );
  };
  return (
    <Modal
      size="xl"
      scrollable
      show={showModal}
      onHide={handleCloseModal}
      centered
    >
      {loading && <CustomSpinner />}
      {rootLoading && <CustomSpinner />}
      {pageLoader && <CustomSpinner />}
      <Modal.Header closeButton>
        <Modal.Title className="text-center d-flex align-items-center gap-2">
          <h2>{t("Customize")}</h2>
          <MdEditNote size={40} />
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container>
          <Row className="justify-content-center mb-0">
            <Col xs={12} sm={10} md={8}>
              <Card className="shadow-sm mb-3">
                <Card.Header className="bg-light text-center">
                  <strong>
                    {t("Selected Deductible")}: $
                    {new Intl.NumberFormat("en-US").format(
                      selectedCardData?.deductible
                    ) || 0}
                  </strong>
                </Card.Header>
                <Card.Body>
                  <Table size="sm" className="table ">
                    <tbody>
                      {selectedCardData &&
                        typeof selectedCardData === "object" &&
                        !Array.isArray(selectedCardData) &&
                        Object.entries(selectedCardData)
                          .filter(([key]) => key !== "deductible")
                          .map(([key, value], index) => {
                            let rowClass = "";
                            if (
                              (key === "contentsPremium" && !includeContents) ||
                              (key === "lossOfUsePremium" &&
                                !includeLossOfUse) ||
                              (key === "businessIncomePremium" &&
                                !businessIncomePremium)
                            ) {
                              rowClass = "strikethrough";
                            }
                            return (
                              <tr key={index} className={rowClass}>
                                <td>{standardizeKeyName(key)}</td>
                                <td className="text-end text-nowrap align-middle">
                                  <Currency
                                    amount={value}
                                    negetiveAmountStyle="none"
                                  />
                                </td>
                              </tr>
                            );
                          })}
                      <tr className="fw-bold">
                        <td>Total Premium</td>
                        <td className="text-end text-nowrap align-middle">
                          <Currency
                            amount={calculateTotalPremium()}
                            negetiveAmountStyle="none"
                          />
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </Card.Body>
              </Card>
            </Col>
          </Row>

          <Row className="justify-content-center mb-0">
            <Col xs={12} sm={10} md={8}>
              <Card className="shadow-sm mb-3">
                <Card.Header className=" d-flex align-items-center gap-2 bg-light text-center justify-content-center">
                  <strong>Fees</strong>{" "}
                  {feeLoader && <Spinner animation="border" size="sm" />}
                </Card.Header>
                <Card.Body>
                  <FeeTable fees={fees} />
                </Card.Body>
              </Card>
            </Col>
          </Row>

          <Row className="justify-content-center mb-3">
            <Col xs={12} sm={10} md={8}>
              <Card
                className="shadow-sm"
                style={{
                  opacity: feeLoader ? 0.5 : 1,
                }}
              >
                <Card.Header className="bg-light text-center">
                  <strong>{t("Current Premium")}</strong>
                </Card.Header>
                <Card.Body>
                  <p className="display-2 text-center text-secondary">
                    $
                    {new Intl.NumberFormat("en-US").format(
                      Number(calculateTotalPremium() || 0) +
                        Number(fees?.totalFee || 0)
                    )}
                  </p>
                </Card.Body>
              </Card>
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col xs={12} sm={10} md={8}>
              <Card className="shadow-sm">
                <Card.Header className="bg-light text-center">
                  <strong>{t("Include Options")}</strong>
                </Card.Header>
                <Card.Body>
                  <Form.Group className="mb-3">
                    {selectedCardData &&
                      "lossOfUsePremium" in selectedCardData && (
                        <Form.Check
                          style={{ userSelect: "none" }}
                          className="custom-switch"
                          id="includeLossOfUseSwitch"
                          type="switch"
                          label={t("Include Loss of Use")}
                          checked={includeLossOfUse}
                          onChange={(e) =>
                            setIncludeLossOfUse(e.target.checked)
                          }
                        />
                      )}
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Check
                      style={{ userSelect: "none", cursor: "pointer" }}
                      className="custom-switch"
                      id="includeContentsSwitch"
                      type="switch"
                      label={t("Include Contents")}
                      checked={includeContents}
                      onChange={(e) => setIncludeContents(e.target.checked)}
                    />
                  </Form.Group>
                  {selectedCardData &&
                    "businessIncomePremium" in selectedCardData && (
                      <Form.Group className="mb-3">
                        <Form.Check
                          style={{ userSelect: "none", cursor: "pointer" }}
                          className="custom-switch"
                          id="businessIncomePremiumSwitch"
                          type="switch"
                          label="Business Income Premium"
                          checked={businessIncomePremium}
                          onChange={(e) =>
                            setBusinessIncomePremium(e.target.checked)
                          }
                        />
                      </Form.Group>
                    )}
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </Modal.Body>
      <Modal.Footer className="gap-2 justify-content-between">
        <div className="d-flex align-items-center">
          {/* <label className="text-secondary mx-2">
            {t("Binding Reference")} :
          </label>
          <div className="d-flex gap-2">
            <input
              value={bindingRef}
              onChange={(e) => setBindingRef(e.target.value)}
              className="form-control"
              placeholder="Reference is required"
              type="text"
            />
            {bindingRef === "" && (
              <label className="text-danger mt-2">!!</label>
            )}
          </div> */}
        </div>
        <div className="d-flex gap-3">
          <Button
            style={{
              width: "6vw",
              borderRadius: "0.2rem",
              backgroundColor: "#33DBFF",
              border: "1px solid",
              borderColor: "none",
            }}
            size="md"
            onClick={onSaveClick}
          >
            {t("Save")}
          </Button>
          <Button
            style={{
              width: "6vw",
              borderRadius: "0.2rem",
              border: "1px solid",
              borderColor: "none",
            }}
            variant="secondary"
            onClick={handleCloseModal}
            size="md"
          >
            {t("Close")}
          </Button>
        </div>
      </Modal.Footer>
      {/* this is custom mini loader css */}
      <style>{`
      .custom-spinner-container {
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(255, 255, 255, 0.5);
      z-index: 9999;
  }
`}</style>
    </Modal>
  );
};
