import { Route, Routes } from "react-router-dom";
import Stepper from "./components/Stepper";
import { QuoteStart } from "./components/QuoteStart";
import { createContext, useContext, useState } from "react";
import FormMode from "./Accordions";
import ProductType from "./components/ProductType";
import { tenant as tenancy } from "../../common/Helper";
import { StarLandingPage } from "../create-star-quote/LandingPage";
export const CreateQuoteContext = createContext(undefined);
const CreateQuote = () => {
  //mode is default stepper
  const [viewMode, setViewMode] = useState("stepper");
  const [product, setProduct] = useState("Residential");
  // if commercial product then default would be Owned
  const [tenant, setTenant] = useState("Owned");
  const [loading, setLoading] = useState(false);
  // this is our flood map data to get values like no of floors and zones or no of units etc.
  const [autoGenMapData, setAutoGenMapData] = useState({});
  const [inputValue, setInputValue] = useState(null);
  // this is to keep track wether it's commercial or not, no use case in api.
  const [productSelection, setProductSelection] = useState(false);
  const [choosedPreferredCard, setChoosedPreferredCard] = useState(false);
  //this is to keep track if user selected premium card and saved it or not.
  const [hiscoxCreateQuoteData, setHiscoxCreateQuoteData] = useState({});
  const [stepLock, setStepLock] = useState(false);
  const [includeLossOfUse, setIncludeLossOfUse] = useState(true);
  const [includeContents, setIncludeContents] = useState(true);
  const [businessIncomePremium, setBusinessIncomePremium] = useState(true);
  const [totalPremium, setTotalPremium] = useState(0);
  const [callHiscoxApi, setCallHiscoxApi] = useState(false);
  const [isNextClicked, setIsNextClicked] = useState(false);
  const [manualEntry, setManualEntry] = useState(false);
  const [limitsValidationError, setLimitsValidationError] = useState();
  const [rcvValidationError, setRcvValidationError] = useState();
  const [status, setStatus] = useState("create");
  const [quoteNumber, setQuoteNumber] = useState(null);
  const productCodeList =
    JSON.parse(localStorage.getItem("productCodeList")) || [];
  const isAgentHasHiscoxAccess = productCodeList.some((item) =>
    Object.values(item).includes("HiscoxFloodPlus")
  );
  const [locationQuoteObject, setLocationQuoteObject] = useState({
    streetNumber: "",
    streetName: "",
    city: "",
    countyName: "",
    stateCode: "",
    country: "",
    postalCode: "",
    zipPlus4: "",
    postalCodeSuffix: "",
    addressLine: "",
    latitude: "",
    longitude: "",
    fullAddress: "",
    addressLine3: "",
  });

  const [constructionDetails, setConstructionDetails] = useState({
    commercialProductType: "",
    productType: "",
    occupancyType: "",
    constructionType: "",
    yearBuilt: "",
    squareFootage: "",
    noOfStories: "",
    elevationHeight: "",
    buildingWaterType: "No",
    basementType: "None",
    foundationType: "",
    additionalFondationType: "None",
    attachedGarageType: "None",
    effectiveDate: "",
    expirationDate: "",
    requestDate: "",
    floodZone: "",
  });

  const [additionalInsured, setAdditionalInsured] = useState([]);
  const [additionalInsuredFlag, setAdditionalInsuredFlag] = useState(false);

  //default values for faster access, can be changed.
  const [financialDetails, setFinancialDetails] = useState({
    residential: {
      replacementCostValues: {
        building: 0,
        contents: 0,
      },
      limits: [
        {
          building: 0,
          contents: 0,
        },
      ],
    },
    commercial: {
      owned: {
        replacementCostValues: {
          building: 0,
          contents: 0,
        },
        limits: [
          {
            building: 0,
            contents: 0,
          },
        ],
      },
      tenanted: {
        replacementCostValues: {
          improvementsAndBetterments: 0,
          contents: 0,
        },
        limits: [
          {
            improvementsAndBetterments: 0,
            contents: 0,
          },
        ],
      },
      businessIncomeAndExtraExpenseAnnualValue: 0,
      stockReplacementCostValue: 0,
    },
    priorLosses: [],

    contentsCostValueType: "ReplacementCostValue",
  });

  const [applicantDetails, setApplicantDetails] = useState({
    firstName: "",
    lastName: "",
    propertyType: "individual",
    organisationName: "",
    email: "",
    contactNo: "",
  });

  const [mortgageeDetails, setMortgageeDetails] = useState([]);
  const [hasMortgagee, setHasMortgagee] = useState(false);
  const [mailingAddress, setMailingAddress] = useState({
    s_StateName: "",
    s_CountyName: "",
    s_CityName: "",
    s_ZipCode: "",
    mailingAddressData: "",
    addressLine: "",
  });
  const [isMailingAddressSame, setIsMailingAddressSame] = useState(true);
  const [premiumFeesData, setPremiumFeesData] = useState("");

  return (
    <CreateQuoteContext.Provider
      value={{
        locationQuoteObject,
        setLocationQuoteObject,
        inputValue,
        setInputValue,
        product,
        setProduct,
        tenant,
        setTenant,
        autoGenMapData,
        setAutoGenMapData,
        loading,
        setLoading,
        constructionDetails,
        setConstructionDetails,
        productSelection,
        setProductSelection,
        financialDetails,
        setFinancialDetails,
        viewMode,
        setViewMode,
        hiscoxCreateQuoteData,
        setHiscoxCreateQuoteData,
        stepLock,
        setStepLock,
        applicantDetails,
        setApplicantDetails,
        choosedPreferredCard,
        setChoosedPreferredCard,
        includeLossOfUse,
        setIncludeLossOfUse,
        businessIncomePremium,
        setBusinessIncomePremium,
        includeContents,
        setIncludeContents,
        totalPremium,
        setTotalPremium,
        callHiscoxApi,
        setCallHiscoxApi,
        isNextClicked,
        setIsNextClicked,
        manualEntry,
        setManualEntry,
        limitsValidationError,
        setLimitsValidationError,
        rcvValidationError,
        setRcvValidationError,
        status,
        setStatus,
        quoteNumber,
        setQuoteNumber,
        additionalInsured,
        setAdditionalInsured,
        additionalInsuredFlag,
        setAdditionalInsuredFlag,
        premiumFeesData,
        setPremiumFeesData,
        mortgageeDetails,
        setMortgageeDetails,
        mailingAddress,
        setMailingAddress,
        isMailingAddressSame,
        setIsMailingAddressSame,
        hasMortgagee,
        setHasMortgagee,
      }}
    >
      <Routes>
        {isAgentHasHiscoxAccess ? (
          <Route path="/*" element={<QuoteStart />} />
        ) : tenancy === "starinsurance" ||
          tenancy === "starvin" ||
          tenancy === "starvantage" ? (
          <Route path="/*" element={<StarLandingPage />} />
        ) : (
          (window.location.href = "/dashboard")
        )}
        <Route path="/product" element={<ProductType />} />
        <Route path="/stepper" element={<Stepper />} />
        <Route path="/form-mode" element={<FormMode />} />
      </Routes>
    </CreateQuoteContext.Provider>
  );
};

export default CreateQuote;

//custom hook to get the context & help with debugging (devloper only.)
export function useMyContext() {
  const context = useContext(CreateQuoteContext);
  if (!context) {
    throw new Error(
      "Dev Error: useMyContext must be used within a CreateQuoteProvider"
    );
  }
  return context;
}
