import React, {useEffect, useState} from 'react'
import {Loader, LoadingOverlay} from "react-overlay-loader";
import {Button, Col, Form, Row, Table, Tabs} from "react-bootstrap";
import {Navigate, useNavigate} from "react-router-dom";
import "react-overlay-loader/styles.css";
import {Tab} from "bootstrap";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import NumberFormat from "react-number-format";
import Swal from "sweetalert2";
import {selectHiscoxRequestParams, selectPrivateProducts ,selectExcessProducts} from "../../../../../../store/selectors/private-flood";
import './index.scss';
import PrivateProductItem from "./PrivateProductItem";
import {
  getDeductibles,
  getEditEmptyQuoteData,
  getHiscoxPremium,
  getPayPlanOptions,
  getPrivateProductConfigurations,
  getProducerInfo,
  pivotApiCall,
  updateInitQuoteApi,
  getZoneDetermination,
  getExcessPremium
} from "../../../../../../services/floodService";
import {toast} from "react-toastify";
import {setActivePrivateProductTab,setExcessProductData} from "../../../../../../store/actions";
import NfipProductItem from "./NfipProductItem";
import {getProfileInfo} from "../../../../../../services/profileService";
import { checkPivotOutage } from "../../../../../../services/commonService";

const PrivateFloodPlan = ({handleChange,setzDDetails}) => {
  const isPivotOutage = checkPivotOutage();
  const [basicBuildingCvg, setBasicBuildingCvg] = useState(0);
  const [basicContentsCvg, setBasicContentsCvg] = useState(0);
  const [recommendedBuildingCvg, setRecommendedBuildingCvg] = useState(0);
  const [recommendedContentsCvg, setRecommendedContentsCvg] = useState(0);
  const [basicBuildingDed, setBasicBuildingDed] = useState(0);
  const [basicContentsDed, setBasicContentsDed] = useState(0);
  const [recommendedBuildingDed, setRecommendedBuildingDed] = useState(0);
  const [recommendedContentsDed, setRecommendedContentsDed] = useState(0);
  const [customBldgDed, setCustomBldgDed] = useState(0);
  const [customBldgCvg, setCustomBldgCvg] = useState(0);
  const [customContentCvg, setCustomContentCvg] = useState(0);
  const [customContentDed, setCustomContentDed] = useState(0);
  const [isCustomChanged, setIsCustomChanged] = useState(false);
  const [isLimitExceed, setIsLimitExceed] = useState(false);
  const hiscoxRequestParams = useSelector(state => selectHiscoxRequestParams(state));
  const privateProducts = useSelector(state => selectPrivateProducts(state))
  const excessProducts = useSelector(state => selectExcessProducts(state))
  
  const [adminEmail, setAdminEmail] = useState('');
  const {
    emptyQuoteData, initApiObject, apiLoading, floodSystemDD, basicCvg, recommendedCvg, basicCvgResponse,
    recommendedCvgResponse, customCvg, customCvgResp, deductible, ZDDetails, ZDDetailsFromAddress
  } = useSelector(state => state.flood);
  const {policyInfo} = emptyQuoteData;
  const [coverages, setCoverages] = useState([])
  const [bDeductible, setBDeductible] = useState([]);
  const [cDeductible, setCDeductible] = useState([]);
  const [tenantInterest, setTenantInterest] = useState(null)
  const [billTo, setBillTo] = useState('');
  const [validated, setValidated] = useState(false);
  const [isBldgCvgDisable, setIsBldgCvgDisable] = useState(false);
  const [loading, setLoading] = useState(false);

  const [basicPrem, setBasicPrem] = useState(0);
  const [basicNetPrem, setBasicNetPrem] = useState(0);
  const [basicPremFees, setBasicPremFees] = useState(0);
  const [recommendedPrem, setRecommendedPrem] = useState(0);
  const [recommendedNetPrem, setRecommendedNetPrem] = useState(0);
  const [recommendedPremFees, setRecommendedPremFees] = useState(0);
  const [customPrem, setCustomPrem] = useState(0);
  const [customNetPrem, setCustomNetPrem] = useState(0);
  const [customPremFees, setCustomPremFees] = useState(0);
  const [basicRCV, setBasicRCV] = useState(0);
  const [recommendCV, setRecommendRCV] = useState(0);
  const [customRCV, setCustomRCV] = useState(0);
  const [isDedChange, setIsDedChange] = useState(false);
  const [floodAPIProcessing, setFloodAPIProcessing] = useState(false);
  const [isRecommendedVisible, setIsRecommendedVisible] = useState(true);
  const navigation = useNavigate();
  const dispatch = useDispatch();
  // Only trigger if custom details change
  const getHiscoxFloodQuote = async () => {
    if (customBldgCvg === 0) return false;
    try {
      const agencyInfo = await getProducerInfo(policyInfo.n_AgencyPeson_FK, policyInfo.n_SubAgentPersoninfo_FK);
      const agencyAddress = {
        city: agencyInfo.address?.s_CityName ?? '',
        county: agencyInfo.address?.s_CountyName ?? '',
        line1: agencyInfo.address?.s_AddressLine1 ?? '',
        line2: agencyInfo.address?.s_AddressLine2 ?? '',
        state: agencyInfo.address?.s_StateCode ?? '',
        street: agencyInfo.address?.s_StreetName ?? '',
        zip: agencyInfo.address?.s_PostalCode ?? '',
        googlePlaceId: '',
        latitude: agencyInfo.address?.s_Latitude ?? '',
        longitude: agencyInfo.address?.s_Longitude ?? '',
      }
      hiscoxRequestParams['agency'] = {...agencyInfo, address : agencyAddress, agencyNo: hiscoxRequestParams['agency']['agencyNo'] , agencyPersonPk: policyInfo.n_AgencyPeson_FK};
      hiscoxRequestParams['building'] = {...hiscoxRequestParams['building'], tenantInterest}
      hiscoxRequestParams['agent'] = {...hiscoxRequestParams['agent'], email: adminEmail}
      const apiRequestParams = {...hiscoxRequestParams, coverages, deductible: customBldgDed > 0 ? customBldgDed : customContentDed}
      const privateProductArray = privateProducts.privateproduct;
      dispatch(getHiscoxPremium(apiRequestParams,false,privateProductArray))
    } catch (e) {
      throw  e;
    }
  }

  const sendFloodRequests = () => {
      getHiscoxFloodQuote();
      //getExcessFloodQuote(1000);
  }
  const getExcessFloodQuote = async (coverage = null) => {
    if (customBldgCvg === 0) return false;
    try {
      const agencyInfo = await getProducerInfo(policyInfo.n_AgencyPeson_FK, policyInfo.n_SubAgentPersoninfo_FK);
      const agencyAddress = {
        city: agencyInfo.address?.s_CityName ?? '',
        county: agencyInfo.address?.s_CountyName ?? '',
        line1: agencyInfo.address?.s_AddressLine1 ?? '',
        line2: agencyInfo.address?.s_AddressLine2 ?? '',
        state: agencyInfo.address?.s_StateCode ?? '',
        street: agencyInfo.address?.s_StreetName ?? '',
        zip: agencyInfo.address?.s_PostalCode ?? '',
        googlePlaceId: '',
        latitude: agencyInfo.address?.s_Latitude ?? '',
        longitude: agencyInfo.address?.s_Longitude ?? '',
      }
      hiscoxRequestParams['agency'] = {...agencyInfo, address : agencyAddress, agencyNo: hiscoxRequestParams['agency']['agencyNo']};
      hiscoxRequestParams['building'] = {...hiscoxRequestParams['building'], tenantInterest}
      hiscoxRequestParams['agent'] = {...hiscoxRequestParams['agent'], email: adminEmail}
      hiscoxRequestParams['primaryPolicy'] = {...hiscoxRequestParams['primaryPolicy'], 
        PolicyNumber: policyInfo.Policy_No,
        InsuranceType: 'NFIP',
        Carrier: '',
        FloodZone: hiscoxRequestParams['building']['floodZone'],
        ContentCoverage: hiscoxRequestParams['building']['n_ContentCvg'],
        EffectiveDate: hiscoxRequestParams['effectiveDate'],
        ExcessCoverageBuilding : parseInt(coverage),
        ExcessCoverageContent:0
      }
      const apiRequestParams = {...hiscoxRequestParams, coverages, deductible: customBldgDed > 0 ? customBldgDed : customContentDed}
      dispatch(getExcessPremium(apiRequestParams))
    } catch (e) {
      throw  e;
    }
  }
  useEffect(() => {    
    async function fetchAndSetAdminEmail() {
      await dispatch(getProfileInfo()).then((res) => {
        setAdminEmail(res?.data?.data?.Email);
      });
    }
    fetchAndSetAdminEmail();
  },[])
  useEffect(()=>{
    if(
      !privateProducts.loading &&
      privateProducts.privateproduct.length > 0 &&
      !floodAPIProcessing &&
      customBldgCvg > 0
    ) {
      setFloodAPIProcessing(true);
      sendFloodRequests();
    }
  },[privateProducts, customBldgCvg])
  const setSelectedTab = (activeTab) => {
    dispatch(setActivePrivateProductTab(activeTab))
  }
  useEffect(() => {
    dispatch(getPrivateProductConfigurations())
  }, []);
  useEffect(() => {
    if (customCvg && customCvg.totalCoverageBuilding > 0) {
      setCustomBldgCvg(customCvg.totalCoverageBuilding)
      setCustomBldgDed(customCvg.buildingDeductibleDisplay)
      setCustomContentCvg(customCvg.totalCoverageContents)
      setCustomContentDed(customCvg.contentsDeductibleDisplay)
    }
  }, [customCvg]);
  useEffect(() => {
    if (isCustomChanged === true) {
      callPivotApi();
      sendFloodRequests();
    }
  }, [isCustomChanged]);
  // Trigger Loading
  useEffect(() => {
    let temp = false;
    if (Object.keys(deductible).length === 0 || Object.keys(basicCvgResponse).length === 0 || Object.keys(recommendedCvgResponse).length === 0 || Object.keys(customCvgResp).length === 0 || privateProducts.loading) {
      temp = true;
    }
    setLoading(temp)
  }, [deductible, basicCvgResponse, recommendedCvgResponse, customCvgResp, privateProducts]);
  // Trigger to updated premium details for various plan
  useEffect(() => {
	const isPivotOutage = checkPivotOutage();
    // Basic Premium
    if (Object.keys(basicCvgResponse).length > 0) {
      if (isPivotOutage == 'true') {
        setBasicPrem(basicCvgResponse.data.premium || 0);
        setBasicNetPrem(basicCvgResponse.data.netPremium || 0);
        setBasicPremFees(basicCvgResponse.data.fees || 0);
      } else {
        if (Object.keys(basicCvgResponse.data).length > 0) {
          const code = basicCvgResponse.data.code || 0;
          if ([200, 201].includes(code) && Object.keys(basicCvgResponse.data.rreQuote).length > 0) {
            setBasicPrem(basicCvgResponse.data.rreQuote.premium.totalAnnualPayment || 0);
            setBasicRCV(basicCvgResponse.data.quoteFactors.rcvFema || 0);
          }
        }
      }

    }
    // Recommended Premium
    if (Object.keys(recommendedCvgResponse).length > 0) {
      if (isPivotOutage == 'true') {
        setRecommendedPrem(recommendedCvgResponse.data.premium || 0);
        setRecommendedNetPrem(recommendedCvgResponse.data.netPremium || 0);
        setRecommendedPremFees(recommendedCvgResponse.data.fees || 0);
      } else {
        if (Object.keys(recommendedCvgResponse.data).length > 0) {
          const code = recommendedCvgResponse.data.code || 0;
          if ([200, 201].includes(code) && Object.keys(recommendedCvgResponse.data.rreQuote).length > 0) {
            setRecommendedPrem(recommendedCvgResponse.data.rreQuote.premium.totalAnnualPayment || 0);
            setRecommendRCV(recommendedCvgResponse.data.quoteFactors.rcvFema || 0);
          }
        }
      }

    }
    // Custom Premium
    if (Object.keys(customCvgResp).length > 0) {
      if (isPivotOutage == 'true') {
        setCustomPrem(customCvgResp.data.premium || 0);
        setCustomNetPrem(customCvgResp.data.netPremium || 0);
        setCustomPremFees(customCvgResp.data.fees || 0);
      } else {
        if (Object.keys(customCvgResp.data).length > 0) {
          const code = customCvgResp.data.code || 0;
          if ([200, 201].includes(code) && Object.keys(customCvgResp.data.rreQuote).length > 0) {
            setCustomPrem(customCvgResp.data.rreQuote.premium.totalAnnualPayment || 0);
            setCustomRCV(customCvgResp.data.quoteFactors.rcvFema || 0);
          }
        }
      }

    }
  }, [basicCvgResponse, recommendedCvgResponse, customCvgResp]);
  useEffect(() => {
    if (isLimitExceed === true) {
      toast.error(t('Coverage Max Limit Exceed!'));
      setIsLimitExceed(false);
    }
  }, [isLimitExceed]);
  useEffect(() => {
    if (!Object.keys(initApiObject).length) return;
    const request = {
      sqft: parseInt(initApiObject.sqft, 10),
      viewType: emptyQuoteData.viewType || '',
      replacementCostValue: emptyQuoteData?.propertyInfo?.n_ReplacementCost || '',
      occupancy: emptyQuoteData?.propertyInfo?.s_Occupancy || '',
      numOfUnit: parseInt(emptyQuoteData?.propertyInfo?.s_NoOfUnits || 0),
    };
    dispatch(getDeductibles());
    dispatch(getPayPlanOptions(request));
    if (!Object.keys(emptyQuoteData).length) return;
    const {s_PolicyWaitingPeriod, policyInfo} = emptyQuoteData;
    if (s_PolicyWaitingPeriod === 'LOANNOWAIT') {
      policyInfo.s_BillTo = 'FIRSTMORTGAGE';
      dispatch(getEditEmptyQuoteData({...emptyQuoteData, policyInfo}));
    }

    setBillTo(policyInfo.s_BillTo);

    const buildingUse = emptyQuoteData.propertyInfo.s_BuildingUse;
    const temp = emptyQuoteData.viewType === 'UNIT'
      && ['APARTMENTUNIT20', 'COOPERATIVEUNIT20', 'RESIDENTIALCONDOUNITNRB20', 'OTHER20'].includes(buildingUse)
      && emptyQuoteData.propertyInfo.s_Occupancy !== 'NONRESIDENTIALUNIT20';

    setIsBldgCvgDisable(temp);
  }, []);
  const getPivotApiRequest = async (cvgData, plan) => {
    const code = 'QUOTE_NEWBUSINESS';
    const reqJson = {...initApiObject};
    reqJson.buildingDeductible = cvgData.buildingDeductibleDisplay;
    reqJson.contentsDeductible = cvgData.contentsDeductibleDisplay;
    reqJson.coverageAmountType = cvgData.coverageAmountType;
    reqJson.iccPremium = cvgData.iccPremium;
    reqJson.totalCoverageBuilding = cvgData.totalCoverageBuilding;
    reqJson.totalCoverageContents = cvgData.totalCoverageContents;
    reqJson.whichSection = 'BUYPLAN';
    reqJson.plan = plan;
    reqJson.uniqueKey = localStorage.getItem('uniqueKey');

    const request = {code, requestParam: reqJson, sourceId: 0};
    await dispatch(pivotApiCall(code, request, plan));
  };

  useEffect(() => {
    const newCoverages = {
      dwelling: customBldgCvg,
      personalProperty: customContentCvg,
      otherStructures: customBldgCvg * 0.1,
      lossOfUse: customBldgCvg * 0.2,
    };
    setCoverages(newCoverages)
  }, [customContentCvg, customBldgCvg, customContentDed])
  useEffect(() => {
    if (
      emptyQuoteData.propertyInfo.s_Usage === 'TENANT' &&
      customBldgDed > 0 &&
      customContentDed > 0
    ) {
      setTenantInterest('BuildingAndPersonalProperty');
    }
    if (
      emptyQuoteData.propertyInfo.s_Usage === 'TENANT' &&
      !customBldgDed > 0 &&
      customContentDed > 0
    ) {
      setTenantInterest('PersonalProperty');
    }
    if (
      emptyQuoteData.propertyInfo.s_Usage === 'TENANT' &&
      customBldgDed > 0 &&
      !customContentDed > 0
    ) {
      setTenantInterest('BuildingOnly');
    }
  }, [])
  useEffect(() => {
    if (emptyQuoteData.policyInfo) {
      setBillTo(emptyQuoteData.policyInfo.s_BillTo);
    }
  }, [emptyQuoteData])

  // Trigger to Updated Plan Details on Dependent State Updated
  useEffect(() => {
    if (Object.keys(basicCvg).length > 0) {
      const cvgData = basicCvg
      var bldgCvg = cvgData.totalCoverageBuilding || 0;
      // Max Building Coverage Allowed for Residential Condo Building With 1 Unit
      if (['RESIDENTIALCONDOBUILD20'].includes(emptyQuoteData.propertyInfo.s_Occupancy) && emptyQuoteData.propertyInfo.s_NoOfUnits === "1") {
        bldgCvg = 250000;
      }

      setBasicBuildingCvg(bldgCvg)
      setBasicContentsCvg(cvgData.totalCoverageContents || 0)
      setBasicBuildingDed(cvgData.buildingDeductibleDisplay || 0)
      setBasicContentsDed(cvgData.contentsDeductibleDisplay || 0)

      // To Call Pivot Api For Various Plan at the same time
      getPivotApiRequest(cvgData, 'Basic')
    }
  }, [basicCvg]);

  // Trigger to Updated Plan Details on Dependent State Updated
  useEffect(() => {
    if (Object.keys(recommendedCvg).length > 0) {
      const cvgData = recommendedCvg
      var bldgCvg = cvgData.totalCoverageBuilding || 0;
      // Max Building Coverage Allowed for Residential Condo Building With 1 Unit
      if (['RESIDENTIALCONDOBUILD20'].includes(emptyQuoteData.propertyInfo.s_Occupancy) && emptyQuoteData.propertyInfo.s_NoOfUnits === "1") {
        bldgCvg = 250000;
      }
      setRecommendedBuildingCvg(bldgCvg)
      setRecommendedContentsCvg(cvgData.totalCoverageContents || 0)
      setRecommendedBuildingDed(cvgData.buildingDeductibleDisplay || 0)
      setRecommendedContentsDed(cvgData.contentsDeductibleDisplay || 0)

      const occupancyType = emptyQuoteData.propertyInfo.s_Occupancy || "";
      setIsRecommendedVisible(!(occupancyType === "OTHERRESIDENTIAL20" && bldgCvg >= 500000));
      // To Call Pivot Api For Various Plan at the same time
      getPivotApiRequest(cvgData, 'Recommended')
    }
  }, [recommendedCvg]);

  // Trigger to Updated Plan Details on Dependent State Updated
  useEffect(() => {
    if (Object.keys(customCvg).length > 0) {
      const cvgData = customCvg
      var bldgCvg = cvgData.totalCoverageBuilding || 0;
      // Max Building Coverage Allowed for Residential Condo Building With 1 Unit
      if (['RESIDENTIALCONDOBUILD20'].includes(emptyQuoteData.propertyInfo.s_Occupancy) && emptyQuoteData.propertyInfo.s_NoOfUnits === "1") {
        bldgCvg = 250000;
      }
      setCustomBldgCvg(bldgCvg)
      setCustomContentCvg(cvgData.totalCoverageContents || 0)
      setCustomBldgDed(cvgData.buildingDeductibleDisplay || 0)
      setCustomContentDed(cvgData.contentsDeductibleDisplay || 0)

      // set state from reducer
      if (emptyQuoteData && Object.keys(emptyQuoteData.propertyCoverageInfo).length > 0) {
        if (emptyQuoteData.plan === 'Custom') {
          var propertyCoverageInfo = emptyQuoteData.propertyCoverageInfo
          setCustomBldgCvg(propertyCoverageInfo.n_BuildingCvg);
          setCustomContentCvg(propertyCoverageInfo.n_ContentCvg);
          setCustomBldgDed(parseFloat(propertyCoverageInfo.s_BuildingDeductible.replace('FLDDED', '')) || 0);
          setCustomContentDed(parseFloat(propertyCoverageInfo.s_ContentDeductible.replace('FLDDED', '')) || 0);

          cvgData.totalCoverageBuilding = customBldgCvg;
          cvgData.totalCoverageContents = customContentCvg;
          cvgData.buildingDeductibleDisplay = customBldgDed;
          cvgData.contentsDeductibleDisplay = customContentDed;
        }
      }

      // To Call Pivot Api For Various Plan at the same time
      getPivotApiRequest(cvgData, 'Custom')
    }
  }, [customCvg]);
  // set deductible options
  useEffect(() => {
    if (emptyQuoteData && Object.keys(deductible).length > 0) {
      setBDeductible(deductible.filter(val => [1000, 1500, 2000, 5000, 10000].includes(val.n_DeductibleIncident)));
      setCDeductible(deductible.filter(val => [1000, 2000, 5000, 10000].includes(val.n_DeductibleIncident)));
    }
  }, [deductible])

  const callPivotApi = () => {
    let tempCustomBldgDed = customBldgDed;
    let tempCustomContentDed = customContentDed;
    if (customBldgCvg > 0 && customBldgDed === 0) {
      tempCustomBldgDed = 2000;
      setCustomBldgDed(2000);
    }
    if (customContentCvg > 0 && customContentDed === 0) {
      tempCustomContentDed = 2000;
      setCustomContentDed(2000);
    }

    const customCvgData = customCvg;
    customCvgData.totalCoverageBuilding = customBldgCvg;
    customCvgData.totalCoverageContents = customContentCvg;
    customCvgData.buildingDeductibleDisplay = tempCustomBldgDed;
    customCvgData.contentsDeductibleDisplay = tempCustomContentDed;
    getPivotApiRequest(customCvgData, 'Custom');
    setIsCustomChanged(false);
  };
  // Validate Coverage Max Amount
  const validateCoverage = (cvgType) => {
    let callApi = true;
    let tempBldgCvg = 0;
    let tempContentCvg = 0;
    if (cvgType === 'BUILDING') {
      tempBldgCvg = 1000 * Math.ceil(customBldgCvg / 1000);
      setCustomBldgCvg(tempBldgCvg);
    }
    if (cvgType === 'CONTENT') {
      tempContentCvg = 1000 * Math.ceil(customContentCvg / 1000);
      setCustomContentCvg(tempContentCvg);
    }
    const maxAmount = (occupancy) => {
      switch (occupancy) {
        case 'SINGLEFAMILY20':
        case 'TWOFOURFAMILY20':
        case 'RESIDENTIALUNIT20':
        case 'RESIDENTIALMOBILEHOME20':
          return {building: 250000, content: 100000};
        case 'OTHERRESIDENTIAL20':
          return {building: 500000, content: 100000};
        case 'NONRESIDENTIALUNIT20':
        case 'NONRESIDENTIALBUILD20':
        case 'NONRESIDENTIALMOBILEHOME20':
          return {building: 500000, content: 500000};
        case 'RESIDENTIALCONDOBUILD20':
          return {building: emptyQuoteData.propertyInfo.RCE, content: 100000};
        default:
          return {building: 500000, content: 500000};
      }
    };
    const {building, content} = maxAmount(emptyQuoteData.propertyInfo.s_Occupancy);

    if (cvgType === 'BUILDING' && tempBldgCvg > building) {
      setIsLimitExceed(true);
      callApi = false;
    }
    if (cvgType === 'CONTENT' && tempContentCvg > content) {
      setIsLimitExceed(true);
      callApi = false;
    }
    if (
      emptyQuoteData.propertyInfo.s_IsNewPurchase === "YES" &&
      emptyQuoteData.propertyInfo.isSellerDec === "YES" &&
      tempBldgCvg === 0
    ) {
      callApi = false;
      Swal.fire({
        title: "Warning",
        text: t("Annual cap increase discount is not available for policies without building coverage"),
        icon: "warning",
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Continue",
        allowOutsideClick: false,
      }).then((result) => {
        if (result.isConfirmed) {
          setIsCustomChanged(true);
          return false;
        }
      });
    }

    if (["RESIDENTIALCONDOBUILD20"].includes(emptyQuoteData.propertyInfo.s_Occupancy)) {
      if (tempBldgCvg === 0) {
        callApi = false;
        Swal.fire({
          title: "Validation",
          text: t("Building Coverage amount can not be 0 for residential condo building occupancy!"),
          icon: "error",
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Change",
          allowOutsideClick: false,
        }).then((result) => {
          if (result.isConfirmed) {
            return false;
          }
        });
      }

      const val1 = 250000;
      const val2 = 250000 * emptyQuoteData.propertyInfo.s_NoOfUnits;
      if (
        cvgType === "BUILDING" &&
        tempBldgCvg !== "" &&
        ((val1 < val2 && tempBldgCvg > val1) || (val2 < val1 && tempBldgCvg > val2))
      ) {
        setIsLimitExceed(true);
        callApi = false;
      }
      if (
        cvgType === "CONTENT" &&
        tempContentCvg !== "" &&
        tempContentCvg > 100000
      ) {
        setIsLimitExceed(true);
        callApi = false;
      }
    }

    if (callApi) {
      setIsCustomChanged(true);
    }
  }

  /**
   * handle Change in Custom plan
   */
  const handleCustomChange = (key, value) => {
    if (!value) {
      value = 0;
    }
    switch (key) {
      case 'customBldgCvg':
        setCustomBldgCvg(value);
        if (['RESIDENTIALBLDG', 'NONRESIDENTIALBLDG'].includes(emptyQuoteData.viewType)) {
          if (value === 0 && customBldgDed > 0) {
            setCustomBldgDed(value);
          }
        }
        break;
      case 'customContentCvg':
        setCustomContentCvg(value)
        if (value === 0 && customBldgDed > 0) {
          setCustomContentDed(value);
        }
        break;
      case 'customContentDed':
        setCustomContentDed(value);
        if (value === 0 && customContentCvg > 0) {
          setCustomContentCvg(value);
        }
        break;
      case 'customBldgDed':
        setCustomBldgDed(value);
        break;
    }
    if (isDedChange) {
      setIsDedChange(false);
    }
  }

  const validateFields = (value) => {
    let temp = false;
    if (["", null].includes(value)) {
      temp = true;
    }
    setValidated(temp);
    return temp;
  }

  /**
   * To Get Zome Determination Details Based on Address
   */
  const callZoneDetermination = async (cvgData, planType, uuid) => {
    if (emptyQuoteData && emptyQuoteData.personAddr) {
      let callZD = true;
      if (Object.keys(ZDDetailsFromAddress).length > 0) {
        if (ZDDetailsFromAddress && ZDDetailsFromAddress.status === 'completed') {
            callZD = false;
          }
      }
      if (callZD) {
        const reqJson = {
          'addressLine1': emptyQuoteData.personAddr.s_AddressLine1,
          'addressLine2': emptyQuoteData.personAddr.s_AddressLine2,
          'city': emptyQuoteData.personAddr.s_CityName,
          'state': emptyQuoteData.personAddr.s_StateName,
          'zipCode': emptyQuoteData.personAddr.s_PostalCode,
          'zipPlus4': emptyQuoteData.personAddr.s_PostalCodeSuffix,
          'latitude': emptyQuoteData.personAddr.s_Latitude,
          'longitude': emptyQuoteData.personAddr.s_Longitude,
          'uniqueKey': localStorage.getItem('uniqueKey')
        }
        // const code = 'ZONE_DETERMINATION';
        // const requestCode = 'ZONE_DETERMINATION_MASSIVECERTS';
        // const request = { 'code': requestCode, 'requestParam': reqJson, 'sourceId': 0 };
        // Call Api
        setLoading(true)
        await dispatch(getZoneDetermination(reqJson)).then(async (res) => {
          setLoading(false);
          if (res.status && res.data.status && ['completed'].includes(res.data.status)) {
            await setzDDetails(res.data);
          } else {
            Swal.fire({
              title: res.data.status,
              text: res.data.description,
              icon: 'warning',
              showCancelButton: false,
              confirmButtonColor: '#3085d6',
              confirmButtonText: 'Close',
              allowOutsideClick: false
            });
          }
        }).catch(() => {
          setLoading(false);
          toast.error(t('Something Went Wrong!'));
          return false;
        });
      }

      // common
      const emptyQuote = emptyQuoteData;
      emptyQuote.propertyCoverageInfo.n_BuildingCvg = cvgData.totalCoverageBuilding
      emptyQuote.propertyCoverageInfo.n_ContentCvg = cvgData.totalCoverageContents
      emptyQuote.propertyCoverageInfo.s_BuildingDeductible = 'FLDDED' + cvgData.buildingDeductibleDisplay
      emptyQuote.propertyCoverageInfo.s_ContentDeductible = 'FLDDED' + cvgData.contentsDeductibleDisplay
      emptyQuote.plan = planType
      emptyQuote.premium = cvgData.premium;
      emptyQuote.netPremium = cvgData.netPremium;
      emptyQuote.fees = cvgData.fees;
      emptyQuote.uniqueKey = localStorage.getItem('uniqueKey');
      emptyQuote.uuid = uuid;

      dispatch(getEditEmptyQuoteData(emptyQuote));

      const reqJson = { ...initApiObject }; // Copy
      reqJson.buildingDeductible = cvgData.buildingDeductible
      reqJson.contentsDeductible = cvgData.contentsDeductible
      reqJson.coverageAmountType = cvgData.coverageAmountType
      reqJson.iccPremium = cvgData.iccPremium
      reqJson.industryReplacementCost = cvgData.industryReplacementCost
      reqJson.totalCoverageBuilding = cvgData.totalCoverageBuilding
      reqJson.totalCoverageContents = cvgData.totalCoverageContents
      reqJson.whichSection = 'BUYPLAN';
      reqJson.uniqueKey = localStorage.getItem('uniqueKey');
      // To update
      dispatch(updateInitQuoteApi(reqJson));

      // Route To Mortgagee Screen to Add Mortgagee on Waiting Period Loan Transaction & Bill-To is First Mortgagee
      let navigate = '/flood/quote';
      if (emptyQuoteData.propertyInfo.s_PolicyWaitingPeriod === 'LOANNOWAIT' && billTo === 'FIRSTMORTGAGE') {
        navigate = '/flood/instantquote/step/14';
      }
      navigation(navigate);
    }
  }

  // To update selected plan details into the quote details
  const updatePlanQuoteData = (planType) => {
    if (!validateFields(billTo)) {
      let cvgData = {}, uuid = '';
      cvgData = basicCvg
      if (planType === 'Basic') {
        cvgData.premium = basicPrem;
        cvgData.netPremium = basicNetPrem;
        cvgData.fees = basicPremFees;
        cvgData.totalCoverageBuilding = basicBuildingCvg;
        cvgData.totalCoverageContents = basicContentsCvg;
        cvgData.buildingDeductibleDisplay = basicBuildingDed;
        cvgData.contentsDeductibleDisplay = basicContentsDed;
        uuid = basicCvgResponse.data.transactionId;
      }
      if (planType === 'Recommended') {
        cvgData.premium = recommendedPrem;
        cvgData.netPremium = recommendedNetPrem;
        cvgData.fees = recommendedPremFees;
        cvgData.totalCoverageBuilding = recommendedBuildingCvg;
        cvgData.totalCoverageContents = recommendedContentsCvg;
        cvgData.buildingDeductibleDisplay = recommendedBuildingDed;
        cvgData.contentsDeductibleDisplay = recommendedContentsDed;
        uuid = recommendedCvgResponse.data.transactionId;
      }
      if (planType === 'Custom') {
        cvgData.premium = customPrem;
        cvgData.netPremium = customNetPrem;
        cvgData.fees = customPremFees;
        cvgData.totalCoverageBuilding = customBldgCvg;
        cvgData.totalCoverageContents = customContentCvg;
        cvgData.buildingDeductibleDisplay = customBldgDed;
        cvgData.contentsDeductibleDisplay = customContentDed;
        uuid = customCvgResp.data.transactionId;
      }
      if (Object.keys(cvgData).length > 0) {
        // To Get Zone Determination Details From Address
        callZoneDetermination(cvgData, planType, uuid);
      }
    }
  }

  /**
   * go back to previous screen
   */
  const handlePrevious = () => {
    if (emptyQuoteData.propertyInfo.s_IsNewPurchase === 'YES' && emptyQuoteData.propertyInfo.s_FloodConstructionType === 'FLDPREFIRM') {
      navigation('/flood/instantquote/step/11');
    } else if (['SLABONGRADE20', 'BASEMENT20', 'ELEVATEDWITHOUTENCLOSURE20'].includes(emptyQuoteData.propertyInfo.s_Foundation) && emptyQuoteData.propertyInfo.s_isValidElevationCert === 'YES') {
      navigation('/flood/instantquote/step/8');
    } else if (['X', 'B', 'C', 'A99', 'D'].includes(emptyQuoteData.propertyInfo.s_FloodZone) && ['SLABONGRADE20', 'BASEMENT20', 'ELEVATEDWITHOUTENCLOSURE20'].includes(emptyQuoteData.propertyInfo.s_Foundation)) {
      navigation('/flood/instantquote/step/8');
    } else if (!['X', 'B', 'C', 'A99', 'D'].includes(emptyQuoteData.propertyInfo.s_FloodZone)) {
      navigation('/flood/instantquote/step/8');
    } else {
      navigation('/flood/instantquote/step/11');
    }
  }
  const privateOptions = {
    floodCoverage: privateProducts.privateproduct,
  };

  const {t} = useTranslation();
  const getCoverageTab = (title) => {
    return <Row className="flood-coverage-section">
      <Col md={12} className="mb-3">
        <h5>{title}</h5>
      </Col>
      <Col md={3}>
        <Row>
          <Col md={7} xs={12}>
            <Form validated={validated}>
              <Form.Select
                className="myform-input"
                id="flood-pricing-plan-select"
                name="s_BillTo"
                parentobject="policyInfo"
                title={t('Bill to')}
                value={billTo}
                onChange={(e) => {
                  handleChange(e);
                  setBillTo(e.currentTarget.value);
                }}
                required
              >
                <option value=''>{t('Select')}</option>
                {
                  Object.keys(floodSystemDD).length > 0 && floodSystemDD.BILLTOFLOOD.map((v, i) => {
                    return <option key={i} value={v.s_AppCodeName}>{t(v.s_AppCodeNameForDisplay)}</option>
                  })
                }
              </Form.Select>
              <Form.Control.Feedback type="invalid">
                {t("Please Select Bill to Error")}
              </Form.Control.Feedback>
            </Form>
          </Col>
        </Row>
      </Col>
      <Col md={7} id="dashboard-table-section">
        <div className="table-main-title-div">
          <span className="table-main-title-name"> {t("Customize Coverage")}</span>
        </div>
        <table width="100%" className="coverage-table">
          <thead>
            <tr>
              <td/>
              <td>Coverages</td>
              <td>Deductibles</td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Building Coverage</td>
              <td>
                <NumberFormat
                  className="dashboard-group-input-flood"
                  style={{textAlign: "right", width: '150px'}}
                  type="text"
                  displayType={'input'}
                  prefix={'$'}
                  thousandSeparator={true}
                  allowNegative={false}
                  name="n_BuildingCvg"
                  title={t('Building Coverage Amount')}
                  parentobject="propertyCoverageInfo"
                  value={customBldgCvg}
                  min={0}
                  onValueChange={(value) => handleCustomChange('customBldgCvg', value.floatValue)}
                  onBlur={() => validateCoverage('BUILDING')}
                  autoComplete="off"
                  disabled={isBldgCvgDisable}
                />
              </td>
              <td>
                <Form.Select
                  // className="dashboard-group-input-flood"
                  style={{width: '150px', display: 'inline'}}
                  title={t('Building Deductible')}
                  parentobject="propertyInfo"
                  value={customBldgDed}
                  onChange={(e) => handleCustomChange('customBldgDed', e.currentTarget.value)}
                  disabled={isBldgCvgDisable}
                  onBlur={() => validateCoverage('BUILDING')}
                >
                  <option value={0}>{0}</option>
                  {
                    bDeductible && Object.keys(bDeductible).length > 0 && bDeductible.map((v, i) => {
                      return <option key={i} value={v.n_DeductibleIncident}>{v.n_DeductibleIncident}</option>
                    })
                  }
                </Form.Select>
              </td>
            </tr>
            <tr>
              <td>Contents Coverage</td>
              <td>
                <NumberFormat
                  className="dashboard-group-input-flood"
                  style={{textAlign: "right", width: '150px'}}
                  type="text"
                  displayType={'input'}
                  prefix={'$'}
                  thousandSeparator={true}
                  allowNegative={false}
                  name="n_ContentCvg"
                  title={t('Content Coverage Amount')}
                  parentobject="propertyCoverageInfo"
                  value={customContentCvg}
                  min={0}
                  onValueChange={(value) => handleCustomChange('customContentCvg', value.floatValue)}
                  onBlur={() => validateCoverage('CONTENT')}
                  autoComplete="off"
                />
              </td>
              <td>
                <Form.Select
                  // className="dashboard-group-input-flood"
                  style={{width: '150px', display: 'inline'}}
                  title={t('Content Deductible')}
                  parentobject="propertyInfo"
                  value={customContentDed}
                  onChange={(e) => handleCustomChange('customContentDed', e.currentTarget.value)}
                  onBlur={() => validateCoverage('CONTENT')}
                >
                  <option value={0}>{0}</option>
                  {
                    cDeductible && Object.keys(cDeductible).length > 0 && cDeductible.map((v, i) => {
                      return <option key={i} value={v.n_DeductibleIncident}>{v.n_DeductibleIncident}</option>
                    })
                  }
                </Form.Select>
              </td>
            </tr>
          </tbody>
        </table>
      </Col>
    </Row>
  }
  const nfipData = {
    basicPrem, basicRCV, recommendedPrem, recommendCV, customPrem, customRCV, basicBuildingCvg, recommendedBuildingCvg,
    customBldgCvg, basicContentsCvg, recommendedContentsCvg, customContentCvg, basicBuildingDed, recommendedBuildingDed,
    customBldgDed, basicContentsDed, recommendedContentsDed, customContentDed
  }
  return (
    <LoadingOverlay>
      <Loader loading={loading || apiLoading}/>
      {
        !loading && (
          privateOptions[privateProducts.selectedTab]?.length > 0 ?
          <>
            <Row>
              <Col md={12} className="custom-tabs">
                <Tabs defaultActiveKey={privateProducts.selectedTab} onSelect={(k) => setSelectedTab(k)}>
                  <Tab eventKey="floodCoverage" title={t("Flood Coverage")}>
                    {getCoverageTab('Flood Coverage Selection')}
                  </Tab>
                </Tabs>
              </Col>
            </Row>
            {
              privateProducts.selectedTab === 'floodCoverage' && privateProducts.nfipproduct.length > 0 && (
                <Row>
                  <Col md={12} id="dashboard-table-section">
                    <div className="table-main-title-div">
                      <span className="table-main-title-name"> {t("National Flood Insurance Program")}</span>
                    </div>
                    <div className="flood-coverage-section"
                         style={{borderTopRightRadius: '0px', paddingBottom: '20px'}}>
                      {
                        privateProducts.nfipproduct.map((product, key) => (
                          <NfipProductItem
                            key={key}
                            data={{...product, ...nfipData, isExpanded: product.expandedCoverage}}
                            updatePlanQuoteData={updatePlanQuoteData}
                          />
                        ))
                      }
                    </div>
                  </Col>
                </Row>
              )
            }
            <Row className="animated fadeIn">
              <Col md={12} id="dashboard-table-section">
                <div className="table-main-title-div">
                  <span className="table-main-title-name"> {t("Private Product Options")}</span>
                </div>
                <div className="flood-coverage-section"
                     style={{borderTopRightRadius: '0px', paddingBottom: '20px'}}>
                  {
                    privateOptions[privateProducts.selectedTab]?.map(
                      (
                        privateOption, key) => {
                        if (!privateOption || !privateOption.isShow) return null
                        return (
                          <PrivateProductItem
                            key={key}
                            data={privateOption}
                            isLastItem={privateOptions.length === key + 1}
                            getExcessFloodQuote={getExcessFloodQuote}
                          />
                        )
                      }
                    )
                  }
                </div>
              </Col>
            </Row>
            <Row className="mt-3">
              <Col md={12} className="d-flex justify-content-between">
                <Button color={"primary"} size={"sm"} onClick={()=>handlePrevious()}>
                  Previous
                </Button>
              </Col>
            </Row>
          </>
            :(
              <section className="pricing-details-section">
                {
                  Object.keys(emptyQuoteData).length === 0 && <Navigate to={'/flood/instantquote/step/address'} />
                }
                <Row className="justify-content-center">
                  <Col
                    xs="12"
                    sm="12"
                    md="12"
                    lg="12"
                    xl="12"
                    className="pricing-page-heading"
                  >
                    <Row className="justify-content-center">
                      <Col xs="12" sm="12" md="12" lg="12" xl="12">
                        <h5>{t('Your Coverage Options')}</h5>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row className="justify-content-center">
                  <Col
                    xs="12"
                    sm="12"
                    md="12"
                    lg="8"
                    xl="8"
                    className="pricing-table-main"
                  >
                    <Table responsive borderless className="pricing-table">
                      <thead>
                      <tr>
                        <th className="flood-pricing-col1">
                          <span style={{ visibility: "hidden" }}>.</span>
                          <div className="pay-plan-col-main">
                            <span className="flood-pay-plan-h">{t('Bill to')}:</span>
                            <Form validated={validated}>
                              <Form.Select
                                className="myform-input"
                                id="flood-pricing-plan-select"
                                name="s_BillTo"
                                parentobject="policyInfo"
                                title={t('Bill to')}
                                value={billTo}
                                onChange={(e) => {
                                  handleChange(e);
                                  setBillTo(e.currentTarget.value);
                                }}
                                required
                              >
                                <option value=''>{t('Select')}</option>
                                {
                                  Object.keys(floodSystemDD).length > 0 && floodSystemDD.BILLTOFLOOD.map((v, i) => {
                                    return <option key={i} value={v.s_AppCodeName}>{t(v.s_AppCodeNameForDisplay)}</option>
                                  })
                                }
                              </Form.Select>
                              <Form.Control.Feedback type="invalid">
                                {t("Please Select Bill to Error")}
                              </Form.Control.Feedback>
                            </Form>
                          </div>
                        </th>
                        <th className="flood-pricing-col2">
                          <div>
                            <h5>{t('Basic')}</h5>
                            <span className="per-month">
                        ${basicPrem} /{t('Year')}
                      </span>
                            <br />
                            <span>
                        RCVFEMA - ${basicRCV}
                      </span>
                          </div>
                        </th>
                        {
                          isRecommendedVisible === true && (
                            <th className="flood-pricing-col3">
                              <div>
                                <h5>{t('Premium')}</h5>
                                <span className="per-month">
                            ${recommendedPrem} /{t('Year')}
                          </span>
                                <br />
                                <span>
                            RCVFEMA - ${recommendCV}
                          </span>
                              </div>
                            </th>
                          )
                        }
                        <th className="flood-pricing-col4">
                          <div>
                            <h5>{t('Customize Your Way')}</h5>
                            <span className="per-month">
                        ${customPrem} /{t('Year')}
                      </span>
                            <br />
                            <span>
                        RCVFEMA - ${customRCV}
                      </span>
                          </div>
                        </th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr className="special-row">
                        <td>{t('Flood coverage')}</td>
                        <td></td>
                        <td></td>
                        <td></td>
                      </tr>
                      <tr>
                        <td>{t('Building')}</td>
                        <td>${basicBuildingCvg}</td>
                        {
                          isRecommendedVisible === true && (
                            <td>${recommendedBuildingCvg}</td>
                          )
                        }
                        <td>
                          <NumberFormat
                            className="dashboard-group-input-flood"
                            style={{ textAlign: "right", width: '150px' }}
                            type="text"
                            displayType={'input'}
                            prefix={'$'}
                            thousandSeparator={true}
                            allowNegative={false}
                            name="n_BuildingCvg"
                            title={t('Building Coverage Amount')}
                            parentobject="propertyCoverageInfo"
                            value={customBldgCvg}
                            min={0}
                            onValueChange={(value) => handleCustomChange('customBldgCvg', value.floatValue)}
                            onBlur={() => validateCoverage('BUILDING')}
                            autoComplete="off"
                            disabled={isBldgCvgDisable}
                          />
                        </td>
                      </tr>

                      <tr>
                        <td>{t('Contents')}</td>
                        <td>${basicContentsCvg}</td>
                        {
                          isRecommendedVisible === true && (
                            <td>${recommendedContentsCvg}</td>
                          )
                        }
                        <td>
                          <NumberFormat
                            className="dashboard-group-input-flood"
                            style={{ textAlign: "right", width: '150px' }}
                            type="text"
                            displayType={'input'}
                            prefix={'$'}
                            thousandSeparator={true}
                            allowNegative={false}
                            name="n_BuildingCvg"
                            title={t('Content Coverage Amount')}
                            parentobject="propertyCoverageInfo"
                            value={customContentCvg}
                            min={0}
                            onValueChange={(value) => handleCustomChange('customContentCvg', value.floatValue)}
                            onBlur={() => validateCoverage('CONTENT')}
                            autoComplete="off"
                          />
                        </td>
                      </tr>

                      <tr className="special-row">
                        <td>{t('Deductibles')}</td>
                        <td></td>
                        <td></td>
                        <td></td>
                      </tr>
                      <tr>
                        <td>{t('Building')}</td>
                        <td>${basicBuildingDed}</td>
                        {
                          isRecommendedVisible === true && (
                            <td>${recommendedBuildingDed}</td>
                          )
                        }
                        <td>
                          <Form.Select
                            // className="dashboard-group-input-flood"
                            style={{ width: '150px', display: 'inline' }}
                            title={t('Building Deductible')}
                            parentobject="propertyInfo"
                            value={customBldgDed}
                            onChange={(e) => handleCustomChange('customBldgDed', e.currentTarget.value)}
                            disabled={isBldgCvgDisable}
                          >
                            <option value={0}>{0}</option>
                            {
                              bDeductible && Object.keys(bDeductible).length > 0 && bDeductible.map((v, i) => {
                                return <option key={i} value={v.n_DeductibleIncident}>{v.n_DeductibleIncident}</option>
                              })
                            }
                          </Form.Select>
                        </td>
                      </tr>
                      <tr>
                        <td>{t('Contents')}</td>
                        <td>${basicContentsDed}</td>
                        {
                          isRecommendedVisible === true && (
                            <td>${recommendedContentsDed}</td>
                          )
                        }
                        <td>
                          <Form.Select
                            style={{ width: '150px', display: 'inline' }}
                            title={t('Content Deductible')}
                            parentobject="propertyInfo"
                            value={customContentDed}
                            onChange={(e) => handleCustomChange('customContentDed', e.currentTarget.value)}
                          >
                            <option value={0}>{0}</option>
                            {
                              cDeductible && Object.keys(cDeductible).length > 0 && cDeductible.map((v, i) => {
                                return <option key={i} value={v.n_DeductibleIncident}>{v.n_DeductibleIncident}</option>
                              })
                            }
                          </Form.Select>
                        </td>
                      </tr>

                      <tr>
                        <td
                          id="flood-pricing-buy-now"
                          style={{ display: "flex", justifyContent: "center" }}
                        >
                          <Button
                            id="primary-grey-btn-flood"
                            variant="outline-dark"
                            onClick={() => handlePrevious()}
                            className="previous-btn"
                          >
                            {t('Previous')}
                          </Button>
                        </td>
                        <td id="flood-pricing-buy-now">
                          <Button
                            id="primary-colored-btn"
                            className="primary"
                            onClick={() => updatePlanQuoteData('Basic')}
                          >
                            {" "}
                            {t('Select')}{" "}
                          </Button>
                        </td>
                        {
                          isRecommendedVisible === true && (
                            <td id="flood-pricing-buy-now">
                              {" "}
                              <Button
                                id="primary-colored-btn"
                                className="primary"
                                onClick={() => updatePlanQuoteData('Recommended')}
                                autoFocus={true}
                              >
                                {" "}
                                {t('Select')}{" "}
                              </Button>
                            </td>
                          )
                        }
                        <td id="flood-pricing-buy-now" className="range-col">
                          <Button
                            id="primary-colored-btn"
                            className="primary"
                            onClick={() => updatePlanQuoteData('Custom')}
                          >
                            {" "}
                            {t('Select')}{" "}
                          </Button>
                        </td>
                      </tr>
                      </tbody>
                    </Table>
                  </Col>
                </Row>
              </section>
            )
        )
      }
    </LoadingOverlay>
  );
}
export default PrivateFloodPlan;
