import React, { useState, Fragment, useEffect } from "react";
import {
  Row,
  Col,
  Form,
  Button,
  Table,
  Modal,
} from "react-bootstrap";
import Select from "react-select";
import GooglePlacesAutocomplete, { geocodeByAddress } from "react-google-places-autocomplete";
import delIcon from "../../../../../assets/delete-icon.svg";
import ZipDetailsView from '../Modal/ZipDetailsView';
import { customStyles } from "../../../../../components/reactSelectCustomStyle";
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from "react-router-dom";
import { getSearchMortgagee, getEditEmptyQuoteData, getZipDetails } from "../../../../../services/floodService";
import { deletePolicyInterest } from "../../../../../services/quoteService";
import { useTranslation } from "react-i18next";
import { toast } from 'react-toastify';
import { LoadingOverlay, Loader } from "react-overlay-loader";
import "react-overlay-loader/styles.css";
import "./add-mortage.scss";
import "./flood-add-mortages.scss";
import MortgageeSearch from "./MortgageeSearch";
import FloodNewMortgageeAdd from "./FloodNewMortgageeAdd";
import { tab } from "@testing-library/user-event/dist/tab";

const FloodAddMortages = (props) => {
  const { poTransPk } = useParams();
  const { showMortgagee, wantAddLender, isBind = false, isDataChanged = null } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { emptyQuoteData, apiLoading } = useSelector(state => state.flood);
  const key = process.env.GOOGLE_API_KEY
  const initOptions = [
    { value: "PRIMARY", label: t("First Mortgagee") },
    { value: "SECOND", label: t("Second Mortgagee") },
    { value: "LOSSPAYEE", label: t("Loss Payee") },
    { value: "DISASTER", label: t("Disaster") }
  ];
  const mortgageeJson = {
    "n_PolicyAddInterest_PK": null,
    "n_POTransaction_FK": null,
    "n_PersonInfoId_PK": null,
    "s_PersonType": null,
    "s_MortgageeType": null,
    "s_LoanNo": null,
    "s_AddionalIntType": null,
    "s_RelationType": null,
    "s_HouseholdType": null,
    "s_isDeleted": "N",
    "n_CorePersonPK": null,
    "n_CorePersonAddressPK": null,
    "n_CreatedUser": null,
    "d_CreatedDate": null,
    "n_UpdatedUser": null,
    "d_UpdatedDate": null,
    "person": {
      "n_PersonInfoId_PK": null,
      "s_PersonUniqueId": null,
      "s_EntityType": "PERSON",
      "s_FirstName": null,
      "s_MiddleName": null,
      "s_LastOrganizationName": null,
      "s_FullLegalName": null,
      "d_BirthDate": null,
      "s_Occupation": null,
      "s_IsMailingDiff": null,
      "s_IsTempMailing": null,
      "n_CreatedUser": null,
      "d_CreatedDate": null,
      "n_UpdatedUser": null,
      "d_UpdatedDate": null
    },
    "address": {
      "n_PersonAddressesId_PK": null,
      "n_PersonInfoId_PK": null,
      "s_AddressTypeCode": "MAILING",
      "s_IsDefaultAddress": "Y",
      "s_AddressLine1": null,
      "n_CountyId_FK": null,
      "n_CityId_FK": null,
      "n_StateId_FK": null,
      "n_CountryId_FK": null,
      "s_PostalCode": null,
      "s_HouseNo": null,
      "s_StreetName": null,
      "s_PostalCodeSuffix": null,
      "s_Latitude": null,
      "s_Longitude": null,
      "s_CountryName": null,
      "s_StateName": null,
      "s_CountyName": null,
      "s_CityName": null,
      "s_ParcelId": null,
      "n_CreatedUser": null,
      "d_CreatedDate": null,
      "n_UpdatedUser": null,
      "d_UpdatedDate": null
    }
  }

  const [show, setShow] = useState(false);
  const [selectInterest, setSelectInterest] = useState(initOptions[0]);
  const [selectedName, setSelectedName] = useState(null);
  const [tableData, setTableData] = useState([]);
  const [options, setOptions] = useState([]);
  const [interestOptions, setIntrestOptions] = useState([]);
  const [loanNo, setLoanNo] = useState('');
  const [search, setSearchProperty] = useState(null);
  const [streetName, setStreetName] = useState('');
  const [googlePlace, setGooglePlace] = useState('');
  const [mortgageeData, setMortgageeData] = useState(mortgageeJson);
  const [isZipSet, setIsZipSet] = useState(false);
  const [showZipModal, setShowZipModal] = useState(false);
  const [multipleCity, setMultipleCity] = useState(null);
  const [showMortgageeSearchModal, setShowMortgageeSearchModal] = useState(false);

  // trigger to update loan number on respective row
  const handleInputChange = (index, event) => {
    const values = [...tableData];
    const updatedValue = event.target.name;
    values[index][updatedValue] = event.target.value;
    // show save & rate if change in mortgagee
    visibleSaveNRate(true);
    // setTableData(values);
  };

  // to update mortgagee data
  const inputChange = (event) => {
    const { name, value, attributes } = event.target;
    const parentKey = attributes.parentobject.value;
    if (parentKey.length > 0) {
      mortgageeData[parentKey][name] = value;
    } else {
      mortgageeData[name] = value;
    }
    setMortgageeData(mortgageeData);
    setIsZipSet(!isZipSet);
  };

  // to push selected mortgagee date
  const handleSaveInterest = () => {
    if (selectInterest.value === 'DISASTER') {
      var fullName = mortgageeData.person.s_FirstName || '';
      if (fullName && fullName !== '') {
        if (mortgageeData.person.s_LastOrganizationName && mortgageeData.person.s_LastOrganizationName !== '') {
          fullName += ' ';
          fullName += mortgageeData.person.s_MiddleName || '';
        }
        fullName += ' ';
        fullName += mortgageeData.person.s_LastOrganizationName || '';
      }
      mortgageeData.person.s_FullLegalName = fullName;
      mortgageeData.s_PersonType = selectInterest.value;
      mortgageeData.s_MortgageeType = selectInterest.label;
    }
    var temp = [...tableData];
    temp.push(mortgageeData);
    setTableData(temp);
    setMortgageeData(mortgageeJson);
    // show save & rate if change in mortgagee
    visibleSaveNRate(true);
    setShow(false);
  };

  const tableNewData = (event) => {
      setTableData(event);
      setShow(false);
  };

  // trigger to remove delete row from the table details
  const handleRemoveFields = async (index, row) => {
    if (await deleteInterest(row.n_PolicyAddInterest_PK)) {
      const values = [...tableData];
      // to delete FIRST Mortgagee
      if (values[index].s_PersonType === 'PRIMARY') {
        var secondMortgagee = values.filter(x => x.s_PersonType === 'SECOND');
        if (secondMortgagee.length > 0) {
          values[index] = secondMortgagee[0];
          values[index].s_MortgageeType = 'First Mortgagee';
          values[index].s_PersonType = 'PRIMARY';
          index = values.findIndex(x => x.s_PersonType === 'SECOND');
        }
      }

      if (index === -1) {
        index = 0;
      }

      values.splice(index, 1);
      setTableData(values);
      setMortgageeData(mortgageeJson);
    }
  };
  
  /**
   * To delete policy additional interest details
   * @param {int} policyAddiInterestPK 
   * @returns boolean
   */
  const deleteInterest = async (policyAddiInterestPK) => {
    if (poTransPk) {
      const params = {
        transPK: poTransPk,
        n_PolicyAddInterest_PK: policyAddiInterestPK
      }
      var res = await dispatch(deletePolicyInterest(params));
      if (res.success && res.data.Status === 'Y') {
        toast.success('Entry Deleted.');
        return true;
      }
    } else {
      return true;
    }
  }

  // trigger to fetch list of clients
  const handleChange = (event) => {
    setSelectedName(event);
    if (event !== '' && event.length >= 4) {
      const params = { searchField: event }
      dispatch(getSearchMortgagee(params)).then((res) => {
        const data = res.data.map(({ s_FullLegalName: label, n_PersonAddressesId_PK: value, ...rest }) => ({ label, value, ...rest }));
        data.map((item) => {
          item.s_FullLegalName = item.label;
          item.n_PersonAddressesId_PK = item.value;
          item.label = item.s_FullLegalName + ' - ' + item.s_AddressLine1 + ',' + item.s_CityName + ',' + item.s_CountyName + ',' + item.s_StateCode + ',' + item.s_PostalCode;
          return item;
        })
        setOptions(data);
      })
    }
  };

  // trigger to update selected client details
  const handleOnChange = (event) => {
    setSelectedName(event);
    const values = mortgageeData;
    values.n_PersonInfoId_PK = event.n_PersonInfoId_PK;
    values.s_PersonType = selectInterest.value;
    values.s_MortgageeType = selectInterest.label;
    values.s_LoanNo = loanNo;
    // person
    values.person.n_PersonInfoId_PK = event.n_PersonInfoId_PK;
    values.person.s_EntityType = event.s_EntityType;
    values.person.s_FullLegalName = event.s_FullLegalName;
    // address
    values.address.n_PersonAddressesId_PK = event.n_PersonAddressesId_PK;
    values.address.n_PersonInfoId_PK = event.n_PersonInfoId_PK;
    values.address.s_AddressTypeCode = event.s_AddressTypeCode;
    values.address.s_AddressLine1 = event.s_AddressLine1;
    values.address.n_CityId_FK = event.n_CityId_FK;
    values.address.n_CountyId_FK = event.n_CountyId_FK;
    values.address.n_StateId_FK = event.n_StateId_FK;
    values.address.n_CountryId_FK = event.n_CountryId_FK;
    values.address.s_PostalCode = event.s_PostalCode;
    values.address.s_StateName = event.s_StateCode;
    values.address.s_HouseNo = event.s_HouseNo;
    values.address.s_StreetName = event.s_StreetName;
    values.address.s_CountyName = event.s_CountyName;
    values.address.s_CityName = event.s_CityName;
    setMortgageeData(values);
  };

  // trigger to set selected address street name
  const handleGooglAutoComplete = (val, status) => {
    if (val) {
      if (status.action === "input-change") {
        setStreetName(val);
      } else if (["set-value", "menu-close", "input-blur", "menu-close"].includes(status.action)) {
        setStreetName(streetName.toUpperCase());
      }
    }
  }

  // set state
  useEffect(() => {
    if (Object.keys(emptyQuoteData).length > 0) {
      setTableData(emptyQuoteData.mortgageeData || []);
    }
  }, [showMortgagee])

  // trigger to set mortgagee type options
  useEffect(() => {
    let selectedInterestOptions = [];
    if (tableData.length === 0) {
      selectedInterestOptions = initOptions.filter(item => item.value !== 'SECOND');
    } else {
      tableData.filter(function (val) {
        if (val.s_PersonType === 'PRIMARY') {
          selectedInterestOptions = initOptions.filter(item => item.value !== 'PRIMARY');
        }
        if (val.s_PersonType === 'SECOND') {
          selectedInterestOptions = selectedInterestOptions.filter(item => item.value !== 'SECOND');
        }
        if (selectedInterestOptions.length == 0) {
          selectedInterestOptions = initOptions.filter(item => item.value !== 'SECOND');
        }
        return selectedInterestOptions;
      });
    }
    setIntrestOptions(selectedInterestOptions);
    setSelectInterest(selectedInterestOptions[0]);
    // to update selected mortgagee details into the quote array
    if (emptyQuoteData && Object.keys(emptyQuoteData).length > 0) {
      const emptyQuote = emptyQuoteData;
      if(tableData && tableData.length > 0){
        emptyQuote.mortgageeData = tableData;
      }
      dispatch(getEditEmptyQuoteData(emptyQuote));
    }
  }, [show, tableData]);

  // trigger to update selected address
  useEffect(() => {
    if (search != null) {
      setStreetName(search.value.structured_formatting.main_text);
      getGeoCode(search.value.description);
      setGooglePlace(search);
    }
  }, [search]);

  // Inserting style only for address field
  useEffect(() => {
    document.head.insertAdjacentHTML("beforeend", `<style>input[id^='react-select-'] { opacity: 2 !important; width: 250px !important; }</style>`)
  }, [])

  // to clear all details on close modal
  useEffect(() => {
    if (!show) {
      setLoanNo('')
      setMortgageeData(mortgageeJson);
      setSearchProperty(null)
      setOptions([]);
      setStreetName(null)
      setGooglePlace(null)
      setSelectedName(null)
      setSelectInterest(initOptions[0])
    }
  }, [show]);

  const getGeoCode = (description) => {
    geocodeByAddress(description)
      .then(addr => {
        addr && addr[0].address_components.forEach(e => {
          if (e.types.includes('postal_code')) {
            getZipData(e.short_name);
          }
        });
      })
  }

  // get zip details 
  const getZipData = (zip) => {
    if (zip !== '') {
      dispatch(getZipDetails('10', zip, 'APPLICANT')).then((res) => {
        if (res.success === true) {
          var data = res.data;
          if (data.status === 'Y') {
            if (data.count === 1) {
              setZipDetailsData(res.data.zipData[0]);
            } else {
              showMultipleCityModal(data.zipData);
            }
          } else {
            toast.error(res.errorMsg);
          }
        }
      });
    } else {
      toast.error('Zip Code should not be empty!');
    }
  }

  // open Zip Modal
  const showMultipleCityModal = (zipData) => {
    setMultipleCity(<ZipDetailsView zipData={zipData} setZipDetailsData={setZipDetailsData} />)
    setShowZipModal(true);
  }

  // get selected zip data
  const setZipDetailsData = (zipData) => {
    const values = mortgageeData;
    var addressLine = zipData.s_AddressLine1
    if (search !== null) {
      addressLine = search.value.structured_formatting.main_text;
    }
    values.address.s_AddressLine1 = addressLine;
    values.address.s_PostalCode = zipData.s_ZipCode;
    values.address.n_Zipcodes_PK = zipData.n_Zipcodes_PK;
    values.address.s_StateName = zipData.s_StateCode;
    values.address.n_CityId_FK = zipData.n_CityId_PK;
    values.address.n_CountyId_FK = zipData.n_CountyId_FK;
    values.address.n_StateId_FK = zipData.n_StateId_PK;
    values.address.n_CountryId_FK = zipData.n_CountryId_FK;
    values.address.s_CityName = zipData.s_CityName;
    setMortgageeData(values);
    setIsZipSet(!isZipSet);
    setShowZipModal(false);
  }

  // trigger to open add mortgagee modal
  useEffect(() => {
    wantAddLender && setShow(wantAddLender);
  }, [wantAddLender])

  // show save & rate if change in mortgagee
  const visibleSaveNRate = (value) => {
    if (isDataChanged) {
      isDataChanged(value);
    }
  }

  return (
    <section className="add-mortage-section">
      {/* Form */}
      <Row className="justify-content-center flood-add-mortage-form-section">
        <Col xs="12" className="add-mortage-form">
          <div className="add-mortage-add-btn">
            <div></div>
            <Button
              variant="primary"
              id="primary-colored-btn"
              onClick={() => {setShow(true); visibleSaveNRate(true);}}
              disabled={isBind}
            >
              {" "}
              <span> + </span> {t('Add')}
            </Button>
          </div>
          <div className="add-mortage-table-div">
            <Table className="add-mortage-table" borderless responsive>
              <thead>
                <tr>
                  <th>{t('Name')}</th>
                  <th>{t('Lender Type')}</th>
                  <th>{t('Loan No')}.</th>
                  <th>{t('Action')}</th>
                </tr>
              </thead>
              <tbody>
                {tableData.length > 0 && (
                  <>
                    {tableData.map((field, index) => (
                      <tr key={index}>
                        <td>
                          <Form.Label
                            className="myform-label"
                            style={{ marginLeft: '10px' }}
                          >
                            {field.person.s_FullLegalName}
                          </Form.Label>
                        </td>
                        <td>
                          <Form.Label
                            className="myform-label"
                            style={{ marginLeft: '10px' }}
                          >
                            {field.s_MortgageeType}
                          </Form.Label>
                        </td>
                        <td>
                          {/* <Form.Control
                            className="myform-input"
                            type="text"
                            placeholder={t("Loan No")}
                            name="s_LoanNo"
                            parentobject=""
                            value={field.s_LoanNo}
                            onChange={(event) =>
                              handleInputChange(index, event)
                            }
                          /> */}
                          <Form.Label
                            className="myform-label"
                            style={{ marginLeft: '10px' }}
                          >
                            {field.s_LoanNo}
                          </Form.Label>
                        </td>
                        <td className="add-mortage-action-row">
                          <div className="add-mortage-action">
                            {
                              !isBind && (
                            <img
                              src={delIcon}
                              alt='Delete'
                              onClick={() => handleRemoveFields(index, field)}
                            />
                              )
                            }
                          </div>
                        </td>
                      </tr>
                    ))}
                  </>
                )}
              </tbody>
            </Table>
          </div>
        </Col>
      </Row>

      {/* Modal */}
      {
        show && (
          <Modal size="lg" show={show} className="add-mortage-modal">
            <Modal.Header id="add-mortage-modal-header">
              <Modal.Title className="text-white">{t('Add Mortgagee')}</Modal.Title>
            </Modal.Header>
            <Modal.Body className="add-mortage-modal-body">
              <LoadingOverlay >
                <Loader loading={apiLoading} />
                <Row>
                  <Col xs="12" sm="12" md="12" lg="6" xl="6" className="mb-2">
                  <Form.Label className="myform-label">{t('Select  Mortgagee')}</Form.Label>
                    <Select
                      options={interestOptions}
                      className="custom-select-autocomplete"
                      value={selectInterest}
                      style={{
                        marginBottom: "5px !important",
                      }}
                      onChange={(e) => {
                        setSelectInterest(e);
                      }}
                    />
                  </Col>
                </Row>
                {/* {(["PRIMARY", "SECOND", "LOSSPAYEE"].includes(selectInterest.value)) && (
                  <Row>
                    <Col xs="12" sm="12" md="12" lg="12" xl="12" className="mb-2"> */}
                      {/* <Select
                        options={options}
                        className="custom-select-autocomplete"
                        value={selectedName}
                        onChange={(e) => {
                          handleOnChange(e);
                        }}
                        onInputChange={(e) => { handleChange(e) }}
                        placeholder={t("Search Name")}
                      /> */}
                      {/* <Button
                        variant="primary"
                        className="mybtn"
                        onClick={() => setShowMortgageeSearchModal(true)}
                      >
                        {t('Search')}
                      </Button> */}
                    {/* </Col>
                  </Row>
                )} */}
                <Row>
                  {/* {selectInterest.value === "DISASTER" ? ( */}
                    {/* <Fragment> */}
                      {/* <Col xs="12" sm="12" md="12" lg="4" xl="4">
                        <Form.Group
                          controlId="formBasicEmail"
                          className="add-mortage-form-group"
                        >
                          <Form.Label className="myform-label">{t('First Name')}</Form.Label>
                          <Form.Control
                            className="myform-input"
                            type="text"
                            placeholder=""
                            name="s_FirstName"
                            parentobject="person"
                            value={mortgageeData.person.s_FirstName}
                            onChange={(e) => inputChange(e)}
                          />
                        </Form.Group>
                      </Col> */}
                      {/* <Col xs="12" sm="12" md="12" lg="4" xl="4">
                        <Form.Group
                          controlId="formBasicEmail"
                          className="add-mortage-form-group"
                        >
                          <Form.Label className="myform-label">
                            {t('Middle Name')}
                          </Form.Label>
                          <Form.Control
                            className="myform-input"
                            type="text"
                            placeholder=""
                            name="s_MiddleName"
                            parentobject="person"
                            value={mortgageeData.person.s_MiddleName}
                            onChange={(e) => inputChange(e)}
                          />
                        </Form.Group>
                      </Col> */}
                      {/* <Col xs="12" sm="12" md="12" lg="4" xl="4">
                        <Form.Group
                          controlId="formBasicEmail"
                          className="add-mortage-form-group"
                        >
                          <Form.Label className="myform-label">{t('Last Name')}</Form.Label>
                          <Form.Control
                            className="myform-input"
                            type="text"
                            placeholder=""
                            name="s_LastOrganizationName"
                            parentobject="person"
                            value={mortgageeData.person.s_LastOrganizationName}
                            onChange={(e) => inputChange(e)}
                          />
                        </Form.Group>
                      </Col> */}
                    {/* </Fragment> */}
                  {/* ) : ( 
                    // <Col xs="12">
                    //   <Form.Group
                    //     controlId="formBasicEmail"
                    //     className="add-mortage-form-group"
                    //   >
                    //     <Form.Label className="myform-label">{t('Name')}</Form.Label>
                    //     <Form.Control
                    //       className="myform-input"
                    //       type="text"
                    //       placeholder=""
                    //       disabled
                    //       name="s_FullLegalName"
                    //       parentobject="person"
                    //       value={mortgageeData.person.s_FullLegalName}
                    //       onChange={(e) => inputChange(e)}
                    //     />
                    //   </Form.Group>
                    // </Col>
                  // )}
                  {/* <Col xs="12">
                    <Form.Group
                      controlId="formBasicEmail"
                      className="add-mortage-form-group"
                    >
                      <Form.Label className="myform-label">
                        {t('Mailing Address')} 1{" "}
                      </Form.Label>
                      {
                        selectInterest.value === "DISASTER" ?
                          <GooglePlacesAutocomplete
                            apiKey={key}
                            selectProps={{
                              styles: customStyles,
                              inputValue: streetName,
                              value: googlePlace,
                              onInputChange: handleGooglAutoComplete,
                              onChange: setSearchProperty,
                              name: "googlePlacesStreetName",
                              id: "googlePlacesStreetName",
                              placeholder: "Enter a location",
                            }}
                          />
                          :
                          <Form.Control
                            className="myform-input"
                            type="text"
                            placeholder=""
                            name="s_AddressLine1"
                            parentobject="address"
                            value={mortgageeData.address.s_AddressLine1}
                            onChange={(e) => inputChange(e)}
                            disabled={
                              selectInterest === "PRIMARY" ||
                              selectInterest === "SECOND" ||
                              selectInterest === "LOSSPAYEE"
                            }
                          />
                      }
                    </Form.Group>
                  </Col> */}
                  {/* <Col xs="12">
                    <Form.Group
                      controlId="formBasicEmail"
                      className="add-mortage-form-group"
                    >
                      <Form.Label className="myform-label">
                        {t('Mailing Address')} 2{" "}
                      </Form.Label>
                      <Form.Control
                        className="myform-input"
                        type="text"
                        placeholder=""
                        disabled={(["PRIMARY", "SECOND", "LOSSPAYEE"].includes(selectInterest.value))}
                        name="s_AddressLine2"
                        parentobject="address"
                        value={mortgageeData.address.s_AddressLine2 || ''}
                        onChange={(e) => inputChange(e)}
                      />
                    </Form.Group>
                  </Col> */}
                  {/* <Col xs="12" sm="12" md="4" lg="4" xl="4">
                    <Form.Group
                      controlId="formBasicEmail"
                      className="add-mortage-form-group"
                    >
                      <Form.Label className="myform-label">{t('City')}</Form.Label>
                      <Form.Control
                        className="myform-input"
                        type="text"
                        placeholder=""
                        disabled={(["PRIMARY", "SECOND", "LOSSPAYEE"].includes(selectInterest.value))}
                        name="s_CityName"
                        parentobject="address"
                        value={mortgageeData.address.s_CityName}
                        onChange={(e) => inputChange(e)}
                      />
                    </Form.Group>
                  </Col> */}
                  {/* <Col xs="12" sm="12" md="4" lg="4" xl="4">
                    <Form.Group
                      controlId="formBasicEmail"
                      className="add-mortage-form-group"
                    >
                      <Form.Label className="myform-label">{t('State')}</Form.Label>
                      <Form.Control
                        className="myform-input"
                        type="text"
                        placeholder=""
                        disabled={(["PRIMARY", "SECOND", "LOSSPAYEE"].includes(selectInterest.value))}
                        name="s_StateName"
                        parentobject="address"
                        value={mortgageeData.address.s_StateName}
                        onChange={(e) => inputChange(e)}
                      />
                    </Form.Group>
                  </Col> */}
                  {/* <Col xs="12" sm="12" md="4" lg="4" xl="4">
                    <Form.Group
                      controlId="formBasicEmail"
                      className="add-mortage-form-group"
                    >
                      <Form.Label className="myform-label">{t('Zip Code')}</Form.Label>
                      <Form.Control
                        className="myform-input"
                        type="text"
                        placeholder=""
                        disabled={(["PRIMARY", "SECOND", "LOSSPAYEE"].includes(selectInterest.value))}
                        name="s_PostalCode"
                        parentobject="address"
                        value={mortgageeData.address.s_PostalCode}
                        onChange={(e) => inputChange(e)}
                        onBlur={(e) => getZipData(e.currentTarget.value)}
                      />
                    </Form.Group>
                  </Col> */}
                  {/* <Col xs="12" sm="12" md="4" lg="4" xl="4">
                    <Form.Group
                      controlId="formBasicEmail"
                      className="add-mortage-form-group"
                    >
                      <Form.Label className="myform-label">{t('Loan No')}</Form.Label>
                      <Form.Control
                        className="myform-input"
                        type="text"
                        placeholder=""
                        name="s_LoanNo"
                        parentobject=""
                        value={mortgageeData.s_LoanNo}
                        onChange={(e) => inputChange(e)}
                      />
                    </Form.Group>
                  </Col> */}
                </Row>
              </LoadingOverlay >
            </Modal.Body>
            <Modal.Footer className="add-mortage-modal-footer">
              <Button
                id="primary-grey-btn-flood"
                variant="outline-dark"
                onClick={() => setShow(false)}
                style={{
                  marginRight: "1rem",
                  paddingLeft: "20px",
                  paddingRight: "20px",
                }}
                disabled={apiLoading}
              >
                {t('Cancel')}
              </Button>

              <Button
                // onClick={() => handleSaveInterest()}
                onClick={() => {setShowMortgageeSearchModal(true);}}
                id="primary-colored-btn"
                style={{ paddingLeft: "20px", paddingRight: "20px" }}
                disabled={apiLoading}
              >
                {t('Next')}
              </Button>
            </Modal.Footer>
          </Modal>
        )
      }
      {
        showZipModal && (
          //  Zip Details Modal 
          <Modal size="md" show={showZipModal} className="zip-details-modal">
            <Modal.Body className="zip-details-modal-body">
              {multipleCity}
            </Modal.Body>
          </Modal>
        )
      }
      {
        showMortgageeSearchModal && (
          // <MortgageeSearch
          //   show={showMortgageeSearchModal}
          //   setShow={setShowMortgageeSearchModal}
          //   setSelectedMortgageeData={handleOnChange}
          // />

          <FloodNewMortgageeAdd
            show={showMortgageeSearchModal}
            setShow={setShowMortgageeSearchModal}
            selectInterest={selectInterest}
            tableNewData={tableNewData}
            tableData={tableData}
          />
        )
      }
    </section>
  );
};

export default FloodAddMortages;