import React, { useState, useEffect } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { connect } from "react-redux";
import { getEditEmptyQuoteData } from "../../../../../services/quoteService";
import {
  Alert,
  Box,
  Flex,
  ListItem,
  Select,
  Spacer,
  Switch,
  Text,
  UnorderedList,
} from "@chakra-ui/react";
import MultiSelect from "../../../create-star-quote/utils/ChakraMultiSelect";
import { useTranslation } from "react-i18next";

const UnderwritingQuestion = (props) => {
  const { t } = useTranslation("underwriting");
  const [quoteData] = useState(props.quoteData);
  const [underWritingQuestions, setUnderWritingQuestions] = useState([]);
  const [allAnswerData, setAllAnswerData] = useState(
    props.underWritingAnswers || {}
  );
  const [underWriting, setUnderWriting] = useState({
    underWritingQuestions: {
      anyOtherBusiness: false,
      inPoorCondition: false,
      inForeClosure: false,
      coverageDeclined: false,
      poolSecurity: false,
      activeFloodPolicy: false,
      anyRecreationalVehicle: false,
      anythingSignificant: false,
      propertyAcres: null,
      propertyExposures: false,
      hasSolarPanels: false,
      solarPanelLocation: "",
      additionalRisks: false,
      hasSwimmingPool: false,
      hasAnimals: false,
      hasTrampoline: false,
      hasTrampolineProtection: false,
    },
  });

  const updateAllAnswerData = (questionKey, value) => {
    const updatedAllAnswerData = { ...allAnswerData };
    const questionPk = underWritingQuestions.find(
      (q) => q.poolManagerKey === questionKey
    )?.n_PRPolicyAddStatQuestions_Pk;

    let questionAnswer = "";
    if (questionKey === "hasSolarPanels" && value) {
      questionAnswer = selectedSolarPanelLocation;
    } else if (
      questionKey === "additionalRisks" &&
      value &&
      Array.isArray(selectedRisk)
    ) {
      questionAnswer = selectedRisk.join(",");
    }

    if (questionPk) {
      const answerKey = `Question_${questionPk}_N`;
      updatedAllAnswerData[answerKey] = {
        ...updatedAllAnswerData[answerKey],
        s_PoAdditionalStatsAnswer: value ? "true" : "false",
        s_PoAdditionalStatsResponse: questionAnswer,
      };
      setAllAnswerData(updatedAllAnswerData);
    }
  };

  const [selectedSolarPanelLocation, setselectedSolarPanelLocation] =
    useState("");
  const [selectedRisk, setSelectedRisk] = useState([]);
  const [solarPanelLocation, setSolarPanelLocation] = useState([]);
  const [riskAssociated, setRiskAssociated] = useState([]);

  useEffect(() => {
    setSolarPanelLocation(props?.getBaseData?.STARSOLARPANELLOCATION || []);
    setRiskAssociated(props?.getBaseData?.STAROTHERRISKASSOCIATED || []);
  }, [props.getBaseData]);

  useEffect(() => {
    if (typeof selectedSolarPanelLocation === "string") {
      updateAllAnswerData("hasSolarPanels", true);
    }
  }, [selectedSolarPanelLocation]);

  useEffect(() => {
    updateAllAnswerData("additionalRisks", true);
  }, [selectedRisk]);

  useEffect(() => {
    if (underWriting.underWritingQuestions) {
      const hasAnimalClaim = underWriting.underWritingQuestions.hasAnimals &&
        Array.isArray(props.quoteData.lossHistoryData) &&
        props.quoteData.lossHistoryData.length > 0; 
      const newErrorMessages = { ...props.errorMessages };
      Object.entries(underWriting.underWritingQuestions).forEach(
        ([key, value]) => {
          // Check if the current key is "hasAnimals" and hasAnimalClaim is true
          if (
            (key === "hasAnimals" && hasAnimalClaim) ||
            (key !== "hasAnimals" && value)
          ) {
            newErrorMessages[key] = t(key);
          }
        }
      );
      props.setErrorMessages(newErrorMessages);
    }
  }, [underWriting.underWritingQuestions, props.quoteData.lossHistoryData]);

  useEffect(() => {
    if (
      quoteData?.lossHistoryData &&
      underWriting.underWritingQuestions.hasAnimals
    ) {
      const hasAnimalClaim =
        Array.isArray(props.quoteData.lossHistoryData) &&
        props.quoteData.lossHistoryData.length > 0;

      props.setErrorMessages((prev) => {
        const newErrorMessages = { ...prev };

        if (hasAnimalClaim) {
          newErrorMessages.hasAnimals = t("hasAnimals");
        } else {
          delete newErrorMessages.hasAnimals;
        }

        return newErrorMessages;
      });
    }
  }, [
    props.quoteData.lossHistoryData,
    underWriting.underWritingQuestions.hasAnimals,
  ]);

  useEffect(() => {
    if (props?.underWritingQuestions && props?.underWritingAnswers) {
      setAllAnswerData(props.underWritingAnswers);
      const sortedQuestions = props.underWritingQuestions
        .filter((ques) => ques.n_DisplaySequenceNo)
        .sort(
          (a, b) =>
            parseFloat(a.n_DisplaySequenceNo) -
            parseFloat(b.n_DisplaySequenceNo)
        );
      setUnderWritingQuestions(sortedQuestions);

      let quutionKeyWithPoolManagerKey = {};
      sortedQuestions.forEach((question) => {
        quutionKeyWithPoolManagerKey = {
          ...quutionKeyWithPoolManagerKey,
          [question.n_PRPolicyAddStatQuestions_Pk]: question.poolManagerKey,
        };
      });

      let setPoolManagerValues = {};
      Object.values(props?.underWritingAnswers).forEach((item) => {
        if (
          quutionKeyWithPoolManagerKey[item.n_PRPolicyAddStatQuestions_FK] ===
          "hasSolarPanels"
        ) {
          setselectedSolarPanelLocation(item.s_PoAdditionalStatsResponse);
        }

        if (
          quutionKeyWithPoolManagerKey[item.n_PRPolicyAddStatQuestions_FK] ===
          "additionalRisks"
        ) {
          if (item.s_PoAdditionalStatsResponse) {
            setSelectedRisk(item.s_PoAdditionalStatsResponse.split(","));
          }
        }

        setPoolManagerValues = {
          ...setPoolManagerValues,
          [quutionKeyWithPoolManagerKey[item.n_PRPolicyAddStatQuestions_FK]]:
            item.s_PoAdditionalStatsAnswer === "false" ? false : true,
        };
      });

      setUnderWriting((prevState) => ({
        ...prevState,
        underWritingQuestions: setPoolManagerValues,
      }));

      if (quoteData) {
        getEditEmptyQuoteData(quoteData);
      }
    }
  }, [props.underWritingAnswers, props.underWritingQuestions, quoteData]);
  const handleSwitchChange = (questionKey) => {
    const newValue = !underWriting.underWritingQuestions[questionKey];
    setUnderWriting((prevState) => ({
      ...prevState,
      underWritingQuestions: {
        ...prevState.underWritingQuestions,
        [questionKey]: newValue,
      },
    }));
    updateAllAnswerData(questionKey, newValue);

    const newErrorMessages = { ...props.errorMessages };

    if (
      questionKey === "hasAnimals" && 
      (!props.quoteData.lossHistoryData|| props.quoteData.lossHistoryData.length === 0)
    ) {
      // Skip updating error message for "hasAnimals" if loss history is empty
    } else {
      if (newValue) {
        newErrorMessages[questionKey] = t(questionKey);
      } else {
        delete newErrorMessages[questionKey];
      }
    }

    props.setErrorMessages(newErrorMessages);
  };

  useEffect(() => {
    if (Object.keys(allAnswerData).length) {
      let tempData = quoteData;
      tempData["allAnswerData"] = allAnswerData;
      getEditEmptyQuoteData(tempData);
    }
  }, [allAnswerData]);

  const renderQuestion = (ques) => {
    const { n_PRPolicyAddStatQuestions_Pk, s_PRQuestionDesc, poolManagerKey } =
      ques;

    const renderErrorMessage = (key) =>
      props.errorMessages[key] && (
        <Box px={3} w={"90%"}>
          <Alert
            status="error"
            borderRadius={"xl"}
            color={"red"}
            p={1.5}
            bg={"transparent"}
            fontSize={"md"}
          >
            {props.errorMessages[key]}
          </Alert>
        </Box>
      );

    switch (n_PRPolicyAddStatQuestions_Pk) {
      case 13:
        return (
          underWriting?.underWritingQuestions?.hasSolarPanels && (
            <Flex
              px={4}
              alignItems="center"
              pt={3}
              style={{ marginBottom: "-10px" }}
            >
              <UnorderedList pl={3}>
                <ListItem color={"neutral.500"}>{s_PRQuestionDesc}</ListItem>
              </UnorderedList>
              <Spacer />
              <Select
                w={"12%"}
                mb={2}
                value={selectedSolarPanelLocation}
                onChange={(e) => {
                  setselectedSolarPanelLocation(e.target.value);
                  setUnderWriting((prevState) => ({
                    ...prevState,
                    underWritingQuestions: {
                      ...prevState.underWritingQuestions,
                      solarPanelLocation: e.target.value,
                    },
                  }));
                }}
              >
                <option value=""></option>
                {solarPanelLocation.map((item, i) => (
                  <option key={i} value={item.s_AppCodeName}>
                    {item.s_AppCodeNameForDisplay}
                  </option>
                ))}
              </Select>
              {underWriting &&
                underWriting?.underWritingQuestions?.solarPanelLocation ===
                  "" && (
                  <Text pt={2} color="red">
                    !!!
                  </Text>
                )}
            </Flex>
          )
        );
      case 5:
        return null;
      // return (
      //   underWriting?.underWritingQuestions?.hasSwimmingPool && (
      //     <Flex
      //       px={4}
      //       alignItems="center"
      //       pt={3}
      //       style={{ marginBottom: "-10px" }}
      //     >
      //       <UnorderedList pl={3}>
      //         <ListItem color={"neutral.500"}>{s_PRQuestionDesc}</ListItem>
      //       </UnorderedList>
      //       <Spacer />
      //       <Switch
      //         size="lg"
      //         pb={4}
      //         isChecked={underWriting.underWritingQuestions[poolManagerKey]}
      //         onChange={() => handleSwitchChange(poolManagerKey)}
      //       />
      //     </Flex>
      //   )
      // );
      case 10:
      case 12:
      case 14:
      case 16:
        return null;
      default:
        return (
          <Box>
            <Flex
              px={4}
              alignItems="center"
              pt={5}
              pb={2}
              style={{ marginBottom: "-10px" }}
            >
              <Text>{s_PRQuestionDesc}</Text>
              <Spacer />
              <Switch
                size="lg"
                pb={4}
                isChecked={underWriting.underWritingQuestions[poolManagerKey]}
                onChange={() => handleSwitchChange(poolManagerKey)}
                isDisabled={props?.isBind}
              />
            </Flex>
            {renderErrorMessage(poolManagerKey)}
          </Box>
        );
    }
  };

  return (
    <>
      {underWritingQuestions?.length ? (
        underWritingQuestions.map((ques) => (
          <Box key={ques.n_PRPolicyAddStatQuestions_Pk} fontSize={"17.5px"}>
            {renderQuestion(ques)}
            {(!underWriting?.underWritingQuestions?.hasSolarPanels &&
              ques.n_PRPolicyAddStatQuestions_Pk === 13) ||
            (!underWriting?.underWritingQuestions?.hasSwimmingPool &&
              ques.n_PRPolicyAddStatQuestions_Pk === 5) ||
            ques.n_PRPolicyAddStatQuestions_Pk === 14 ||
            ques.n_PRPolicyAddStatQuestions_Pk === 16 ||
            ques.n_PRPolicyAddStatQuestions_Pk === 5 ||
            (!underWriting?.underWritingQuestions?.additionalRisks &&
              ques.n_PRPolicyAddStatQuestions_Pk === 11) ||
            ques.n_PRPolicyAddStatQuestions_Pk === 10 ||
            ques.n_PRPolicyAddStatQuestions_Pk === 12 ? null : (
              <Box my={4} borderTop={"1px solid"} borderColor={"gray.200"} />
            )}
          </Box>
        ))
      ) : (
        <Text>No underwriting questions found.</Text>
      )}
      {!underWriting?.underWritingQuestions?.additionalRisks && <Box my={3} />}
      {underWriting?.underWritingQuestions?.additionalRisks && (
        <Flex
          px={4}
          alignItems="center"
          justifyContent={"space-between"}
          py={4}
        >
          <UnorderedList pl={3}>
            <ListItem color={"neutral.500"}>
              Please Select Additional Risks (Multiple Allowed)
            </ListItem>
          </UnorderedList>
          <MultiSelect
            options={riskAssociated.map((item) => ({
              label: item.s_AppCodeNameForDisplay,
              value: item.s_AppCodeName,
            }))}
            setSelected={setSelectedRisk}
            selected={selectedRisk}
            placeholder="Select Additional Risks"
          />
        </Flex>
      )}
      <style>
        {`
          .chakra-switch {
            width: fit-content !important;
            margin-top: 1.5px;
          }

          .chakra-switch .chakra-switch__track::after {
            content: "NO" !important;
            color: var(--chakra-colors-white);
            display: block;
            position: absolute;
            top: 15px;
            left: 58%;
            font-size: 11px;
            font-weight: bold;
          }

          .chakra-switch[data-checked] .chakra-switch__track::after {
            content: "YES" !important;
            left: 7.7%;
            font-size: 10px;
            font-weight: normal;
          }
        `}
      </style>
    </>
  );
};

function mapStateToProps(state) {
  return {
    quoteData: state.common.emptyQuoteData ? state.common.emptyQuoteData : {},
    getBaseData: state.common.getBaseData ? state.common.getBaseData : {},
  };
}

export default connect(mapStateToProps)(UnderwritingQuestion);
