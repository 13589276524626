
export function checkProducerVisibility(agentSubModulesAccess,userLevel,userSubLevel) {
    let subModuleAccess = false
    let userLevelAccess = false
    if(agentSubModulesAccess.includes('PRODUCER', 'FLOOD_PENDING_POLICY')){
      subModuleAccess =true
    }
    if(userLevel === 'EMPLOYEE' || userSubLevel === 'WYO_MANAGER'){
      userLevelAccess = true
    }
    
    return ( subModuleAccess && userLevelAccess )
  }

  export function isAllowedToBindQuoteInHiscoxFloodPlus(userLevel,userSubLevel) {
    let userLevelAccess = false

    if(userLevel === 'EMPLOYEE' || userSubLevel === 'WYO_MANAGER'){
      userLevelAccess = true;
    }

    return userLevelAccess;
  }