import React, { Fragment, useState } from "react";
import { Button, Row, Col, Form, Modal } from "react-bootstrap";
import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Box,
} from "@chakra-ui/react";

import { FaPlus } from "react-icons/fa";
import "react-datepicker/dist/react-datepicker.css";
import { useTranslation } from "react-i18next";

import { connect } from "react-redux";
import Select from "react-select";
import BillingInfo from "./components/ApplicantTabComponents/BillingInfo";
import PriorCoverage from "./components/ApplicantTabComponents/PriorCoverage";
import PolicyInterests from "./components/ApplicantTabComponents/PolicyInterests";
import FooterButton from "./components/FooterButton";
import LossHistory from "./components/ApplicantTabComponents/LossHistory";
const ApplicationTab = (props) => {
  const { t } = useTranslation();

  const [showModal, setShowModal] = useState(false);
  const [selectInterest, setSelectInterest] = useState("PRIMARY");
  const [selectedName, setSelectedName] = useState(null);
  const [inputFields, setInputFields] = useState([]);

  const [interestValue, setInterestValue] = useState({
    value: "PRIMARY",
    label: "First Mortgagee",
  });
  const interestOptions = [
    { value: "PRIMARY", label: "First Mortgagee" },
    { value: "SECOND", label: "Second Mortgagee" },
    { value: "THIRD", label: "Third Mortgagee" },
    { value: "ADDITIONALINSURED", label: "Additional Insured" },
    { value: "ADDITIONALINTEREST", label: "Additional Interest" },
  ];
  const nameOptions = [
    { value: "Brad", label: "Brad" },
    { value: "Lester", label: "Lester" },
    { value: "John", label: "John" },
    { value: "Albert", label: "Albert" },
    { value: "Micheal", label: "Micheal" },
  ];

  const [data, setData] = useState({
    name: "",
    firstName: "",
    middleName: "",
    lastName: "",
    type: "",
    mailAddress1: "",
    mailAddress2: "",
    city: "",
    state: "",
    zipCode: null,
    loadNo: "",
  });

  const handleInputDataChange = (event) => {
    setData({
      ...data,
      [event.target.name]: event.target.value,
    });
  };

  const handleSaveInterest = () => {
    const values = [...inputFields];
    if (
      selectInterest === "ADDITIONALINSURED" ||
      selectInterest === "ADDITIONALINTEREST"
    ) {
      setData({
        ...data,
        name: `${data.firstName} ${data.middleName} ${data.lastName}`,
      });
      values.push({
        name: `${data.firstName} ${data.middleName} ${data.lastName}`,
        interestType: interestValue.label,
        loadNo: data.loadNo,
      });
    } else {
      values.push({
        name: data.name,
        interestType: interestValue.label,
        loadNo: data.loadNo,
      });
    }

    setInputFields(values);
    setData({});
    setShowModal(false);
  };

  return (
    <Fragment>
      <div className="search-quote-tab-div py-2 radius-xl shadow-sm">
        <Accordion m={5} allowToggle>
          {/* billing info */}
          <AccordionItem
            mb={4}
            style={{ border: "1px solid #E6E9ED", borderTop: 0 }}
            borderRadius={"xl"}
          >
            <AccordionButton
              background="var(--chakra-colors-primary-50)"
              borderRadius={"xl"}
            >
              <Box as="span" flex="1" fontSize="1.2rem" textAlign="left">
                {t("Billing Info")}
              </Box>
              <AccordionIcon />
            </AccordionButton>
            <AccordionPanel>
              <BillingInfo propsData={props} isBind={props.isBind} />
            </AccordionPanel>
          </AccordionItem>

          {/* prior coverage */}
          {/* <AccordionItem
            mb={4}
            style={{ border: "1px solid #E6E9ED", borderTop: 0 }}
            borderRadius={"xl"}
          >
            <AccordionButton
              background="var(--chakra-colors-primary-50)"
              borderRadius={"xl"}
            >
              <Box as="span" flex="1" fontSize="1.2rem" textAlign="left">
                {t("Prior Coverage")}
              </Box>
              <AccordionIcon />
            </AccordionButton>
            <AccordionPanel>
              <PriorCoverage propsData={props} isBind={props.isBind} />
            </AccordionPanel>
          </AccordionItem> */}

          {/* policy Interests */}
          <AccordionItem
            mb={4}
            style={{ border: "1px solid #E6E9ED", borderTop: 0 }}
            borderRadius={"xl"}
          >
            <AccordionButton
              background="var(--chakra-colors-primary-50)"
              borderRadius={"xl"}
            >
              <Box as="span" flex="1" fontSize="1.2rem" textAlign="left">
                {t("Policy Interests")}
              </Box>
              <AccordionIcon />
            </AccordionButton>
            <AccordionPanel>
              <PolicyInterests isBind={props.isBind} />
            </AccordionPanel>
          </AccordionItem>

          {/* Loss History */}
          <AccordionItem
            mb={4}
            style={{ border: "1px solid #E6E9ED", borderTop: 0 }}
            borderRadius={"xl"}
          >
            <AccordionButton
              background="var(--chakra-colors-primary-50)"
              borderRadius={"xl"}
            >
              <Box as="span" flex="1" fontSize="1.2rem" textAlign="left">
                {t("Loss History")}
              </Box>
              <AccordionIcon />
            </AccordionButton>
            <AccordionPanel>
              <LossHistory isBind={props.isBind} />
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
      </div>

      <FooterButton rateSubmit={props.rateSubmit} isBind={props.isBind} />

      {/* add interest modal */}
      <Modal
        className="custom-dashboard-modal"
        size="lg"
        show={showModal}
        onHide={() => setShowModal(false)}
      >
        <Modal.Header closeButton className="custom-dashboard-modal-header">
          <Modal.Title>Add Interest</Modal.Title>
        </Modal.Header>
        <Modal.Body
          className="custom-dashboard-modal-body"
          style={{ paddingTop: "40px", paddingBottom: "40px" }}
        >
          <Row className="align-items-end">
            <Col xs="12" sm="12" md="12" lg="6" xl="6">
              <Form.Group
                controlId="formBasicEmail"
                className="search-quote-form-group"
              >
                <Form.Label className="dashboard-group-label">
                  Select Interest
                </Form.Label>
                <Select
                  options={interestOptions}
                  className="custom-select-autocomplete"
                  value={interestValue}
                  onChange={(e) => {
                    setInterestValue(e);
                    setSelectInterest(e.value);
                  }}
                />
              </Form.Group>
            </Col>

            <Col xs="12" sm="12" md="12" lg="6" xl="6">
              <Form.Group
                controlId="formBasicEmail"
                className="search-quote-form-group"
              >
                <div className="d-grid gap-2">
                  <Button
                    size="lg"
                    id="common-btn"
                    variant="primary"
                    style={{
                      whiteSpace: "nowrap",
                      padding: "9px",
                    }}
                  >
                    <FaPlus size={15} style={{ marginRight: "10px" }} />
                    Add Additional Interests
                  </Button>{" "}
                </div>
              </Form.Group>
            </Col>
          </Row>
          {(selectInterest === "PRIMARY" ||
            selectInterest === "SECOND" ||
            selectInterest === "THIRD") && (
            <Row>
              <Col
                xs="12"
                sm="12"
                md="12"
                lg="12"
                xl="12"
                style={{ marginBottom: "30px" }}
              >
                <Select
                  options={nameOptions}
                  className="custom-select-autocomplete"
                  value={selectedName}
                  onChange={(e) => {
                    setSelectedName(e);
                    setData({
                      ...data,
                      name: e.value,
                    });
                  }}
                  placeholder="Input search text"
                />
              </Col>
            </Row>
          )}

          <Row>
            {selectInterest === "ADDITIONALINSURED" ||
            selectInterest === "ADDITIONALINTEREST" ? (
              <Fragment>
                <Col xs="12" sm="12" md="12" lg="4" xl="4">
                  <Form.Group
                    controlId="formBasicEmail"
                    className="search-quote-form-group"
                  >
                    <Form.Label className="dashboard-group-label">
                      First Name
                    </Form.Label>
                    <Form.Control
                      className="dashboard-group-input"
                      type="text"
                      placeholder="Type here"
                      name="firstName"
                      value={data.firstName}
                      onChange={(e) => handleInputDataChange(e)}
                    />
                  </Form.Group>
                </Col>
                <Col xs="12" sm="12" md="12" lg="4" xl="4">
                  <Form.Group
                    controlId="formBasicEmail"
                    className="search-quote-form-group"
                  >
                    <Form.Label className="dashboard-group-label">
                      Middle Name
                    </Form.Label>
                    <Form.Control
                      className="dashboard-group-input"
                      type="text"
                      placeholder="Type here"
                      name="middleName"
                      value={data.middleName}
                      onChange={(e) => handleInputDataChange(e)}
                    />
                  </Form.Group>
                </Col>
                <Col xs="12" sm="12" md="12" lg="4" xl="4">
                  <Form.Group
                    controlId="formBasicEmail"
                    className="search-quote-form-group"
                  >
                    <Form.Label className="dashboard-group-label">
                      Last Name
                    </Form.Label>
                    <Form.Control
                      className="dashboard-group-input"
                      type="text"
                      placeholder="Type here"
                      name="lastName"
                      value={data.lastName}
                      onChange={(e) => handleInputDataChange(e)}
                    />
                  </Form.Group>
                </Col>
              </Fragment>
            ) : (
              <Col xs="12">
                <Form.Group
                  controlId="formBasicEmail"
                  className="search-quote-form-group"
                >
                  <Form.Label className="dashboard-group-label">
                    Full Name
                  </Form.Label>
                  <Form.Control
                    className="dashboard-group-input"
                    type="text"
                    placeholder="Type here"
                    name="name"
                    value={data.name}
                    onChange={(e) => handleInputDataChange(e)}
                  />
                </Form.Group>
              </Col>
            )}
            {selectInterest === "ADDITIONALINTEREST" && (
              <Col xs="12" sm="12" md="12" lg="12" xl="12">
                <Form.Group
                  controlId="formBasicEmail"
                  className="search-quote-form-group"
                >
                  <Form.Label className="dashboard-group-label">
                    Type
                  </Form.Label>
                  <Form.Select
                    className="dashboard-group-input"
                    name="type"
                    value={data.type}
                    onChange={(e) => handleInputDataChange(e)}
                  >
                    <option>Select</option>
                    <option value="PROPMGR">Property Manager</option>
                    <option value="OTHERS">Others</option>
                  </Form.Select>
                </Form.Group>
              </Col>
            )}
            <Col xs="12">
              <Form.Group
                controlId="formBasicEmail"
                className="search-quote-form-group"
              >
                <Form.Label className="dashboard-group-label">
                  Mailing Address{" "}
                </Form.Label>
                <Form.Control
                  className="dashboard-group-input"
                  type="text"
                  placeholder="Type here"
                  name="mailAddress1"
                  value={data.mailAddress1}
                  onChange={(e) => handleInputDataChange(e)}
                />
              </Form.Group>
            </Col>
            <Col xs="12">
              <Form.Group
                controlId="formBasicEmail"
                className="search-quote-form-group"
              >
                <Form.Label className="dashboard-group-label">
                  Mailing Address 1{" "}
                </Form.Label>
                <Form.Control
                  className="dashboard-group-input"
                  type="text"
                  placeholder="Type here"
                  name="mailAddress2"
                  value={data.mailAddress2}
                  onChange={(e) => handleInputDataChange(e)}
                />
              </Form.Group>
            </Col>
            <Col xs="12" sm="12" md="6" lg="6" xl="6">
              <Form.Group
                controlId="formBasicEmail"
                className="search-quote-form-group"
              >
                <Form.Label className="dashboard-group-label">City</Form.Label>
                <Form.Control
                  className="dashboard-group-input"
                  type="text"
                  placeholder="Type here"
                  name="city"
                  value={data.city}
                  onChange={(e) => handleInputDataChange(e)}
                />
              </Form.Group>
            </Col>
            <Col xs="12" sm="12" md="6" lg="6" xl="6">
              <Form.Group
                controlId="formBasicEmail"
                className="search-quote-form-group"
              >
                <Form.Label className="dashboard-group-label">State</Form.Label>
                <Form.Control
                  className="dashboard-group-input"
                  type="text"
                  placeholder="Type here"
                  name="state"
                  value={data.state}
                  onChange={(e) => handleInputDataChange(e)}
                />
              </Form.Group>
            </Col>
            <Col xs="12" sm="12" md="6" lg="6" xl="6">
              <Form.Group
                controlId="formBasicEmail"
                className="search-quote-form-group"
              >
                <Form.Label className="dashboard-group-label">
                  Zip Code
                </Form.Label>
                <Form.Control
                  className="dashboard-group-input"
                  type="text"
                  placeholder="Type here"
                  name="zipCode"
                  value={data.zipCode}
                  onChange={(e) => handleInputDataChange(e)}
                />
              </Form.Group>
            </Col>
            <Col xs="12" sm="12" md="6" lg="6" xl="6">
              <Form.Group
                controlId="formBasicEmail"
                className="search-quote-form-group"
              >
                <Form.Label className="dashboard-group-label">
                  Loan Number
                </Form.Label>
                <Form.Control
                  className="dashboard-group-input"
                  type="text"
                  placeholder="Type here"
                  name="loadNo"
                  value={data.loadNo}
                  onChange={(e) => handleInputDataChange(e)}
                />
              </Form.Group>
            </Col>
            <Col xs="12">
              <div className="center-div-class">
                <Button
                  onClick={() => setShowModal(false)}
                  id="common-cancel-btn"
                  variant="primary"
                  style={{ padding: "8px 24px", marginRight: "21px" }}
                >
                  Cancel
                </Button>
                <Button
                  onClick={() => handleSaveInterest()}
                  id="common-btn"
                  variant="primary"
                  style={{ padding: "8px 24px" }}
                >
                  Save
                </Button>
              </div>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </Fragment>
  );
};

function mapStateToProps(state) {
  return {
    getQuoteData: state.common.getQuoteData ? state.common.getQuoteData : {},
    getQuoteDataHO3: state.common.getQuoteDataHO3
      ? state.common.getQuoteDataHO3
      : {},
  };
}
export default connect(mapStateToProps)(ApplicationTab);
